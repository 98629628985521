import React from 'react';
import Modal from 'antd/es/modal/Modal';
import './modals.css';

const FullScreenMessageModal = (props) => {
  return (
    <>
      <Modal
        visible={props.messageModalVisible}
        closable={false}
        footer={null}
        onPan={(p) => { }}
        centered
        className="full-screen-message-modal"
      >
        <div style={{ fontSize: 200, fontWeight: 'bold', color: 'white' }}>
          {props.message}
        </div>
      </Modal>
    </>

  );
};

export default FullScreenMessageModal;
