import React, { useContext } from 'react';
import { Button, Menu } from 'antd';
import {
  CalendarOutlined,
  DesktopOutlined, DribbbleOutlined, HomeOutlined,
  IdcardOutlined, LogoutOutlined, MenuOutlined,
  ProfileOutlined,
  TeamOutlined, SolutionOutlined, DollarCircleOutlined, SnippetsOutlined,
  MailOutlined
} from '@ant-design/icons';
// import '../layout/layout.css';
import { Link, useLocation } from 'react-router-dom';
import { MenuContext } from '@elekajota/react-flexible-sliding-menu-scroll';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/utils';

const OrganizerMenu = (props) => {
  const location = useLocation();
  const { closeMenu } = useContext(MenuContext);
  const { t } = useTranslation();

  return (
    <>
      <Menu theme="dark" mode={props.mode || 'inline'} defaultSelectedKeys={['/dashboard']} selectedKeys={[location.pathname]} onClick={closeMenu}>
        {props.mode !== 'horizontal'
          ? (
            <Button onClick={closeMenu} style={{ height: '100%', width: '100%', border: 0, display: 'flex', justifyContent: 'flex-end' }} ghost ><MenuOutlined style={{ fontSize: 32, margin: '8px' }} /></Button>
          )
          : (<></>)}
        <Menu.Item key="/dashboard" icon={<ProfileOutlined style={{ fontSize: 24 }}/>} style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/dashboard">{capitalize(t('home'))}</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/my-center" icon={<HomeOutlined style={{ fontSize: 24 }}/>} style={{ display: 'flex', alignItems: 'center' }}>
          <Link to='/dashboard/my-center'>{capitalize(t('myCenter'))}</Link>
        </Menu.Item>

        {props.center?.plan[0] === '1' && (
          <>
            <Menu.Item key="/dashboard/users" icon={<IdcardOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/users'>{capitalize(t('users'))}</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/trainers" icon={<TeamOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/trainers'>{capitalize(t('trainers'))}</Link>
            </Menu.Item>
          </>
        )}

        {props.center?.plan[1] === '1' && (
          <>
            <Menu.Item key="/dashboard/devices" icon={<DesktopOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/devices'>{capitalize(t('devices'))}</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/exercises" icon={<DribbbleOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/exercises'>{capitalize(t('exercises'))}</Link>
            </Menu.Item>
          </>
        )}

        {props.center?.plan[3] === '1' && (
          <>
            <Menu.Item key="/dashboard/comunicationtemplates" icon={<MailOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/comunicationtemplates'>{capitalize(t('comunicationtemplates'))}</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/comunication/send" icon={<MailOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/comunication/send'>{capitalize(t('comunications'))}</Link>
            </Menu.Item>
          </>
        )}

        {props.center?.plan[2] === '1' && (
          <>
            <Menu.Item key="/dashboard/booksessions" icon={<SnippetsOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/booksessions'>{capitalize(t('booksessions'))}</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/booking" icon={<CalendarOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/booking'>{capitalize(t('booking'))}</Link>
            </Menu.Item>
            <Menu.Item key="/dashboard/transactions" icon={<DollarCircleOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/transactions'>{capitalize(t('transactions'))}</Link>
            </Menu.Item>
          </>
        )}

        {props.center?.plan[1] === '1' && (
          <>
            <Menu.Item key="/dashboard/sessions" icon={<SolutionOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/dashboard/sessions'>{capitalize(t('sessions'))}</Link>
            </Menu.Item>
          </>
        )}

        {props.mode !== 'horizontal'
          ? (
            <Menu.Item key="logout" icon={<LogoutOutlined style={{ fontSize: 24 }}/>} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100%' }} onClick={props.signOut}> {capitalize(t('logout'))} </div>
            </Menu.Item>
          )
          : (<></>)}
      </Menu>
    </>
  );
};

export default OrganizerMenu;
