import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import { getComunicationtemplateByQuery } from '../../../services/comunicationtemplate.service';
import TagExercise from '../../../components/tags/TagExercise';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const Comunicationtemplate = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [comunicationtemplates, setComunicationtemplates] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadComunicationtemplates = async (centerId) => {
    const { status, data } = await getComunicationtemplateByQuery({ center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(comunicationtemplate => {
        comunicationtemplate.key = comunicationtemplate._id;
      });
      setComunicationtemplates(data.data);
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      loadComunicationtemplates(data.data[0]._id);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      loadComunicationtemplates(data.data._id);
    }
  };

  const columns = [
    {
      title: capitalize(t('comunicationtemplates')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
          <br />
          {record.text}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('subject')),
      dataIndex: ['name'],
      key: 'name',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('text')),
      dataIndex: ['text'],
      key: 'name',
      render: (text, record) => record.text,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.text),
        multiple: 4
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewComunicationtemplate'))}
        </Button>
        <Table columns={columns} dataSource={comunicationtemplates} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { navigate('edit/' + record._id); }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  };
};

export default Comunicationtemplate;
