import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../services/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCenterById, getCenterByQuery } from '../services/center.service';

const Redirect = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (user.account && center) {
      if (user.account.roles.indexOf('user') >= 0) {
        navigate('/user');
      } else {
        navigate('/dashboard');
      }
    }
  }, [center]);

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('user') >= 0) {
        loadCenterId(user.account.center);
      } else {
        loadCenterOrg(user.account._id);
      }
    }
  }, [user]);

  const loadCenterId = async (centerId) => {
    const { status, data } = await getCenterById(centerId);
    if (status === 200) {
      i18n.changeLanguage(data.data.language);
      localStorage.setItem('language', data.data.language);
      setCenter(data.data);
    }
  };

  const loadCenterOrg = async (orgId) => {
    const { status, data } = await getCenterByQuery({ organizer: orgId });
    if (status === 200) {
      i18n.changeLanguage(data.data.language);
      localStorage.setItem('language', data.data.language);
      setCenter(data.data);
    }
  };

  return (
    <>
      <p>Redireccionando...</p>
      <br />
    </>
  );
};

export default Redirect;
