import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Alert, message, Popconfirm } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { createComunicationtemplate, getComunicationtemplateById, patchComunicationtemplate } from '../../../services/comunicationtemplate.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import TextArea from 'antd/es/input/TextArea';

const CreateComunicationtemplate = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [comunicationtemplate, setComunicationtemplate] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      if (params.id) {
        await loadComunicationtemplate(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      setCenter(data.data);
      if (params.id) {
        await loadComunicationtemplate(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadComunicationtemplate = async (id, token) => {
    const { status, data } = await getComunicationtemplateById(id, token);
    if (status === 200) {
      setComunicationtemplate(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const fillForm = (comunicationtemplate) => {
    form.setFieldsValue({
      name: comunicationtemplate.name,
      text: comunicationtemplate.text
    });
  };

  const deactivateComunicationtemplate = async () => {
    if (comunicationtemplate) {
      const { status } = await patchComunicationtemplate(comunicationtemplate._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/comunicationtemplates');
        message.success(capitalize(t('comunicationtemplateDeleted')));
      }
    }
  };

  const createNewComunicationtemplate = async (data, token) => {
    const formData = {
      name: data.name,
      text: data.text,
      center: center._id
    };
    if (params.id) await patchComunicationtemplate(params.id, formData, token);
    else await createComunicationtemplate(formData, token);
    navigate('/dashboard/comunicationtemplates');
  };

  const correctClave = async e => {
    await createNewComunicationtemplate(e, user.token);
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {comunicationtemplate ? <h1>{capitalizeAll(t('editComunicationtemplate'))}</h1> : <h1>{capitalizeAll(t('createComunicationtemplate'))}</h1>}
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('subject')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('subject'))}
            name="name"
            defaultValue={comunicationtemplate ? comunicationtemplate.name : ''}
            placeholder={capitalize(t('subject'))}
          />
        </Form.Item>
        <Form.Item
          name="text"
          rules={[{
            required: true,
            message: capitalize(t('text')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            style={{ height: 300 }}
            addonBefore={capitalize(t('text'))}
            allowClear={true}
            name="text"
            defaultValue={history ? history.description : ''}
            placeholder={capitalize(t('text'))}
          />
        </Form.Item>

        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {comunicationtemplate && (
            <Popconfirm
              title={capitalize(t('confirmDeleteComunicationtemplate'))}
              onConfirm={deactivateComunicationtemplate}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {comunicationtemplate ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createComunicationtemplate'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateComunicationtemplate;
