import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getEvaluationByQuery } from '../../../services/evaluation.service';
import { getAccountById } from '../../../services/user.service';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';
import { getCenterById } from '../../../services/center.service';
import { getImageSize } from 'react-image-size';

const Evaluation = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const navigate = useNavigate();
  const [evaluations, setEvaluations] = useState(null);
  const [account, setAccount] = useState(null);
  const [center, setCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  Font.register({
    family: 'Open Sans',
    fonts: [
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
  });
  useEffect(() => {
    if (user.account) {
      loadAccount(params.account);
    }
  }, [user.account]);

  // GENERATE PDF
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      fontSize: 9,
      paddingTop: 35,
      paddingHorizontal: 35,
      paddingBottom: 25,
      fontFamily: 'Open Sans'
    },
    titleSection: {
      flexDirection: 'row',
      justifyContent: 'space-between'

    },
    logo: {
      flexDirection: 'column',
      fontWeight: 600,
      fontSize: 12
    },
    logoImage: {
      width: 100,
      height: 40
    },
    titleDate: {
      fontSize: 9
    },
    descriptionSection: {
      flexDirection: 'column',
      marginTop: 15,
      fontSize: 9
    },
    keyValue: {
      flexDirection: 'row',
      marginBottom: 2
    },
    key: {
      fontWeight: 600
    },
    evaluationsTitle: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 9,
      fontWeight: 600
    },
    evaluation: {
      flexDirection: 'column',
      fontSize: 9,
      marginBottom: 15
    },
    evaluationImageLandscape: {
      maxHeight: 200,
      maxWidth: 300,
      marginTop: 10
    },
    evaluationImagePortrait: {
      maxHeight: 300,
      maxWidth: 200,
      marginTop: 10
    }
  });

  const MyDocument = ({ evaluation }) => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.titleSection}>
            <View style={styles.logo}>
              {/* <Image */}
              {/*  style={styles.logoImage} */}
              {/*  src={center.logo} */}
              {/* /> */}
              <Image
                style={styles.logoImage}
                src={'https://sei-platform-test.s3.eu-west-3.amazonaws.com/assets/sei-logo.png'}
              />
              <Text>{center.name}</Text>
            </View>
            <View style={styles.titleDate}>
              <Text>{moment(evaluation.timestamp.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
            </View>
          </View>
          <View style={styles.descriptionSection}>
            <View style={styles.keyValue}>
              <View style={styles.key}><Text>{capitalize(t('user'))}:</Text></View>
              <Text> {account.name} {account.surname}</Text>
            </View>
            <View style={styles.keyValue}>
              <View style={styles.key}><Text>{capitalize(t('evaluation'))}:</Text></View>
              <Text> {evaluation.name}</Text>
            </View>
            <View style={styles.evaluation}>
              <View style={styles.key}><Text>{capitalize(t('description'))}:</Text></View>
              <Text> {evaluation.description}</Text>
            </View>
          </View>
          <View style={styles.evaluationsTitle}>
            <Text>{capitalize(t('evaluations'))}</Text>
          </View>
          {evaluation.eval.map((evaluation, index) => {
            let image = false;
            image = !!evaluation.image;
            return (
              <View key={index} wrap={false} style={styles.evaluation}>
                <View style={styles.key}><Text>{capitalize(
                  evaluation.evaluation)}:</Text></View>
                <Text> {evaluation.description}</Text>
                {image
                  ? <Image
                    src={{
                      uri: evaluation.image,
                      method: 'GET',
                      headers: {
                        crossorigin: 'anonymous',
                        'cache-control': 'no-cache'
                      },
                      body: ''
                    }}
                    style={evaluation.orientation === 'landscape' ? styles.evaluationImageLandscape : styles.evaluationImagePortrait}
                    cache={false}
                  />
                  : null}
              </View>
            );
          })
          }
        </Page>
      </Document>
    );
  };

  const fetchImageSize = async (url) => {
    try {
      const dimensions = await getImageSize(url);
      return dimensions;
    } catch (error) {
      console.error(error);
    }
  };

  const loadEvaluations = async (userId) => {
    const { status, data } = await getEvaluationByQuery({ account: userId, isActive: true }, user.token);
    if (status === 200) {
      const evaluationsArr = [];
      for await (const evaluation of data.data) {
        for await (const evalItem of evaluation.eval) {
          if (evalItem.image) {
            const imageSize = await fetchImageSize(evalItem.image);
            evalItem.orientation = imageSize.width > imageSize.height ? 'landscape' : 'portrait';
          }
        }
        evaluation.key = evaluation._id;
        evaluationsArr.push(evaluation);
      }
      evaluationsArr.sort((a, b) => {
        return new Date(b.timestamp.createdAt) - new Date(a.timestamp.createdAt);
      });
      setEvaluations(evaluationsArr);
      if (center) setLoading(false);
    }
  };

  const loadAccount = async (userId) => {
    const { status, data } = await getAccountById(userId, user.token);
    if (status === 200) {
      setAccount(data.data);
      await loadEvaluations(params.account);
      await loadCenter(data.data.center);
      setLoading(false);
    }
  };

  const loadCenter = async (centerId) => {
    const { status, data } = await getCenterById(centerId, user.token);
    if (status === 200) {
      setCenter(data.data);
    }
  };

  const columns = [
    {
      title: capitalize(t('evaluations')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const evaluation = evaluations.find(evaluation => evaluation._id === record._id);
        return (
          <React.Fragment>
            {record.name}
            <br/>
            {record.description}
            <br/>
            {moment(record.timestamp.createdAt).format('DD/MM/YYYY')}
            <br/>
            <PDFDownloadLink document={<MyDocument evaluation={evaluation}/>}
              fileName={evaluation.name + '.pdf'}>
              {({ blob, url, loading, error }) => (loading
                ? capitalize(
                  t('generatingPdf'))
                : (<Button type="primary" icon={<FilePdfOutlined/>}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  {capitalize(t('downloadPdf'))}
                </Button>))}
            </PDFDownloadLink>
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      key: 'name',
      render: (record) => (
        <React.Fragment>
          {record.name}
        </React.Fragment>
      ),
      responsive: ['sm']
    },
    {
      title: capitalize(t('description')),
      key: 'description',
      // eslint-disable-next-line react/display-name
      render: (record) => record.description,
      responsive: ['sm']
    },
    {
      title: capitalize(t('creationDate')),
      key: 'creationDate',
      // eslint-disable-next-line react/display-name
      render: (record) => moment(record.timestamp.createdAt).format('DD/MM/YYYY'),
      responsive: ['sm'],
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      }
    },
    {
      title: capitalize(t('modifyDate')),
      key: 'modifyDate',
      // eslint-disable-next-line react/display-name
      render: (record) => moment(record.timestamp.updatedAt).format('DD/MM/YYYY'),
      responsive: ['sm'],
      sorter: {
        compare: (a, b) => a.timestamp.updatedAt.localeCompare(b.timestamp.updatedAt),
        multiple: 2
      }
    },
    {
      title: capitalize(t('actions')),
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const evaluation = evaluations.find(evaluation => evaluation._id === record._id);
        return (
          <React.Fragment>

            <PDFDownloadLink document={<MyDocument evaluation={evaluation}/>}
              fileName={evaluation.name + '.pdf'}>
              {({ blob, url, loading, error }) => (loading
                ? capitalize(
                  t('generatingPdf'))
                : (<Button type="primary" icon={<FilePdfOutlined />} onClick={(e) => {
                  e.stopPropagation();
                }}>
                  {capitalize(t('downloadPdf'))}
                </Button>))}
            </PDFDownloadLink>
          </React.Fragment>
        );
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewEvaluation'))}
        </Button>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b>{account ? account.name + ' ' + account.surname : ''}</b>
        </div>
        <Table columns={columns} dataSource={evaluations} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { navigate('/dashboard/users/evaluation/edit/' + record._id); }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  };
};

export default Evaluation;
