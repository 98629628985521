import React from 'react';
import { Row } from 'antd';

const ResumeDashboard = (props) => {
  return (
    <>
      <Row onClick={() => {
        if (props.link) {
          props.navigate(props.link);
        }
      }} style={{ width: 125, cursor: props.link ? 'pointer' : '', height: 125, border: '1px solid rgba(104, 129, 171, .5)', marginBottom: 10, borderRadius: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', wrap: 'nowrap', padding: 5 }}>
        <div style={{ fontSize: 36, color: 'rgba(104, 129, 171, .9)' }}>
          {props.icon}
        </div>
        <div style={{ fontSize: 22, color: 'rgba(104, 129, 171, .9)' }}>
          {props.value}
        </div>
        <div style={{ fontSize: 12, color: 'rgba(104, 129, 171, .9)' }}>
          {props.title}
        </div>
      </Row>
    </>
  );
};

export default ResumeDashboard;
