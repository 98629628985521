import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/navigation/Spinner';
import { getExerciseSessionByQuery } from '../../../services/exercisesession.service';
import { getIdToken } from 'firebase/auth';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getAccountById } from '../../../services/user.service';
import { getExerciseById } from '../../../services/exercise.service';
import { Table, Tag } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getCenterById } from '../../../services/center.service';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
moment.locale('es');

const ExerciseLog = (props) => {
  const params = useParams();
  const { user } = useContext(Auth);
  const [exercise, setExercise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [center, setCenter] = useState(null);
  const [exerciseSessions, setExerciseSessions] = useState([]);
  const [evolution, setEvolution] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [exerciseSessionsReverse, setExerciseSessionsReverse] = useState([]);
  const { t } = useTranslation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    zoomPlugin
    // ChartDataLabels
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: 'right',
        title: {
          display: true,
          text: capitalize(t('reps'))
        },
        ticks: {
          stepSize: 1
        },
        grid: {
          drawOnChartArea: false
        },
        suggestedMin: 0,
        suggestedMax: 20
      },
      y2: {
        title: {
          position: 'left',
          display: true,
          text: capitalize(t('weight'))
        },
        ticks: {
          stepSize: 5
        },
        suggestedMin: 60,
        suggestedMax: 120
      }
    },
    plugins: {
      datalabels: {
        font: {
          size: '18px'
        }
      }
    }
  };

  const labels = chartData.map(exercise => moment(exercise.timestamp.createdAt).format('DD/MM/YYYY'));

  const data = {
    labels,
    datasets: [
      {
        id: 1,
        label: capitalize(t('reps')),
        borderColor: 'rgba(193, 221, 230,1)',
        backgroundColor: 'rgba(46,124,157,0.5)',
        fill: true,
        yAxisID: 'y',
        // type: 'line',
        data: chartData.map(exercise => exercise.reps)
      },
      {
        id: 2,
        label: capitalize(t('weight')),
        borderColor: 'rgba(193, 221, 230,1)',
        backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: true,
        yAxisID: 'y2',
        type: 'line',
        data: chartData.map(exercise => exercise.weight)
      }
    ]
  };

  useEffect(() => {
    if (user.account) {
      loadExerciseSession();
    }
  }, [user.account]);

  const percentBetween = (numA, numB) => {
    return (numA - numB) / numB * 100;
  };

  const loadExerciseSession = async () => {
    const { status, data } = await getExerciseSessionByQuery({ exercise: params.id, account: params.account ? params.account : user.account._id }, user.token);
    if (status === 200) {
      await loadAccount(params.account ? params.account : user.account._id);
      await loadExercise(params.id);
      setExerciseSessions(data.data);
      const chart = [...data.data.slice(Math.max(data.data.length - 30, 0))];
      setChartData(chart);
      const reverse = [...data.data];
      reverse.reverse();
      setExerciseSessionsReverse(reverse);
      setEvolution(percentBetween(chart[chart.length - 1].weight, chart[0].weight));
      setLoading(false);
    }
  };

  const loadAccount = async (accountId) => {
    const { status, data } = await getAccountById(accountId, user.token);
    if (status === 200) {
      setAccount(data.data);
      await loadCenter(data.data.center);
    }
  };

  const loadCenter = async (centerId) => {
    const { status, data } = await getCenterById(centerId, user.token);
    if (status === 200) {
      setCenter(data.data);
    }
  };

  const loadExercise = async (exerciseId) => {
    const { status, data } = await getExerciseById(exerciseId, user.token);
    if (status === 200) {
      setExercise(data.data);
    }
  };

  const columns = [
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {moment(record.timestamp.createdAt).format('DD/MM/YYYY')}
          <br />
          {record.weight} {exercise.weightMeasure}
          <br />
          {record.reps}
          <br />
          {moment(record.time).format('mm:ss')}
          <br />
          { record.AP && <Tag color="default">AP</Tag>}
          { record.MT && <Tag color="default">MT</Tag>}
          { record.NF && <Tag color="default">NF</Tag>}
          { record.NS && <Tag color="default">NS</Tag>}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      dataIndex: ['timestamp.createdAt'],
      key: 'date',
      align: 'center',
      render: (text, record) => moment(record.timestamp.createdAt).format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('weight')),
      align: 'center',
      // dataIndex: ['name'],
      key: 'weight',
      // render: (text, record) => record.name,
      render: (text, record) => record.weight + ' ' + exercise.weightMeasure,
      responsive: ['sm']
    },
    {
      title: capitalize(t('reps')),
      align: 'center',
      dataIndex: ['reps'],
      key: 'all',
      render: (text, record) => record.reps + (record.incomplete ? ' + ' + record.incomplete : ''),
      responsive: ['sm']
    },
    {
      title: capitalize(t('time')),
      align: 'center',
      dataIndex: 'time',
      key: 'time',

      render: (text, record) => moment(record.time).format('mm:ss'),
      responsive: ['sm']
    },
    {
      title: capitalize(t('details')),
      align: 'center',
      render: (text, record) => {
        return (
          <>
            { record.AP && <Tag color="default">AP</Tag>}
            { record.MT && <Tag color="default">MT</Tag>}
            { record.NF && <Tag color="default">NF</Tag>}
            { record.NS && <Tag color="default">NS</Tag>}
            { record.passTul === 2 && <Tag color="success"><ArrowUpOutlined /></Tag>}
            { record.passTul === 1 && <Tag color="red"><ArrowDownOutlined /></Tag>}
          </>
        );
      },
      responsive: ['sm']
    }
  ];

  const columnsIsometric = [
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {moment(record.timestamp.createdAt).format('DD/MM/YYYY')}
          <br />
          {moment(record.lastSet).format('mm:ss')}
          <br />
          {record.sets.length} {capitalize(t('sets'))}
          <br />
          {moment(record.time).format('mm:ss')}
          <br />
          { record.AP && <Tag color="default">AP</Tag>}
          { record.MT && <Tag color="default">MT</Tag>}
          { record.NF && <Tag color="default">NF</Tag>}
          { record.NS && <Tag color="default">NS</Tag>}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      dataIndex: ['timestamp.createdAt'],
      key: 'date',
      align: 'center',
      render: (text, record) => moment(record.timestamp.createdAt).format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('lastSet')),
      align: 'center',
      // dataIndex: ['name'],
      key: 'lastSet',
      // render: (text, record) => record.name,
      render: (text, record) => moment(record.lastSet).format('mm:ss'),
      responsive: ['sm']
    },
    {
      title: capitalize(t('sets')),
      align: 'center',
      dataIndex: ['reps'],
      key: 'all',
      render: (text, record) => record.sets.length + ' ' + capitalize(t('sets')),
      responsive: ['sm']
    },
    {
      title: capitalize(t('time')),
      align: 'center',
      dataIndex: 'time',
      key: 'time',

      render: (text, record) => moment(record.time).format('mm:ss'),
      responsive: ['sm']
    },
    {
      title: capitalize(t('details')),
      align: 'center',
      render: (text, record) => {
        return (
          <>
            { record.AP && <Tag color="default">AP</Tag>}
            { record.MT && <Tag color="default">MT</Tag>}
            { record.NF && <Tag color="default">NF</Tag>}
            { record.NS && <Tag color="default">NS</Tag>}
            { record.passTul === 2 && <Tag color="success"><ArrowUpOutlined /></Tag>}
            { record.passTul === 1 && <Tag color="red"><ArrowDownOutlined /></Tag>}
          </>
        );
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return <Spinner/>;
  }

  return (
    <div>
      <h1 style={{ marginBottom: 0 }}>{exercise.name}</h1>
      {exercise.type === 'isometric'
        ? <Table columns={columnsIsometric} dataSource={exerciseSessionsReverse} onChange={onChange} />
        : (
          <>
            <div>
              {capitalize(t('evolution'))}: <span style={{ fontWeight: 'bold' }}>{evolution.toFixed(2)}%</span>
            </div>
            <div style={{ height: 300 }}>
              <Bar
                datasetIdKey='id'
                data={data}
                options={options}
              />

            </div>
            <Table columns={columns} dataSource={exerciseSessionsReverse} onChange={onChange}/>
          </>
        )}

    </div>
  );
};

export default ExerciseLog;
