import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import { getAccountByQuery } from '../../../services/user.service';
import '../../../components/layout/layout.css';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

const Organizers = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [organizers, setOrganizers] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    loadOrganizers();
  }, []);

  const loadOrganizers = async () => {
    const { status, data } = await getAccountByQuery({ roles: 'organizer', isActive: true }, user.token);
    if (status === 200) {
      setOrganizers(data.data);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: capitalize(t('organizer')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name} {record.surname}
          <br />
          {record.email}
          <br />
          {record.phone}
          <br />
          {record.nif}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name', 'surname'],
      key: '_id',
      render: (text, record) => record.name + ' ' + record.surname,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('email')),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('phone')),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a.phone.localeCompare(b.phone),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('nif')),
      dataIndex: 'nif',
      key: 'nif',
      sorter: {
        compare: (a, b) => a.nif.localeCompare(b.nif),
        multiple: 1
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewOrganizer'))}
        </Button>
        <Table columns={columns} dataSource={organizers} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { navigate('edit/' + record._id); }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  };
};

export default Organizers;
