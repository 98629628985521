import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Form, Input, Steps, message, Transfer, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import { getComunicationtemplateByQuery } from '../../../services/comunicationtemplate.service';
import { getAccountByQuery, sendEmail } from '../../../services/user.service';
import TagExercise from '../../../components/tags/TagExercise';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const SendComunication = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [comunicationtemplates, setComunicationtemplates] = useState(null);
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState(null);
  const [sentEmails, setSentEmails] = useState(0);
  const [percent, setPercent] = useState(0);
  const { t } = useTranslation();
  const [presset, setPresset] = useState({
    subject: '',
    message: ''
  });
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  useEffect(() => {
    if (presset.message !== '' && presset.subject !== '') {
      fillWithPresset(presset.subject, presset.message);
    }
  }, [presset]);

  useEffect(() => {
    if (selectedAccounts.length > 0) {
      setPercent(Math.round((sentEmails / selectedAccounts.length) * 100));
    }
    if (Math.round((sentEmails / selectedAccounts.length) * 100) === 100) {
      message.success(capitalize(t('emailsSent')));
    }
  }, [sentEmails]);

  const loadComunicationtemplates = async (centerId) => {
    const { status, data } = await getComunicationtemplateByQuery({ center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(comunicationtemplate => {
        comunicationtemplate.key = comunicationtemplate._id;
      });
      setComunicationtemplates(data.data);
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      loadComunicationtemplates(data.data[0]._id);
      loadAccounts(data.data[0]._id);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      setCenter(data.data);
      loadComunicationtemplates(data.data._id);
      loadAccounts(data.data._id);
    }
  };

  const loadAccounts = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(account => {
        account.key = account._id;
      });
      setAccounts(data.data);
    }
  };

  const resetAll = () => {
    setSentEmails(0);
    setPercent(0);
    setSelectedAccounts([]);
    setCurrent(0);
    setPresset({
      subject: '',
      message: ''
    });
    form.resetFields();
  };

  const sendUserEmail = async () => {
    const formData = {
      message: presset.message,
      subject: presset.subject
    };

    let sentEmails = 0;

    for (const account of selectedAccounts) {
      const emailRes = await sendEmail(account, formData, user.token);
      sentEmails++;
      setSentEmails(sentEmails);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    // navigate('/dashboard/users');
  };

  const fillWithPresset = (subject, message) => {
    form.setFieldsValue({
      subject: subject,
      message: message
    });
  };

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    const filterOption = (inputValue, option) => {
      const name = option.name.toLowerCase();
      const surname = option.surname.toLowerCase();
      const email = option.email.toLowerCase();
      const inputValueLowerCase = inputValue.toLowerCase();
      return name.indexOf(inputValueLowerCase) > -1 || surname.indexOf(inputValueLowerCase) > -1 || email.indexOf(inputValueLowerCase) > -1;
    };
    const handleChange = (newTargetKeys) => {
      setSelectedAccounts(newTargetKeys);
    };
    const renderItem = (item) => {
      const customLabel = (
        <span className="custom-item">
          {item.name + ' ' + item.surname} - {item.email}
        </span>
      );
      return {
        label: customLabel,
        value: item.name
      };
    };

    const accountsStep = () => {
      return (
        <>
          <h1>{capitalizeAll(t('selectUsers'))}</h1>
          <div className='step-content'>
            <Transfer
              titles={[capitalize(t('users')), capitalize(t('selectedUsers'))]}
              dataSource={accounts}
              showSearch
              filterOption={filterOption}
              targetKeys={selectedAccounts}
              onChange={handleChange}
              listStyle={{
                width: 400,
                height: 400
              }}
              pagination
              render={renderItem}
              locale={{
                itemUnit: capitalize(t('user')),
                itemsUnit: capitalize(t('users')),
                searchPlaceholder: capitalize(t('search')) + ' ' + capitalize(t('users')),
                notFoundContent: capitalize(t('notFound')),
                remove: capitalize(t('remove')),
                selectAll: capitalize(t('selectAll')),
                selectCurrent: capitalize(t('selectCurrent')),
                selectInvert: capitalize(t('selectInvert')),
                removeAll: capitalize(t('removeAll')),
                removeCurrent: capitalize(t('removeCurrent'))
              }}
            />
          </div>
        </>
      );
    };

    const templateStep = () => {
      return (
        <>
          <h1>{capitalizeAll(t('selectTemplate'))}</h1>
          <div className='step-content'>
            <Form form={form} style={{
              width: '100%',
              maxWidth: 600
            }}>
              <div style={{ textAlign: 'left', fontSize: 12, marginBottom: 8 }}>
              </div>
              <Form.Item
                name="subject"
                rules={[{
                  required: true,
                  message: capitalize(t('subject')) + ' ' + t('isRequired')
                }]}>
                <Input
                  addonBefore={capitalize(t('subject'))}
                  name="subject"
                  placeholder={capitalize(t('subject'))}
                  onChange={(e) => {
                    setPresset({
                      ...presset,
                      subject: e.target.value
                    });
                  }}
                />
              </Form.Item>
              <div style={{ textAlign: 'left' }}>
                {capitalize(t('message'))}:
              </div>
              <Form.Item
                name="message"
                rules={[{
                  required: true,
                  message: capitalize(t('message')) + ' ' + t('isRequired')
                }]}>
                <TextArea
                  addonBefore={capitalize(t('message'))}
                  rows={6}
                  name="message"
                  placeholder={capitalize(t('message'))}
                  onChange={(e) => {
                    setPresset({
                      ...presset,
                      message: e.target.value
                    });
                  }}
                />
              </Form.Item>
            </Form>
            <div style={{ display: 'flex', flexDirection: 'column', width: '600px' }}>
              {capitalize(t('pressetsEmails'))}:
              <button onClick={() => setPresset({
                subject: 'Bono Terminado',
                message: 'Queremos informarte, que tu "Bono de Entrenamiento" se ha quedado sin sesiones.\n\nMuchas gracias.'
              })} style={{ width: '100%', marginBottom: 4 }}>
                {t('Predefinido: Bono agotado')}
              </button>
              {comunicationtemplates.map((comunicationtemplate, index) => {
                return (
                  <button key={index} onClick={() => setPresset({
                    subject: comunicationtemplate.name,
                    message: comunicationtemplate.text
                  })} style={{ width: '100%', marginBottom: 4 }}>
                    {comunicationtemplate.name}
                  </button>
                );
              }
              )}
            </div>
          </div>
        </>
      );
    };

    const resumeStep = () => {
      return (
        <>
          <h1>{capitalizeAll(t('resume'))}</h1>
          <div className='step-content'>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Progress type="circle" percent={percent} />
              <div style={{ paddingTop: 24 }}>
                {capitalize(t('sentEmails'))}: {sentEmails} / {selectedAccounts.length}
              </div>
            </div>
          </div>
        </>
      );
    };

    const steps = [
      {
        title: capitalize(t('selectUsers')),
        content: accountsStep()
      },
      {
        title: capitalize(t('selectTemplate')),
        content: templateStep()
      },
      {
        title: capitalize(t('resume')),
        content: resumeStep()
      }
    ];
    const next = () => {
      setCurrent(current + 1);
    };
    const prev = () => {
      setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
      key: item.title,
      title: item.title
    }));

    return (
      <>
        <Steps current={current} items={items} />
        <div style={{
          color: 'rgba(0, 0, 0, 0.85)',
          borderRadius: 4,
          border: '1px dashed rgb(217, 217, 217)',
          marginTop: 16,
          padding: 16
        }}>{steps[current].content}</div>
        <div
          style={{
            marginTop: 24,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {current === 1 && (
            <Button
              style={{
                margin: '0 8px'
              }}
              onClick={() => prev()}
            >
              {capitalize(t('previous'))}
            </Button>
          )}
          {current === 0 && (
            <Button type="primary" disabled={selectedAccounts.length === 0} onClick={() => next()}>
              {capitalize(t('next'))}
            </Button>
          )}
          {current === 1 && (
            <Button disabled={presset.message === '' || presset.subject === ''} type="primary" onClick={() => {
              next();
              sendUserEmail();
            }}>
              {capitalize(t('sendEmail'))}
            </Button>
          )}
          {current === 2 && (
            <Button type="primary" onClick={() => {
              resetAll();
            }}>
              {capitalize(t('finish'))}
            </Button>
          )}
        </div>
      </>
    );
  };
};

export default SendComunication;
