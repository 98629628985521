import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Form, Input, Table, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  ControlOutlined,
  EditOutlined,
  EuroOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  SettingOutlined, UploadOutlined
} from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import { createAccount, getAccountByQuery, patchAccount } from '../../../services/user.service';
import '../../../components/layout/layout.css';
import { getCenterByQuery } from '../../../services/center.service';
import { createUserWithEmailAndPassword, getAuth, getIdToken, signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import { createTransaction, confirmTransaction } from '../../../services/transaction.service';

import * as XLSX from 'xlsx';
import { signUpApp } from '../../../services/firebase/firebaseConfig';
import { getAllPrograms } from '../../../services/program.service';

const Credits = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [center, setCenter] = useState(null);
  const [message, setMessage] = useState('');
  const [programs, setPrograms] = useState(null);
  const [creditForm] = Form.useForm();
  const creditsValue = Form.useWatch('credits', creditForm);
  const priceValue = Form.useWatch('price', creditForm);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  useEffect(() => {
    if (users && users.length > 0 && filter !== '' && filter !== null && filter !== undefined) {
      const filtered = users.filter(user => {
        return user.name?.toLowerCase().includes(filter.toLowerCase()) || user.email?.toLowerCase().includes(filter.toLowerCase()) || user.phone?.toLowerCase().includes(filter.toLowerCase()) || user.nif?.toLowerCase().includes(filter.toLowerCase()) || user.surname?.toLowerCase().includes(filter.toLowerCase());
      });
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [filter, users]);

  const loadUsers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ roles: 'user', center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(user => {
        user.key = user._id;
      });
      setUsers(data.data.reverse());
      // setFilteredUsers(data.data);
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      const programs = await getAllPrograms(user.token);
      setPrograms(programs.data.data);
      loadUsers(data.data[0]._id);
      setCenter(data.data[0]);
    }
  };

  const columns = [
    {
      title: capitalize(t('user')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name} {record.surname}
          <br />
          {record.email}
          <br />
          {record.credits} {capitalize(t('credits'))}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name', 'surname'],
      key: '_id',

      render: (text, record) => record.name + ' ' + record.surname,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('email')),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('credits')),
      key: 'credits',
      render: (text, record) => {
        return (
          <React.Fragment>
            {record.credits} {capitalize(t('credits'))}
          </React.Fragment>
        );
      },
      responsive: ['sm']
    }
  ];

  const sendCredits = async (e, record) => {
    // e.preventDefault();
    const newTransaction = await createTransaction({
      created: undefined,
      grossPrice: 0,
      isActive: true,
      netPrice: 0,
      center: center._id,
      credits: parseInt(e.credits),
      id: null,
      secret: null,
      totalPrice: parseFloat(((e.credits * e.price) * 100).toFixed(2)),
      currency: 'eur',
      account: record._id,
      paymentMethod: 'cash',
      intentPayment: null,
      status: 'requires_payment_method',
      sendedEmail: false,
      description: record.email + ' Pagado manualmente'
    });

    if (newTransaction.status === 200) {
      const confirm = await confirmTransaction(newTransaction.data.data._id, user.token);
      if (confirm.status === 200) {
        setMessage(t('transactionOk'));
        loadCenter();
      } else {
        setMessage(t('transactionError'));
      }
    } else {
      setMessage(t('transactionError'));
    }
    // const { status, data } = await patchAccount({ _id: record._id, credits: record.credits + 1 }, user.token);
    // if (status === 200) {
    //   loadUsers(center._id);
    // }
  };

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        {message}
        <Input placeholder={capitalize(t('search'))} onChange={(e) => setFilter(e.target.value)} style={{ marginBottom: 18 }} />
        <Table columns={columns} dataSource={filteredUsers} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => {}, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        expandable={{
          // eslint-disable-next-line react/display-name
          expandedRowRender: record => <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
              {center?.plan[2] === '1' && (
                <>
                  <Form form={creditForm} name="credits" onFinish={(e) => sendCredits(e, record)} initialValues={{
                    price: 1,
                    credits: 1
                  }}>
                    <Form.Item
                      name="price"
                      rules={[{
                        required: true,
                        message: capitalize(t('price')) + ' ' + t('isRequired')
                      }]}>
                      <Input
                        type={'number'}
                        onWheel={(e) => e.target.blur()}
                        addonBefore={capitalize(t('price') + ' /' + t('u.'))}
                        name="price"
                        placeholder={capitalize(t('price') + ' /' + t('u.'))}
                      />
                    </Form.Item>
                    <Form.Item
                      name="credits"
                      rules={[{
                        required: true,
                        message: capitalize(t('credits')) + ' ' + t('isRequired')
                      }]}>
                      <Input
                        type={'number'}
                        onWheel={(e) => e.target.blur()}
                        addonBefore={capitalize(t('credits'))}
                        name="credits"
                        placeholder={capitalize(t('credits'))}
                      />
                    </Form.Item>
                    {capitalize(t('total'))} <b>{(parseInt(creditsValue) * parseFloat(priceValue)).toFixed(2) + ' €'}</b>
                    <Form.Item>
                      <Button type="primary"
                        htmlType="submit">{capitalize(t('addCredits'))}</Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </React.Fragment>,
          rowExpandable: record => record.name !== 'Not Expandable',
          expandRowByClick: true
        }}
        />
        <br />
      </>
    );
  }
};

export default Credits;
