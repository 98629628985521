import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getProgramByQueryWithExercises } from '../../../services/program.service';
import Spinner from '../../../components/navigation/Spinner';
import { Button } from 'antd';
import { format, set } from 'date-fns';
import { fadeIn, bounceInUp, slideInUp, fadeOut, zoomIn, slideOutUp, merge } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { getAccountById } from '../../../services/user.service';
import { createWeight, getWeightByQuery, patchWeight } from '../../../services/weight.service';
import WeightControl from '../../../components/modals/WeightControl';
import { LogoutOutlined } from '@ant-design/icons';
import StartProgramButton from '../../../components/buttons/StartProgram';
import { useTranslation } from 'react-i18next';
import FullScreenMessageModal from '../../../components/modals/FullScreenMessage';
import { NumberPicker } from '../../../components/utils/NumberPicker';
import { getDeviceByQuery } from '../../../services/device.service';
import { capitalize } from '../../../utils/utils';
import ProgressControl from '../../../components/modals/ProgressControl';
import { getProgramSessionByQuery } from '../../../services/programsession.service';
import moment from 'moment';
import 'moment/locale/es';
import { getCenterById } from '../../../services/center.service';
import CorporalCompositionControl from '../../../components/modals/CorporalCompositionControl';
import { getCompositionByQuery, patchComposition, createComposition } from '../../../services/composition.service';
const fadeUp = merge(fadeIn, slideInUp);

const styles = {
  fadeIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  },
  fadeOut: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(fadeOut, 'fadeOut')
  },
  bounceInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceInUp, 'bounceInUp')
  },
  slideInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp')
  },
  slideOutUp: {
    animation: 'x 4s',
    animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
  },
  zoomIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(zoomIn, 'zoomIn')
  },
  fadeUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeUp, 'fadeUp')
  }
};

const ClientPrograms = (props) => {
  // const { user } = useContext(Auth);
  const params = useParams();
  const [programsPosition, setProgramPosition] = useState('center');
  const [programsHeight, setProgramHeight] = useState('80vh');
  const [openProgram, setOpenProgram] = useState(null);
  const [account, setAccount] = useState(null);
  const [weight, setWeight] = useState(null);
  const [composition, setComposition] = useState(null);
  const [showOpenProgram, setShowOpenProgram] = useState(false);
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [programsAnimation, setProgramAnimation] = useState(styles.fadeIn);
  const [programsTimeout, setProgramsTimeout] = useState();
  const [devices, setDevices] = useState([]);
  const [center, setCenter] = useState(null);
  const [weightHistory, setWeightHistory] = useState([]);
  const [compositionHistory, setCompositionHistory] = useState([]);
  const [accountWeightUpdated, setAccountWeightUpdated] = useState(true);
  const [weightModalVisible, setWeightModalVisible] = useState(false);
  const [compositionUpdated, setCompositionUpdated] = useState(true);
  const [compositionModalVisible, setCompositionModalVisible] = useState(false);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [messageModal, setMessageModal] = useState('READY');
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedWeightNumber, setSelectedWeightNumber] = useState(80);
  const [selectedWeightDecimal, setSelectedWeightDecimal] = useState(5);
  const [selectedCompositionWeightNumber, setSelectedCompositionWeightNumber] = useState(80);
  const [selectedCompositionWeightDecimal, setSelectedCompositionWeightDecimal] = useState(5);
  const [selectedFatNumber, setSelectedFatNumber] = useState(20);
  const [selectedFatDecimal, setSelectedFatDecimal] = useState(0);
  const [selectedMuscleNumber, setSelectedMuscleNumber] = useState(20);
  const [selectedMuscleDecimal, setSelectedMuscleDecimal] = useState(0);
  const [progressControlModalVisible, setProgressControlModalVisible] = useState(false);
  const [programSessions, setProgramSessions] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCloseProgressControlModal = () => {
    setProgressControlModalVisible(false);
  };

  const handleClick = (e) => {
    switch (e.detail) {
    case 1:
      break;
    case 2:
      if (document.fullscreenEnabled) {
        document.documentElement.requestFullscreen();
      }
      break;
    case 3:
      break;
    default:
    }
  };

  const weightNumbers = [];
  for (let i = 0; i < 180; i++) {
    weightNumbers.push(i);
  }

  const percentNumbers = [];
  for (let i = 0; i < 101; i++) {
    percentNumbers.push(i);
  }

  const weightDecimals = [];
  for (let i = 0; i < 10; i++) {
    weightDecimals.push(i);
  }

  useEffect(() => {
    if (programsTimeout === 'up') {
      setProgramAnimation(styles.fadeOut);
      const timer = setTimeout(() => {
        setProgramPosition('flex-start');
        setProgramHeight('auto');
        setProgramAnimation(styles.fadeUp);
        setShowOpenProgram(true);
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [programsTimeout]);

  useEffect(() => {
    if (messageModalVisible) {
      // setMessageModal(t('ready').toUpperCase());
      setMessageModal('');
      let time = 0;
      const timer = setInterval(() => {
        setMessageModal(time);
        time--;
        if (time === -1) {
          clearInterval(timer);
          setMessageModalVisible(false);
          navigate(`/training/${params.center}`);
        }
      }, 1000);
    }
  }, [messageModalVisible]);

  useEffect(() => {
    // if (user.account && user.firebaseUser) {
    loadAccount(params.account);
    // }
  }, []);

  const loadAccount = async (accountId) => {
    const { status, data } = await getAccountById(accountId);
    if (status === 200) {
      setAccount(data.data);
      await loadDevices(data.data.center);
      await loadCenter(data.data.center, data.data._id);
      const programSessions = await loadProgramSessions(data.data.center, data.data._id);
      await loadAccountWeight(data.data._id);
      await loadPrograms(params.account, programSessions);
    }
  };

  const loadCenter = async (centerId, accountId) => {
    const { status, data } = await getCenterById(centerId);
    if (status === 200) {
      if (data.data.plan[4] === '1') {
        await loadAccountComposition(accountId);
      }
      setCenter(data.data);
    }
  };

  const loadAccountComposition = async (accountId, token) => {
    const { status, data } = await getCompositionByQuery({ account: accountId }, token);
    if (status === 200) {
      if (data.data[data.data.length - 1]?.weight) {
        setComposition(data.data[data.data.length - 1]);

        setSelectedCompositionWeightNumber(data.data[data.data.length - 1].weight.toString().split('.')[0]);
        setSelectedCompositionWeightDecimal(data.data[data.data.length - 1].weight.toFixed(1).split('.')[1][0]);
        setSelectedMuscleNumber(data.data[data.data.length - 1].muscle.toString().split('.')[0]);
        setSelectedMuscleDecimal(data.data[data.data.length - 1].muscle.toFixed(1).split('.')[1][0]);
        setSelectedFatNumber(data.data[data.data.length - 1].fat.toString().split('.')[0]);
        setSelectedFatDecimal(data.data[data.data.length - 1].fat.toFixed(1).split('.')[1][0]);
        setCompositionHistory(data.data);
      } else {
        setSelectedCompositionWeightNumber(60);
        setSelectedCompositionWeightDecimal(5);
        setSelectedMuscleNumber(20);
        setSelectedMuscleDecimal(0);
        setSelectedFatNumber(20);
        setSelectedFatDecimal(0);
      }
    }
  };

  const loadAccountWeight = async (accountId, token) => {
    const { status, data } = await getWeightByQuery({ account: accountId }, token);
    if (status === 200) {
      if (data.data[data.data.length - 1]?.weight) {
        setWeight(data.data[data.data.length - 1]);
        setWeightHistory(data.data);
        // setAccountWeight(data.data[data.data.length - 1].weight);
        setSelectedWeightNumber(data.data[data.data.length - 1].weight.toString().split('.')[0]);
        setSelectedWeightDecimal(data.data[data.data.length - 1].weight.toFixed(1).split('.')[1][0]);
      } else {
        // setAccountWeight(60);
        setSelectedWeightNumber(60);
        setSelectedWeightDecimal(5);
      }
    }
  };

  const loadProgramSessions = async (centerId, accountId) => {
    const { status, data } = await getProgramSessionByQuery({ center: centerId, account: accountId });
    if (status === 200) {
      setProgramSessions(data.data);
      return data.data;
    }
  };

  const loadDevices = async (centerId) => {
    const { status, data } = await getDeviceByQuery({ center: centerId });
    if (status === 200) {
      setDevices(data.data);
    }
  };

  const loadPrograms = async (accountId, programSessions) => {
    const { status, data } = await getProgramByQueryWithExercises({ account: accountId, isActive: true });
    if (status === 200) {
      data.data.forEach(program => {
        const programSessionsFilter = programSessions.filter(programSession => programSession.program === program._id);
        program.key = program._id;
        if (programSessionsFilter.length > 0 && programSessionsFilter[programSessionsFilter.length - 1].closed) {
          program.lastSession = moment(programSessionsFilter[programSessionsFilter.length - 1].closed).format('DD/MM/YYYY');
        } else if (!programSessionsFilter.length) {
          program.lastSession = capitalize(t('noSession'));
        } else {
          program.lastSession = capitalize(t('active'));
        }
      });
      setPrograms(data.data);
      setLoading(false);
    }
  };

  const handleStartSession = () => {
    setMessageModalVisible(true);
    // navigate(`/training/${params.center}`);
  };

  const renderExercise = (exercise) => {
    const devicesWithExercise = [];
    devices.forEach(device => {
      device.exercises.forEach(ex => {
        if (ex === exercise._id) {
          devicesWithExercise.push(device);
        }
      });
    });
    return (
      <>
        <div className={`exerciseCard${center.theme}`}>
          {/* <div></div> */}
          <div style={{ }}>{exercise.name.slice(0, 25) + (exercise.name.length > 25 ? '...' : '')}</div>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', width: '100%' }}>{devicesWithExercise.map((dev, i) => { */}
          {/*  const ret = dev.identifier; */}
          {/*  if (i === devicesWithExercise.length - 1) { */}
          {/*    return ret; */}
          {/*  } else { */}
          {/*    return `${ret}, `; */}
          {/*  } */}
          {/* })}</div> */}
        </div>
      </>
    );
  };

  const renderProgram = (program) => {
    let bgColor;
    let color;
    if (openProgram === program) {
      bgColor = '#A33E3E';
      color = 'white';
    }
    return (
      <>
        <div className={`programButton${center.theme}`} style={{ background: bgColor, color: color }} onClick={() => {
          setProgramsTimeout('up');
          setOpenProgram(program);
        }}>
          {/* <div className="programButton" style={{ background: bgColor }}> */}
          <div>
            {program.name.slice(0, 20) + (program.name.length > 20 ? '...' : '')}
          </div>
          <div className={`text-color${center.theme}`} style={{ fontSize: 12 }}>
            {program.lastSession}
          </div>
          {/* </div> */}
          {/* <Card size="small" style={{ width: 200, height: 100, margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24, background: bgColor, borderRadius: 10 }} hoverable> */}
          {/* </Card> */}
        </div>

      </>
    );
  };

  const renderExercises = (program) => {
    return (
      <>
        <div style={[{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }, styles.fadeIn]}>
          {program.exercises.map(exercise => exercise.isActive ? renderExercise(exercise) : null)}
        </div>
        <div style={[{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', margin: 15 }, styles.slideInUp]}>
          <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
            setAccountWeightUpdated(false);
          }}>{capitalize(t('assignWeight'))}</button>
          <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
            setWeightModalVisible(true);
          }}>{capitalize(t('weight'))}</button>
          {center.plan[4] === '1' && <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
            setCompositionUpdated(false);
          }}>{capitalize(t('assignCorporalComposition'))}</button>}
          {center.plan[4] === '1' && <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
            setCompositionModalVisible(true);
          }}>{capitalize(t('corporalComposition'))}</button>}
          <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
            setSelectedProgram(program);
            setProgressControlModalVisible(true);
          }}>{capitalize(t('progress'))}</button>
          <StartProgramButton program={program} theme={center.theme} account={account} centerId={params.center} handleStartSession={handleStartSession}/>
        </div>
      </>
    );
  };

  const continueToPrograms = async (accountWeight, accountMuscle = undefined, accountFat = undefined) => {
    if (weight) {
      if (format(new Date(weight.created), 'dd-MM-yyyy') === format(new Date(), 'dd-MM-yyyy')) {
        await patchWeight(weight._id, { weight: accountWeight, muscle: accountMuscle, fat: accountFat });
      } else {
        await createWeight({ account: account._id, weight: accountWeight, muscle: accountMuscle, fat: accountFat });
      }
    } else {
      await createWeight({ account: account._id, weight: accountWeight, muscle: accountMuscle, fat: accountFat });
    }
    await loadAccountWeight(account._id);
    setAccountWeightUpdated(true);
  };

  const saveComposition = async (accountWeight, accountMuscle, accountFat) => {
    if (composition) {
      if (format(new Date(composition.created), 'dd-MM-yyyy') === format(new Date(), 'dd-MM-yyyy')) {
        await patchComposition(composition._id, { weight: accountWeight, muscle: accountMuscle, fat: accountFat });
      } else {
        await createComposition({ account: account._id, weight: accountWeight, muscle: accountMuscle, fat: accountFat });
      }
    } else {
      await createComposition({ account: account._id, weight: accountWeight, muscle: accountMuscle, fat: accountFat });
    }
    await loadAccountComposition(account._id);
    setCompositionUpdated(true);
  };

  if (loading) {
    return (<Spinner dark/>);
  }

  if (!accountWeightUpdated) {
    // if (center?.plan[4] === '0') {
    return (
      <>
        <div className={`background-main${center.theme}`} onClick={handleClick}>
          <div className={`background-sub${center.theme}`} style={{ height: '100vh' }}>
            <div style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button onClick={() => {
                navigate(`/training/${params.center}`);
              }}
              style={{ height: '100%', border: 0, boxShadow: 'none', display: 'flex', alignItems: 'center' }} ghost ><span className={`text-color${center.theme}`} style={{ fontSize: 18 }}>
                  {account?.name} {account?.surname}
                </span><LogoutOutlined className={`text-color${center.theme}`} style={{ fontSize: 24 }} /></Button>
            </div>
            {/* <StyleRoot> */}
            {/* <div style={[{ height: programsHeight }, programsAnimation]}> */}
            <div className={`text-color${center.theme}`} style={{ fontSize: 22, marginBottom: 40, marginTop: 150, textAlign: 'center' }}>
              {capitalize(t('weightBefore'))}
            </div>
            <div style={{ display: 'block', width: '100%' }}>
              <NumberPicker
                theme={center.theme}
                numbers={weightNumbers}
                decimals={weightDecimals}
                selectedNumber={selectedWeightNumber}
                setSelectedNumber={setSelectedWeightNumber}
                selectedDecimal={selectedWeightDecimal}
                setSelectedDecimal={setSelectedWeightDecimal}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 25, width: '100%' }}>
              <button className={`basicButton${center.theme}`} onClick={() => {
                const weight = selectedWeightNumber + '.' + selectedWeightDecimal;
                // setAccountWeight(weight);
                continueToPrograms(weight);
              }}>{t('next').toUpperCase()}</button>
            </div>
            {/* </div> */}
            {/* </StyleRoot> */}
          </div>
        </div>
      </>
    );
  }

  if (!compositionUpdated) {
    return (
      <>
        <div className={`background-main${center.theme}`} onClick={handleClick}>
          <div className={`background-sub${center.theme}`} style={{ height: '100vh' }}>
            <div style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button onClick={() => {
                navigate(`/training/${params.center}`);
              }}
              style={{ height: '100%', border: 0, boxShadow: 'none', display: 'flex', alignItems: 'center' }} ghost ><span className={`text-color${center.theme}`} style={{ fontSize: 18 }}>
                  {account?.name} {account?.surname}
                </span><LogoutOutlined className={`text-color${center.theme}`} style={{ fontSize: 24 }} /></Button>
            </div>
            <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div className={`text-color${center.theme}`} style={{ fontSize: 22, marginBottom: 12, marginTop: 12, textAlign: 'center' }}>
                {capitalize(t('weightBefore'))}
              </div>
              <div style={{ display: 'block', width: '100%' }}>
                <NumberPicker
                  theme={center.theme}
                  numbers={weightNumbers}
                  decimals={weightDecimals}
                  selectedNumber={selectedCompositionWeightNumber}
                  setSelectedNumber={setSelectedCompositionWeightNumber}
                  selectedDecimal={selectedCompositionWeightDecimal}
                  setSelectedDecimal={setSelectedCompositionWeightDecimal}
                />
              </div>
              <div style={{ minHeight: 60 }}/>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'block', width: '100%', position: 'relative', maxWidth: 600 }}>
                  <div style={{ fontSize: 22, display: 'flex', justifyContent: 'center', marginBottom: 12 }}>{capitalize(t('muscle'))}</div>
                  <NumberPicker
                    theme={center.theme}
                    numbers={weightNumbers}
                    decimals={weightDecimals}
                    selectedNumber={selectedMuscleNumber}
                    setSelectedNumber={setSelectedMuscleNumber}
                    selectedDecimal={selectedMuscleDecimal}
                    setSelectedDecimal={setSelectedMuscleDecimal}
                    wheelClass='half-wheel'
                  />
                </div>
                <div style={{ display: 'block', width: '100%', position: 'relative', maxWidth: 600 }}>
                  <div style={{ fontSize: 22, display: 'flex', justifyContent: 'center', marginBottom: 12 }}>{capitalize(t('fat'))}</div>
                  <NumberPicker
                    theme={center.theme}
                    numbers={percentNumbers}
                    decimals={weightDecimals}
                    selectedNumber={selectedFatNumber}
                    setSelectedNumber={setSelectedFatNumber}
                    selectedDecimal={selectedFatDecimal}
                    setSelectedDecimal={setSelectedFatDecimal}
                    percentResult={true}
                    wheelClass='half-wheel'
                  />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 25, width: '100%' }}>
                <button className={`basicButton${center.theme}`} onClick={() => {
                  const weight = selectedCompositionWeightNumber + '.' + selectedCompositionWeightDecimal;
                  const muscle = selectedMuscleNumber + '.' + selectedMuscleDecimal;
                  const fat = selectedFatNumber + '.' + selectedFatDecimal;
                  saveComposition(weight, muscle, fat);
                }}>{t('next').toUpperCase()}</button>
              </div>
            </div>

          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div className={`background-sub${center.theme}`} style={{ height: '100vh' }}>
          <div style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button onClick={() => navigate(`/training/${params.center}`)}
              style={{ height: '100%', border: 0, boxShadow: 'none', display: 'flex', alignItems: 'center' }} ghost ><span className={`text-color${center.theme}`} style={{ fontSize: 18 }}>
                {account?.name} {account?.surname}
              </span><LogoutOutlined className={`text-color${center.theme}`} style={{ fontSize: 24 }} /></Button>
          </div>
          <StyleRoot>
            <div style={[{ display: 'flex', alignItems: programsPosition, justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', height: programsHeight, width: '100%' }, programsAnimation]}>
              {programs?.map(program => renderProgram(program))}
            </div>
            {openProgram && showOpenProgram
              ? (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', height: '70vh' }}>
                {renderExercises(openProgram)}
              </div>)
              : (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
                    setAccountWeightUpdated(false);
                  }}>{capitalize(t('assignWeight'))}</button>
                  <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
                    setWeightModalVisible(true);
                  }}>{capitalize(t('weight'))}</button>
                  {center?.plan[4] === '1' &&
                    <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
                      setCompositionUpdated(false);
                    }}>{capitalize(t('assignCorporalComposition'))}</button>
                  }
                  {center?.plan[4] === '1' &&
                    <button className={`basicButton${center.theme}`} style={{ margin: 15 }} onClick={() => {
                      setCompositionModalVisible(true);
                    }}>{capitalize(t('corporalComposition'))}</button>
                  }
                </div>
              )}
            {weightHistory && <WeightControl weightModalVisible={weightModalVisible} setWeightModalVisible={setWeightModalVisible} weightHistory={weightHistory}/>}
            {compositionHistory && center?.plan[4] === '1' && <CorporalCompositionControl compositionModalVisible={compositionModalVisible} setCompositionModalVisible={setCompositionModalVisible} compositionHistory={compositionHistory}/>}
            {openProgram && <ProgressControl program={openProgram._id} progressControlModalVisible={progressControlModalVisible} handleCloseProgressControlModal={handleCloseProgressControlModal} />}
            {messageModalVisible && <FullScreenMessageModal messageModalVisible={messageModalVisible} setMessageModalVisible={setMessageModalVisible} message={messageModal}/>}
          </StyleRoot>
        </div>
      </div>
    </>
  );
};
export default ClientPrograms;
