import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getProgramByQuery } from '../../../services/program.service';
import TagExercise from '../../../components/tags/TagExercise';
import { getAccountById } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

const Programs = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const navigate = useNavigate();
  const [programs, setPrograms] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadPrograms(params.account);
      loadAccount(params.account);
    }
  }, [user.account]);

  const loadPrograms = async (userId) => {
    const { status, data } = await getProgramByQuery({ account: userId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(program => {
        program.key = program._id;
      });
      setPrograms(data.data);
      setLoading(false);
    }
  };

  const loadAccount = async (userId) => {
    const { status, data } = await getAccountById(userId, user.token);
    if (status === 200) {
      setAccount(data.data);
    }
  };

  const columns = [
    {
      title: capitalize(t('programs')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
          <br />
          {record.isActive ? capitalize(t('active')) : capitalize(t('noActive'))}
          <br />
          {record.exercises.length
            ? (
              record.exercises.map((exercise) => {
                return (
                  <TagExercise key={exercise} id={exercise} user={user} />
                );
              })
            )
            : (<>{capitalize(t('noExercises'))}</>)}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: 'name',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('isActive')),
      key: 'isActive',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.isActive ? capitalize(t('true')) : capitalize(t('false'))}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.isActive.localeCompare(b.isActive),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.exercises.length
            ? (
              record.exercises.map((exercise) => {
                return (
                  <TagExercise key={exercise} id={exercise} user={user} />
                );
              })
            )
            : (<>{capitalize(t('noExercises'))}</>)}
        </React.Fragment>
      ),
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewProgram'))}
        </Button>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b>{account ? account.name + ' ' + account.surname : ''}</b>
        </div>
        <Table columns={columns} dataSource={programs} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { navigate('/dashboard/users/programs/edit/' + record._id); }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  };
};

export default Programs;
