import React, { useEffect, useState } from 'react';

const ExerciseChrono = (props) => {
  const [diff, setDiff] = useState(null);
  const [initial, setInitial] = useState(null);
  const [startChrono, setStartChrono] = useState(false);

  useEffect(() => {
    start();
  }, []);

  const tick = () => {
    const update = new Date(+new Date() - initial);
    setDiff(update);
  };
  const start = () => {
    if (!startChrono) {
      setInitial(+new Date());
      setStartChrono(true);
    }
    if (startChrono) setStartChrono(false);
  };

  useEffect(() => {
    if (initial && startChrono && props.startExercise) {
      tick();
    }
  }, [initial]);

  useEffect(() => {
    if (diff && startChrono) {
      tick();
    }
    props.handleExerciseTime(diff);
  }, [diff]);

  const timeFormat = (date) => {
    if (!date) return '00:00';

    let mm = date.getMinutes();
    let ss = date.getSeconds();

    ss = ss < 10 ? '0' + ss : ss;
    mm = mm < 10 ? '0' + mm : mm;

    return `${mm}:${ss}`;
  };

  return (
    <div className="App" onClick={start}>
      {timeFormat(diff)}
    </div>
  );
};

export default ExerciseChrono;
