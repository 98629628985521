import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Popconfirm, message } from 'antd';
import { signUpApp } from '../../../services/firebase/firebaseConfig';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getAuth, createUserWithEmailAndPassword, signOut, getIdToken } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { createAccount, getAccountById, patchAccount } from '../../../services/user.service';
import Spinner from '../../../components/navigation/Spinner';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateOrganizer = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [error, seterror] = useState('');
  const [organizer, setOrganizer] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (params.id) {
        setLoading(true);
        loadOrganizer(params.id);
      }
    }
  }, [user.account]);

  const loadOrganizer = async (id) => {
    const { status, data } = await getAccountById(id, user.token);
    if (status === 200) {
      setOrganizer(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const fillForm = (organizer) => {
    form.setFieldsValue({
      email: organizer.email,
      name: organizer.name,
      surname: organizer.surname,
      phone: organizer.phone,
      birthdate: organizer.birthdate,
      position: organizer.position,
      nif: organizer.nif
    });
  };

  const deactivateOrganizer = async () => {
    if (params.id) {
      await patchAccount(params.id, { isActive: false }, user.token);
      message.success(capitalizeAll(t('organizerDeleted')));
      navigate('/dashboard/organizers');
    }
  };

  const createNewAccount = async (data) => {
    const formData = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      firebaseUid: null,
      birthdate: data.birthdate,
      position: data.position,
      phone: data.phone,
      roles: 'organizer',
      nif: data.nif
    };
    if (params.id) await patchAccount(params.id, formData, user.token);
    else {
      formData.password = data.passwd;
      await createAccount(formData, user.token);
    }
    navigate('/dashboard/organizers');
  };

  const correctClave = async e => {
    await createNewAccount(e);
    // if (params.id) {
    //   await createNewAccount(e);
    // } else {
    //   const { email, passwd } = e;
    //   const auth = getAuth(signUpApp);
    //   await createUserWithEmailAndPassword(auth, email, passwd)
    //     .then(async result => {
    //       createNewAccount(e, result.user.uid);
    //     })
    //     .catch(error => {
    //       seterror(error.message);
    //     });
    //   await signOut(auth);
    // }
  };

  if (loading) {
    return <Spinner/>;
  }

  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {organizer ? <h1>{capitalizeAll(t('editOrganizer'))}</h1> : <h1>{capitalizeAll(t('createOrganizer'))}</h1>}
        </Form.Item>
        {error ? <Form.Item>{error}</Form.Item> : null}
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: capitalize(t('email')) + ' ' + t('isRequired')
            }
          ]}>
          {organizer
            ? (
              <Input
                addonBefore={capitalize(t('email'))}
                name="email"
                defaultValue={organizer ? organizer.email : ''}
                placeholder={capitalize(t('email'))}
                disabled
              />
            )
            : (
              <Input
                addonBefore={capitalize(t('email'))}
                name="email"
                placeholder={capitalize(t('email'))}
              />
            )}
        </Form.Item>
        {organizer
          ? null
          : (<Form.Item name="passwd"
            rules={[
              {
                required: true,
                message: capitalize(t('password')) + ' ' + t('isRequired')
              }
            ]}>
            {organizer
              ? (
                <Input
                  addonBefore={capitalize(t('password'))}
                  name="passwd"
                  type="password"
                  placeholder={capitalize(t('password'))}
                  disabled
                />
              )
              : (
                <Input
                  addonBefore={capitalize(t('password'))}
                  name="passwd"
                  type="password"
                  placeholder={capitalize(t('password'))}
                />
              )}
          </Form.Item>)}

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: capitalize(t('name')) + ' ' + t('isRequired')
            }
          ]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={organizer ? organizer.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <Form.Item
          name="surname"
          rules={[
            {
              required: true,
              message: capitalize(t('surname')) + ' ' + t('isRequired')
            }
          ]}>
          <Input
            addonBefore={capitalize(t('surname'))}
            name="surname"
            defaultValue={organizer ? organizer.surname : ''}
            placeholder={capitalize(t('surname'))}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: capitalize(t('phone')) + ' ' + t('isRequired')
            }
          ]}>
          <Input
            addonBefore={capitalize(t('phone'))}
            name="phone"
            defaultValue={organizer ? organizer.phone : ''}
            placeholder={capitalize(t('phone'))}
          />
        </Form.Item>
        <Form.Item
          name="birthdate"
          rules={[
            {
              required: true,
              message: capitalize(t('birthdate')) + ' ' + t('isRequired')
            }
          ]}>
          <Input
            addonBefore={capitalize(t('birthdate'))}
            name="birthdate"
            defaultValue={organizer ? organizer.birthdate : ''}
            placeholder={capitalize(t('birthdate'))}
            type="date"
          />
        </Form.Item>
        <Form.Item
          name="position"
          rules={[
            {
              required: true,
              message: capitalize(t('position')) + ' ' + t('isRequired')
            }
          ]}>
          <Input
            addonBefore={capitalize(t('position'))}
            name="position"
            defaultValue={organizer ? organizer.position : ''}
            placeholder={capitalize(t('position'))}
          />
        </Form.Item>
        <Form.Item
          name="nif"
          rules={[
            {
              required: true,
              message: t('nif').toUpperCase() + ' ' + t('isRequired')
            }
          ]}>
          <Input
            addonBefore={t('nif').toUpperCase()}
            name="nif"
            defaultValue={organizer ? organizer.nif : ''}
            placeholder={t('nif').toUpperCase()}
          />
        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {organizer && (
            <Popconfirm
              title={capitalize(t('confirmDeleteOrganizer'))}
              onConfirm={deactivateOrganizer}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {organizer ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createOrganizer'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateOrganizer;
