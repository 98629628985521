import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import Spinner from '../../../components/navigation/Spinner';
import { Col, Row } from 'antd';
import { getIdToken } from 'firebase/auth';
import { getAccountByQuery } from '../../../services/user.service';
import { getCenterByQuery } from '../../../services/center.service';
import ResumeDashboard from '../../../components/cards/CardResumeDashboard';
import { DollarOutlined, TeamOutlined, BarChartOutlined } from '@ant-design/icons';
import { capitalizeAll } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { getProgramSessionByQuery } from '../../../services/programsession.service';

const UserHome = (props) => {
  const { user } = useContext(Auth);
  // const [devices, setDevices] = useState([]);
  const [centers, setCenters] = useState([]);
  const [users, setUsers] = useState([]);
  const [programSessions, setProgramSessions] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  // const [programSessions, setProgramSessions] = useState([]);

  useEffect(() => {
    if (user.account) {
      loadUserSessions();
    }
  }, [user.account]);

  const loadUserSessions = async () => {
    const { status, data } = await getProgramSessionByQuery({ account: user.account._id }, user.token);
    if (status === 200) {
      setProgramSessions(data.data);
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner/>;
  }

  return (
    <>
      <Row justify='center' style={{ gap: 15, marginBottom: 25 }}>
        {/* <ResumeDashboard title={capitalizeAll(t('users'))} icon={<TeamOutlined />} value={users.length}/> */}
        <ResumeDashboard title={capitalizeAll(t('sessions'))} icon={<BarChartOutlined />} value={programSessions.length}/>
        <ResumeDashboard title={capitalizeAll(t('credits'))} icon={<DollarOutlined />} value={user.account.credits}/>
        {/* <ResumeDashboard title={'Earnings'} icon={<DollarOutlined />} value={'0€'}/> */}
      </Row>
      <Row>
        <Col xs = {24} md={12}>
          <p><b></b></p>
          {/* {devices.map(device => renderDevice(device))} */}
          <br />
        </Col>
      </Row>

    </>
  );
};

export default UserHome;
