import React from 'react';
import { css } from '@emotion/react';
import { BarLoader, ClockLoader } from 'react-spinners';

const Spinner = (props) => {
  if (props.theme === '-light') {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
      // <ScaleLoader color={'#0050B3'} loading={true} css={override} height={160} width={10} radius={10} margin={4} />
      <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#222 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANklEQVQYV2NkwAMEBQX/M+KSB0m+f/+eEasCmCRIM4YCZEkMBeiSKAqwScIV4JIEK8AnCVIAAKedHTuzxDwjAAAAAElFTkSuQmCC) repeat' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(145deg, rgba(235,235,235,1) 100%, rgba(122,122,122,0.2945553221288515) 50%, rgba(64,64,64,0.50) 50%)', height: '100vh' }}>
          <BarLoader color={'#222'} css={override} loading={true} width={300} size={100}/>
        </div>
      </div>
    );
  }

  if (props.dark) {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    return (
      // <ScaleLoader color={'#0050B3'} loading={true} css={override} height={160} width={10} radius={10} margin={4} />
      <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#222 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANklEQVQYV2NkwAMEBQX/M+KSB0m+f/+eEasCmCRIM4YCZEkMBeiSKAqwScIV4JIEK8AnCVIAAKedHTuzxDwjAAAAAElFTkSuQmCC) repeat' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(145deg, rgba(255,255,255,0.19371498599439774) 100%, rgba(122,122,122,0.2945553221288515) 50%, rgba(64,64,64,0.50) 50%)', height: '100vh' }}>
          <BarLoader color={'#d8d8d8'} css={override} loading={true} width={300} size={100}/>
        </div>
      </div>
    );
  }

  const override = css`
  display: block;
  margin: 150px auto;
  border-color: black;
`;
  return (
    <ClockLoader color={'#0050B3'} loading={true} css={override} size={100}/>
  );
};

export default Spinner;
