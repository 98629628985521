import React, { useEffect, useState } from 'react';
import Modal from 'antd/es/modal/Modal';
import { Button, Row } from 'antd';
import { getIdToken } from 'firebase/auth';
import { patchBooking, sendEmail } from '../../services/booking.service';
import Spinner from '../navigation/Spinner';
import { patchAccount } from '../../services/user.service';
import moment from 'moment';
import 'moment/locale/es';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
moment.locale('es');

const BookingUserCreate = (props) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.booking) {
      setLoading(false);
    }
  }, [props.booking]);

  const editBooking = async (payload) => {
    const { status, data } = await patchBooking(props.booking._id, payload, props.user.token);

    if (status === 200) {

    }
  };

  const updateCredits = async (payload, action) => {
    const { status, data } = await patchAccount(props.account._id, payload, props.user.token);
    if (status === 200) {
      if (action === 'create') {
        await sendEmail({
          account: props.account._id,
          center: props.center._id,
          trainer: props.booking.trainer._id,
          booking: props.booking._id,
          action: 'create',
          name: props.booking.name,
          startTime: moment(props.booking.start).format('HH:mm'),
          endTime: moment(props.booking.end).format('HH:mm'),
          date: moment(props.booking.start).format('LL')
        });
      } else if (action === 'cancel') {
        await sendEmail({
          account: props.account._id,
          center: props.center._id,
          trainer: props.booking.trainer._id,
          booking: props.booking._id,
          action: 'cancel',
          name: props.booking.name,
          startTime: moment(props.booking.start).format('HH:mm'),
          endTime: moment(props.booking.end).format('HH:mm'),
          date: moment(props.booking.start).format('LL')
        });
      }
    }
  };

  // const updateQueue = async (payload) => {
  //   const { status, data } = await patchAccount(props.account._id, payload, props.user.token);
  //
  // };
  const isInPenaltyHours = () => {
    const penaltyHours = props.center.bookingSettings?.penaltyHoursCancel || 0;
    const now = moment();
    const start = moment(props.booking.start);
    const diff = start.diff(now, 'hours');
    if (diff < penaltyHours && penaltyHours > 0) {
      return true;
    }
    return false;
  };

  const isInMinHours = () => {
    const minHours = props.center.bookingSettings?.minHoursBooking || 0;
    const now = moment();
    const start = moment(props.booking.start);
    const diff = start.diff(now, 'hours');
    if (diff < minHours && minHours > 0) {
      return false;
    }
    return true;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        title={capitalizeAll(t('booking'))}
        visible={props.bookingDataVisible}
        onOk={() => {
          props.handleUserCreateBooking();
        }}
        onCancel={() => {
          props.handleUserCreateBooking();
        }}

        onPan={(p) => { }}
        centered
        footer={[
          // eslint-disable-next-line react/jsx-key
          <div>{!props.account.credits || props.account.credits <= 0 ? capitalize(t('notEnoughCredits')) : ''}</div>,
          // eslint-disable-next-line react/jsx-key
          <div>
            {/* eslint-disable-next-line multiline-ternary */}
            {(props.booking?.account?._id === props.account._id) ||
            (props.booking.isGroup && props.booking.group.includes(props.account._id)) ? (
                <>
                  <div style={{ fontSize: 12, color: 'red' }}>
                    {isInPenaltyHours() ? capitalize(t('cancelPenalty')) + props.center.bookingSettings.penaltyHoursCancel + t('cancelPenalty2') : ''}
                  </div>
                  <Button key="OK" onClick={async () => {
                    try {
                      let payload;
                      if (props.booking.isGroup) {
                        const newGroup = props.booking.group.filter(item => item !== props.account._id);
                        payload = { group: newGroup };
                      } else {
                        payload = {
                          account: null
                        };
                      }
                      // if (props.booking?.queue?.length > 0) {
                      //   const newAccount = props.booking.queue[0];
                      //   const newQueue = props.booking.queue.slice(1);
                      //   payload = {
                      //     queue: newQueue,
                      //     account: newAccount
                      //   };
                      // } else {
                      //   payload = {
                      //     queue: [],
                      //     account: null
                      //   };
                      // }
                      await editBooking(payload);
                      let credits = props.account.credits + 1;
                      if (isInPenaltyHours()) {
                        credits = props.account.credits;
                      }
                      await updateCredits({ credits: credits }, 'cancel');
                      props.handleUserCreateBooking();
                    } catch (error) {

                    }
                  }}>
                    {t('cancelBook').toUpperCase()}
                  </Button>
                </>
              )
              : (
                props.booking?.queue?.length > 0 && props.booking?.queue.filter((b) => b === props.account._id).length > 0
                  ? (props.booking.account
                    ? (
                      <Button key="OK" onClick={async () => {
                        try {
                          const newQueue = props.booking.queue.filter((b) => b !== props.account._id);
                          const payload = {
                            queue: newQueue
                          };
                          await editBooking(payload);
                          props.handleUserCreateBooking();
                        } catch (error) {

                        }
                      }}>
                        {t('leaveQueue').toUpperCase()}
                      </Button>
                    )
                    : (<>
                      <Button key="OK" onClick={async () => {
                        try {
                          const newQueue = props.booking.queue.filter((b) => b !== props.account._id);
                          const payload = {
                            queue: newQueue
                          };
                          await editBooking(payload);
                          props.handleUserCreateBooking();
                        } catch (error) {

                        }
                      }}>
                        {t('leaveQueue').toUpperCase()}
                      </Button>
                      {props.booking.isGroup
                        ? props.booking.group?.length < props.booking.slots && (
                          <Button disabled={!isInMinHours()} key="OK" onClick={async () => {
                            try {
                              const newQueue = props.booking.queue.filter((b) => b !== props.account._id);
                              let payload;
                              if (props.booking.isGroup) {
                                let newGroup;
                                if (props.booking.group.length > 0) {
                                  newGroup = [...props.booking.group];
                                } else {
                                  newGroup = [];
                                }
                                newGroup.push(props.account._id);
                                payload = {
                                  queue: newQueue,
                                  group: newGroup
                                };
                              } else {
                                payload = {
                                  queue: newQueue,
                                  account: props.account._id
                                };
                              }
                              await editBooking(payload);
                              await updateCredits({ credits: props.account.credits - 1 }, 'create');
                              props.handleUserCreateBooking();
                            } catch (error) {

                            }
                          }}>
                            {isInMinHours() ? t('book').toUpperCase() : capitalize(t('minHoursReached')) + props.center.bookingSettings.minHoursBooking + t('minHoursReached2')}
                          </Button>
                        )
                        : (
                          <Button key="OK" disabled={!isInMinHours()} onClick={async () => {
                            try {
                              const newQueue = props.booking.queue.filter((b) => b !== props.account._id);
                              let payload;
                              if (props.booking.isGroup) {
                                let newGroup;
                                if (props.booking.group.length > 0) {
                                  newGroup = [...props.booking.group];
                                } else {
                                  newGroup = [];
                                }
                                newGroup.push(props.account._id);
                                payload = {
                                  queue: newQueue,
                                  group: newGroup
                                };
                              } else {
                                payload = {
                                  queue: newQueue,
                                  account: props.account._id
                                };
                              }
                              await editBooking(payload);
                              await updateCredits({ credits: props.account.credits - 1 }, 'create');
                              props.handleUserCreateBooking();
                            } catch (error) {

                            }
                          }}>
                            {isInMinHours() ? t('book').toUpperCase() : capitalize(t('minHoursReached')) + props.center.bookingSettings.minHoursBooking + t('minHoursReached2')}
                          </Button>
                        )}
                    </>
                    )
                  )
                  : props.booking.isGroup
                    ? (
                      <Button key="OK" disabled={props.account.credits <= 0 || !props.account.credits || !isInMinHours()} onClick={async () => {
                        if (props.account.credits <= 0) {
                          return;
                        }
                        try {
                          let payload;
                          if (props.booking.group?.length > props.booking.slots - 1) {
                            if (props.booking.queue.length > 0) {
                              const newQueue = [...props.booking.queue];
                              newQueue.push(props.account._id);
                              payload = {
                                queue: newQueue
                              };
                            } else {
                              payload = {
                                queue: [props.account._id]
                              };
                            }
                          } else {
                            let newGroup;
                            if (props.booking.group?.length > 0) {
                              newGroup = [...props.booking.group];
                              newGroup.push(props.account._id);
                            } else {
                              newGroup = [props.account._id];
                            }
                            payload = {
                              group: newGroup
                            };
                            if (props.account.credits > 0) {
                              await updateCredits({ credits: props.account.credits - 1 }, 'create');
                            }
                          }
                          await editBooking(payload);
                          props.handleUserCreateBooking();
                        } catch (error) {

                        }
                      }}>
                        {props.booking.group?.length > props.booking.slots - 1 ? t('queue').toUpperCase() : isInMinHours() ? t('book').toUpperCase() : capitalize(t('minHoursReached')) + props.center.bookingSettings.minHoursBooking + t('minHoursReached2')}
                      </Button>
                    )
                    : (
                      <Button key="OK" disabled={props.account.credits <= 0 || !props.account.credits || !isInMinHours()} onClick={async () => {
                        if (props.account.credits <= 0) {
                          return;
                        }
                        try {
                          let payload;
                          if (props.booking.account && props.booking.account !== 'undefined') {
                            if (props.booking.queue.length > 0) {
                              const newQueue = [...props.booking.queue];
                              newQueue.push(props.account._id);
                              payload = {
                                queue: newQueue
                              };
                            } else {
                              payload = {
                                queue: [props.account._id]
                              };
                            }
                          } else {
                            payload = {
                              account: props.account._id
                            };
                            if (props.account.credits > 0) {
                              await updateCredits({ credits: props.account.credits - 1 }, 'create');
                            }
                          }
                          await editBooking(payload);
                          props.handleUserCreateBooking();
                        } catch (error) {

                        }
                      }}>
                        {props.booking.account && props.booking.account !== 'undefined' ? t('queue').toUpperCase() : isInMinHours() ? t('book').toUpperCase() : capitalize(t('minHoursReached')) + props.center.bookingSettings.minHoursBooking + t('minHoursReached2')}
                      </Button>
                    )
              )}
          </div>
        ]}
      >
        <Row>
          <b>{capitalize(t('center'))}</b>: {props.center.name} <br/>
        </Row>
        <Row>
          <b>{capitalize(t('name'))}</b>: {props.booking.name} <br/>
        </Row>
        <Row>
          <b>{capitalize(t('trainer'))}</b>: {props.booking.trainer.name + ' ' + props.booking.trainer.surname} <br/>
        </Row>
        {props.booking.queue.length > 0 && (
          <Row>
            <b>{capitalize(t('queue'))}</b>: {props.booking.queue.length} {capitalize('persons')}<br/>
          </Row>
        )}
        <Row>
          <b>{capitalize(t('day'))}</b>: {moment(props.booking.start).format('LL')}
        </Row>
        <Row>
          <b>{capitalizeAll(t('startTime'))}</b>: {moment(props.booking.start).format('LT')}
        </Row>
        <Row>
          <b>{capitalizeAll(t('endTime'))}</b>: {moment(props.booking.end).format('LT')}
        </Row>
      </Modal>
    </>
  );
};

export default BookingUserCreate;
