import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllEvaluations = (token) => {
  return methodGet('evaluation/all', '', token);
};

export const getEvaluationById = (id, token) => {
  return methodGet('evaluation/' + id, '', token);
};

export const getEvaluationByQuery = (req, token) => {
  return methodGet('evaluation', req, token);
};

export const createEvaluation = (data, token) => {
  return methodPost('evaluation/create', data, token);
};

export const patchEvaluation = (id, data, token) => {
  return methodPatch('evaluation/update?id=' + id, data, token);
};
