import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAccountByUid = (uid, token) => {
  return methodGet('account/firebase', { uid }, token);
};

export const getAccountById = (id, token) => {
  return methodGet('account/' + id, '', token);
};

export const getAccountByQuery = (req, token) => {
  return methodGet('account', req, token);
};

export const getAccountByQueryWithPrograms = (req, token) => {
  return methodGet('account/with-programs', req, token);
};

export const createAccount = (data, token) => {
  return methodPost('account/create', data, token);
};

export const getLowCreditAccounts = (center, token) => {
  return methodGet('account/low-credit?center=' + center, '', token);
};

export const sendEmail = (id, data, token) => {
  return methodPost('account/send-email?id=' + id, data, token);
};

export const patchAccount = (id, data, token) => {
  return methodPatch('account/update?id=' + id, data, token);
};

export const patchAccountPassword = (id, data, token) => {
  return methodPatch('account/update/password?id=' + id, data, token);
};

export const resetPassword = (id, token) => {
  return methodPost('account/reset-password?id=' + id, {}, token);
};
