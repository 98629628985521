import React, { useState, useContext, useEffect } from 'react';
import { Table } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useParams } from 'react-router-dom';
import { getCenterByQuery } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { getStrengthById } from '../../../services/strength.service';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';

const StrengthTest = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [strength, setStrength] = useState(null);
  const [evalData, setEvalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      if (params.id) {
        await loadStrength(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadStrength = async (id, token) => {
    const { status, data } = await getStrengthById(id, token);
    if (status === 200) {
      setEvalData(data.data.eval);
      setStrength(data.data);
    }
  };

  const defaultColumns = [
    {
      title: capitalize(t('tilt')),
      dataIndex: 'tilt',
      editable: true
    },
    {
      title: capitalize(t('strength')),
      dataIndex: 'weight',
      editable: true
    }
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    zoomPlugin
    // ChartDataLabels
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: capitalize(t('strength'))
        },
        ticks: {
          stepSize: 5
        }
        // suggestedMin: 20,
        // suggestedMax: 120
      },
      x: {
        title: {
          display: true,
          text: capitalize(t('tilt'))
        }
      }
    },
    plugins: {
      datalabels: {
        font: {
          size: '18px'
        }
      }
    }
  };

  const labels = evalData.map(evalD => evalD.tilt);

  const data = {
    labels,
    datasets: [
      {
        id: 1,
        label: capitalize(t('strength')),
        borderColor: 'rgba(193, 221, 230,1)',
        backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: true,
        data: evalData.map(evalD => evalD.weight)
      }
    ]
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div>
      <div style={{ height: 300 }}>
        <Line
          datasetIdKey='id'
          data={data}
          options={options}
        />
      </div>
      <div>
        <Table columns={defaultColumns} dataSource={evalData} onRow={(record, rowIndex) => {
          return {
            onClick: event => {}, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
      </div>
    </div>
  );
};
export default StrengthTest;
