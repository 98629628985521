import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllWeights = (token) => {
  return methodGet('weight/all', '', token);
};

export const getWeightById = (id, token) => {
  return methodGet('weight/' + id, '', token);
};

export const getWeightByQuery = (req, token) => {
  return methodGet('weight', req, token);
};

export const createWeight = (data, token) => {
  return methodPost('weight/create', data, token);
};

export const patchWeight = (id, data, token) => {
  return methodPatch('weight/update?id=' + id, data, token);
};
