import React, { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'moment/locale/es';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getWeightByQuery } from '../../../services/weight.service';
import { getIdToken } from 'firebase/auth';
import Spinner from '../../../components/navigation/Spinner';
import { Table } from 'antd';
import { useParams } from 'react-router-dom';
moment.locale('es');

const UserWeightLog = (props) => {
  const { user } = useContext(Auth);
  const [weights, setWeights] = useState([]);
  const [weightsReverse, setWeightsReverse] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (user.account) {
      loadWeights();
    }
  }, [user.account]);

  const loadWeights = async () => {
    const { status, data } = await getWeightByQuery({ account: params.account ? params.account : user.account._id }, user.token);
    if (status === 200) {
      setWeights(data.data.sort((a, b) => new Date(a.created) - new Date(b.created)));
      const reverse = [...data.data];
      const chart = [...data.data.slice(Math.max(data.data.length - 30, 0))];
      reverse.reverse();
      setWeightsReverse(reverse);
      setChartData(chart);
      setLoading(false);
    }
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    zoomPlugin
    // ChartDataLabels
  );

  if (loading) {
    return <Spinner/>;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Kg.'
        },
        ticks: {
          stepSize: 10
        }
        // suggestedMin: 20,
        // suggestedMax: 120
      }
    },
    plugins: {
      datalabels: {
        font: {
          size: '18px'
        }
      }
    }
  };

  const labels = chartData.map(weight => moment(weight.timestamp.createdAt).format('L'));

  const data = {
    labels,
    datasets: [
      {
        id: 1,
        label: 'Weight',
        borderColor: 'rgba(193, 221, 230,1)',
        backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: true,
        data: chartData.map(weight => weight.weight)
      }
    ]
  };

  const columns = [
    {
      title: 'Devices',
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.timestamp.createdAt}
          <br />
          {record.weight}Kg
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: 'Date',
      dataIndex: ['timestamp.createdAt'],
      key: 'date',
      align: 'center',
      render: (text, record) => moment(record.timestamp.createdAt).format('DD/MM/YYYY'),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: 'Weight',
      align: 'center',
      // dataIndex: ['name'],
      key: 'weight',
      // render: (text, record) => record.name,
      render: (text, record) => record.weight + 'Kg',
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  return (
    <>
      <div style={{ height: 300 }}>
        <Line
          datasetIdKey='id'
          data={data}
          options={options}
        />
      </div>

      <Table columns={columns} dataSource={weightsReverse} onChange={onChange}/>
    </>

  );
};

export default UserWeightLog;
