import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Context as Auth } from '../../../services/auth/AuthContext';
import Spinner from '../../../components/navigation/Spinner';
import { Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getBookingPopulatedReq, getBookingByQuery } from '../../../services/booking.service';
import { capitalize } from '../../../utils/utils';
import { getAccountById, getAccountByQuery } from '../../../services/user.service';

moment.locale('es');

const UserBookings = (props) => {
  const { user } = useContext(Auth);
  const [bookings, setBookings] = useState([]);
  const [groupBookings, setGroupBookings] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadAccount();
    }
  }, [user.account]);

  const loadAccount = async () => {
    const { status, data } = await getAccountById(params.account, user.token);
    if (status === 200) {
      await loadTrainers(data.data.center);
      await loadBookings();
      await loadGroupBookings();
    }
  };

  const loadTrainers = async (center) => {
    const { status, data } = await getAccountByQuery({ center: center, role: 'trainer' }, user.token);
    if (status === 200) {
      setTrainers(data.data);
    }
  };

  const loadBookings = async () => {
    const { status, data } = await getBookingByQuery({ account: params.account });
    if (status === 200) {
      setBookings(data.data.reverse());
    }
  };

  const loadGroupBookings = async () => {
    const { status, data } = await getBookingByQuery({ group: params.account });
    if (status === 200) {
      setGroupBookings(data.data.reverse());
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner/>;
  }

  const columns = [
    {
      title: capitalize(t('bookings')),
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const trainer = trainers.find(trainer => trainer._id === record.trainer);
        return (
          <React.Fragment>
            {moment(record.start).format('DD/MM/YYYY')}<br/>
            {moment(record.start).format('HH:mm')} - {moment(record.end).format('HH:mm')}<br/>
            {record.name}
            {trainer ? <><br/>{trainer.name} {trainer.surname}</> : ''}
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      key: 'date',
      align: 'center',
      render: (text, record) => (
        <>
          {moment(record.start).format('DD/MM/YYYY')}<br/>
          {moment(record.start).format('HH:mm')} - {moment(record.end).format('HH:mm')}
        </>
      ),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('name')),
      align: 'center',
      key: 'name',
      render: (text, record) => record.name,
      responsive: ['sm']
    },
    {
      title: capitalize(t('trainer')),
      align: 'center',
      key: 'trainer',
      render: (text, record) => {
        const trainer = trainers.find(trainer => trainer._id === record.trainer);
        return trainer ? trainer.name + ' ' + trainer.surname : '';
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  return (
    <>
      <h3>{capitalize(t('individual'))}</h3>
      <Table columns={columns} dataSource={bookings} onChange={onChange}/>
      <h3>{capitalize(t('group'))}</h3>
      <Table columns={columns} dataSource={groupBookings} onChange={onChange}/>
    </>

  );
};

export default UserBookings;
