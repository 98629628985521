import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, message, Popconfirm, Radio, Select } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { createExercise, getExerciseById, patchExercise } from '../../../services/exercise.service';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateExercise = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [exercise, setExercise] = useState(null);
  const [exerciseType, setExerciseType] = useState('hist');
  const { firebaseUser } = useContext(Auth);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const { TextArea } = Input;
  const { t } = useTranslation();

  const exerciseTypes = [
    {
      label: t('hist').toUpperCase(),
      value: 'hist'
    },
    {
      label: capitalize(t('isometric')),
      value: 'isometric'
    }
  ];

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      if (params.id) {
        await loadExercise(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      setCenter(data.data);
      if (params.id) {
        await loadExercise(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadExercise = async (id, token) => {
    const { status, data } = await getExerciseById(id, token);
    if (status === 200) {
      setExercise(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const fillForm = (exercise) => {
    setExerciseType(exercise.type);
    if (exercise.type === 'hist') {
      form.setFieldsValue({
        name: exercise.name ? exercise.name : '',
        eccentric: exercise.eccentric ? exercise.eccentric : '',
        concentric: exercise.concentric ? exercise.concentric : '',
        squeeze: exercise.squeeze ? exercise.squeeze : '',
        settings: exercise.settings ? exercise.settings : '',
        weightMeasure: exercise.weightMeasure ? exercise.weightMeasure : ''
      });
    } else if (exercise.type === 'isometric') {
      form.setFieldsValue({
        name: exercise.name ? exercise.name : '',
        settings: exercise.settings ? exercise.settings : '',
        sets: exercise.sets ? exercise.sets : ''
      });
    } else if (exercise.type === 'strength') {
      form.setFieldsValue({
        name: exercise.name ? exercise.name : '',
        settings: exercise.settings ? exercise.settings : ''
      });
    } else {
      form.setFieldsValue({
        name: exercise.name ? exercise.name : '',
        eccentric: exercise.eccentric ? exercise.eccentric : '',
        concentric: exercise.concentric ? exercise.concentric : '',
        squeeze: exercise.squeeze ? exercise.squeeze : '',
        settings: exercise.settings ? exercise.settings : '',
        weightMeasure: exercise.weightMeasure ? exercise.weightMeasure : ''
      });
    }
  };

  const deactivateExercise = async () => {
    if (exercise) {
      const { status } = await patchExercise(exercise._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/exercises');
        message.success(capitalize(t('exerciseDeleted')));
      }
    }
  };

  const createNewExercise = async (data, token) => {
    let formData = {};
    if (exerciseType === 'hist') {
      formData = {
        name: data.name,
        eccentric: data.eccentric,
        concentric: data.concentric,
        squeeze: data.squeeze,
        center: center._id,
        type: exerciseType,
        settings: data.settings,
        weightMeasure: data.weightMeasure
      };
    } else if (exerciseType === 'isometric') {
      formData = {
        name: data.name,
        center: center._id,
        settings: data.settings,
        type: exerciseType,
        sets: data.sets
      };
    } else if (exerciseType === 'strength') {
      formData = {
        name: data.name,
        center: center._id,
        settings: data.settings,
        type: exerciseType
      };
    } else {
      formData = {
        name: data.name,
        center: center._id,
        settings: data.settings,
        type: exerciseType
      };
    }
    if (params.id) await patchExercise(params.id, formData, token);
    else await createExercise(formData, token);
    navigate('/dashboard/exercises');
  };

  const correctClave = async e => {
    await createNewExercise(e, user.token);
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {exercise ? <h1>{capitalize(t('editExercise'))}</h1> : <h1>{capitalize(t('createExercise'))}</h1>}
        </Form.Item>

        <Radio.Group
          options={exerciseTypes}
          onChange={(e) => setExerciseType(e.target.value)}
          value={exerciseType}
          optionType="button"
          buttonStyle="solid"
          style={{ marginBottom: 20 }}
        />

        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={exercise ? exercise.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        {exerciseType === 'hist' && (
          <>
            <Form.Item
              name="concentric"
              rules={[{
                required: true,
                message: capitalize(t('concentric')) + ' ' + t('isRequired')
              }]}>
              <Input
                addonBefore={capitalize(t('concentric'))}
                name="concentric"
                defaultValue={exercise ? exercise.concentric : ''}
                placeholder={capitalize(t('concentric'))}
              />
            </Form.Item>
            <Form.Item
              name="eccentric"
              rules={[{
                required: true,
                message: capitalize(t('eccentric')) + ' ' + t('isRequired')
              }]}>
              <Input
                addonBefore={capitalize(t('eccentric'))}
                name="eccentric"
                defaultValue={exercise ? exercise.eccentric : ''}
                placeholder={capitalize(t('eccentric'))}
              />
            </Form.Item>
            <Form.Item
              name="squeeze"
              rules={[{
                required: true,
                message: capitalize(t('squeeze')) + ' ' + t('isRequired')
              }]}>
              <Input
                addonBefore={capitalize(t('squeeze'))}
                name="squeeze"
                defaultValue={exercise ? exercise.squeeze : ''}
                placeholder={capitalize(t('squeeze'))}
              />
            </Form.Item>
            <div style={{ textAlign: 'left' }}>
              {capitalize(t('weightMeasure'))}:
            </div>
            <Form.Item
              name="weightMeasure"
              rules={[
                {
                  required: true,
                  message: capitalize(t('weightMeasure')) + ' ' + t('isRequired')
                }
              ]}>
              <Select defaultValue={exercise && exercise.weightMeasure ? exercise.weightMeasure : 'p.'} >
                <Option value="p.">p.</Option>
                <Option value="Kg">Kg</Option>
              </Select>
            </Form.Item>
          </>)}
        {exerciseType === 'isometric' && (
          <>
            <Form.Item
              name="sets"
              rules={[{
                required: true,
                message: capitalize(t('sets')) + ' ' + t('isRequired')
              }]}>
              <Input
                addonBefore={capitalize(t('sets'))}
                name="sets"
                defaultValue={exercise ? exercise.sets : ''}
                placeholder={capitalize(t('sets'))}
              />
            </Form.Item>
          </>)}

        <div style={{ textAlign: 'left' }}>
          {capitalize(t('settings'))}:
        </div>
        <Form.Item
          name="settings"
          rules={[{
            required: true,
            message: capitalize(t('settings')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            addonBefore={capitalize(t('settings'))}
            rows={4}
            name="settings"
            defaultValue={exercise ? exercise.settings : ''}
            placeholder={capitalize(t('settings'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {exercise && (
            <Popconfirm
              title={capitalize(t('confirmDeleteExercise'))}
              onConfirm={deactivateExercise}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {exercise ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createExercise'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateExercise;
