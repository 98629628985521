import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table, Collapse } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getSettingByQuery } from '../../../services/setting.service';
import TagExercise from '../../../components/tags/TagExercise';
import { getAccountByQuery } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { getProgramSessionByQuery, getSessionPdf, getProgramSessionForSessions } from '../../../services/programsession.service';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import moment from 'moment';
import 'moment/locale/es';

const Sessions = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [programSessions, setProgramSessions] = useState([]);
  const [programSessionsByMonth, setProgramSessionsByMonth] = useState([]);
  const { t } = useTranslation();
  const { Panel } = Collapse;

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      await loadProgramSessions(data.data[0]._id);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      await loadProgramSessions(data.data._id);
    }
  };

  const loadProgramSessions = async (centerId) => {
    const { status, data } = await getProgramSessionForSessions({ center: centerId }, user.token);
    if (status === 200) {
      separateProgramSessionsByMonth(data.data.reverse());
      await loadAccounts(centerId);
      await setProgramSessions(data.data);
      setLoading(false);
    }
  };

  const loadAccounts = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId }, user.token);
    if (status === 200) {
      setAccounts(data.data);
    }
  };

  const separateProgramSessionsByMonth = (programSessions) => {
    const programSessionsByMonth = [];
    programSessions.forEach((programSession) => {
      const date = moment(programSession.timestamp.updatedAt).format('MMMM YYYY');
      if (!programSessionsByMonth[date]) {
        programSessionsByMonth[date] = [];
      }
      programSessionsByMonth[date].push(programSession);
    });
    setProgramSessionsByMonth(programSessionsByMonth);
  };

  const columns = [
    {
      title: capitalize(t('sessions')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const accountSession = accounts.find(account => account._id === record.account);
        return (
          <React.Fragment>
            {moment(record.timestamp.updatedAt).format('DD/MM/YYYY HH:mm')}<br/>
            {accountSession.name} {accountSession.surname}<br/>
            {accountSession.dni}<br/>
            {record.closed
              ? (
                <Button type="link" onClick={() => {}}>{t('lastSessions')}</Button>
              )
              : (
                <>
                  {capitalizeAll(t('activeSession'))}
                </>
              )}
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => new Date(b.timestamp.updatedAt) - new Date(a.timestamp.updatedAt),
        multiple: 1
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      key: 'date',
      render: (record) => (
        <React.Fragment>
          {moment(record.timestamp.updatedAt).format('DD/MM/YYYY HH:mm')}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => new Date(b.timestamp.updatedAt) - new Date(a.timestamp.updatedAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('user')),
      key: 'user',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const accountSession = accounts.find(account => account._id === record.account);
        return (
          <React.Fragment>
            {accountSession.name} {accountSession.surname}<br/>
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => accounts.find(account => account._id === a.account).name.localeCompare(accounts.find(account => account._id === b.account).name)
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('nif')),
      key: 'dni',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const accountSession = accounts.find(account => account._id === record.account);
        return (
          <React.Fragment>
            {accountSession.nif}<br/>
          </React.Fragment>
        );
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('actions')),
      key: 'actions',
      render: (record) => (
        <React.Fragment>
          {record.closed
            ? (
              <a style={{ color: '#222', padding: 8, border: '1px solid #999', borderRadius: 3 }} href={process.env.REACT_APP_API_URL + 'programsession/downloadpdf?id=' + record._id} target="_blank" rel="noopener noreferrer">{capitalize(t('lastSessions'))}</a>
            )
            : (
              <>
                {capitalizeAll(t('activeSession'))}
              </>
            )}

          {/* <Button type="ghost" onClick={() => {
            getSessionPdf(record._id);
          }}>{capitalize(t('lastSessions'))}</Button> */}
        </React.Fragment>
      ),
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  const renderMonthPanel = (month) => {
    return (
      <Panel header={<b>{capitalize(month)}</b>} key={month} extra={programSessionsByMonth[month].length + ' ' + capitalize(t('sessions'))}>
        <Table columns={columns} dataSource={programSessionsByMonth[month]} />
      </Panel>
    );
  };

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b> {capitalize(t('sessions'))}</b>
        </div>
        <Collapse
          // defaultActiveKey={['1']}
          expandIconPosition={'left'}
          style={{ marginTop: '10px' }}
        >
          {programSessionsByMonth && Object.keys(programSessionsByMonth).map(renderMonthPanel)}
        </Collapse>
        {/* <Table columns={columns} dataSource={programSessions} onChange={onChange} onRow={(record, rowIndex) => { */}
        {/*  return { */}
        {/*    onClick: event => {}, // click row */}
        {/*    onDoubleClick: event => {}, // double click row */}
        {/*    onContextMenu: event => {}, // right button click row */}
        {/*    onMouseEnter: event => {}, // mouse enter row */}
        {/*    onMouseLeave: event => {} // mouse leave row */}
        {/*  }; */}
        {/* }} rowClassName={'row-pointer'} */}
        {/* /> */}
        <br />
      </>
    );
  };
};

export default Sessions;
