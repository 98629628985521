import React, { useState } from 'react';

const CheckButton = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  let bgColor, color, shadow;
  if (isChecked) {
    bgColor = 'gray';
    color = 'white';
    shadow = 'inset 0px 0px 10px 4px rgba(0,0,0,0.36)';
  } else {
    bgColor = '#cf1d11';
    color = 'white';
    shadow = 'none';
  }

  return (
    <>
      <div style={{ height: '60px', width: props.width, backgroundColor: bgColor, color: color, borderRadius: 4, margin: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 16, boxShadow: shadow }} onClick={() => {
        setIsChecked(!isChecked);
        props.handleResumeButtons(props.id, !isChecked);
      }}>
        {props.text}
      </div>
    </>
  );
};

export default CheckButton;
