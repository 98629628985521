import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getCenterByQuery } from '../../../services/center.service';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import { getIdToken } from 'firebase/auth';
import { getProgramSessionCount } from '../../../services/programsession.service';
import { getAccountByQuery } from '../../../services/user.service';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import * as xlsx from 'xlsx';

const Centers = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [centers, setCenters] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    loadCenters();
  }, []);

  const loadCenters = async () => {
    const { status, data } = await getCenterByQuery({ isActive: true }, user.token);
    if (status === 200) {
      setCenters(data.data);
      for await (const center of data.data) {
        center.programSessionsCount = await loadProgramSessionsCount(center._id);
      }
      setLoading(false);
    }
  };

  const loadProgramSessionsCount = async (centerId) => {
    const { status, data } = await getProgramSessionCount({ center: centerId }, user.token);
    if (status === 200) {
      return data.data;
    }
  };

  const downloadXls = async (record) => {
    const users = await getAccountByQuery({ center: record._id }, user.token);
    const usersData = users.data.data;
    const usersDataMapped = usersData.map((user) => {
      return {
        Nombre: user.name,
        Apellido: user.surname,
        Email: user.email,
        Tlf: user.phone,
        DNI: user.nif
      };
    });
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.json_to_sheet(usersDataMapped);
    xlsx.utils.book_append_sheet(wb, ws, 'Clientes');
    xlsx.writeFile(wb, record.name + '-clientes' + '.xlsx');
  };

  const columns = [
    {
      title: capitalize(t('centers')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
          <br />
          {record.email}
          <br />
          {record.phone}
          <br />
          {record.programSessionsCount ? record.programSessionsCount : 0} {capitalize(t('sessions'))}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('email')),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('phone')),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a.phone.localeCompare(b.phone),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('sessions')),
      dataIndex: 'programSessionsCount',
      key: 'programSessionsCount',
      sorter: {
        compare: (a, b) => a.programSessionsCount - b.programSessionsCount,
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('xls')),
      // dataIndex: 'plan',
      key: 'xls',
      render: (record) => {
        return (
          <Button type="primary" icon={<DownloadOutlined />} size='small' onClick={() => downloadXls(record)}>
            {capitalize(t('download'))}
          </Button>
        );
      },
      sorter: {
        compare: (a, b) => a.nif.localeCompare(b.nif),
        multiple: 1
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  // const renderCardCenter = (center, key) => {
  //   return (
  //     <Col key={key}>
  //       <Card hoverable style={{ width: 300, marginBottom: 12 }} onClick={() => {
  //         setLoading(true);
  //         navigate('/dashboard/centers/' + center._id);
  //       }}>
  //         <p><b>{center.name}</b></p>
  //         <span>{center.email}</span><br />
  //         <span>{center.phone}</span>
  //
  //       </Card>
  //     </Col>
  //   );
  // };

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewCenter'))}
        </Button>
        <Table columns={columns} dataSource={centers} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (event.target.tagName === 'TD') {
                navigate('edit/' + record._id);
              }
            },
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
        <br />
      </>
    );
  };
};

export default Centers;
