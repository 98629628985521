import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getAllTransactions = (token) => {
  return methodGet('transaction/all', '', token);
};

export const getTransactionById = (id, token) => {
  return methodGet('transaction/' + id, '', token);
};

export const getTransactionByQuery = (req, token) => {
  return methodGet('transaction', req, token);
};

export const createTransaction = (data, token) => {
  return methodPost('transaction/create', data, token);
};

export const patchTransaction = (id, data, token) => {
  return methodPatch('transaction/update?id=' + id, data, token);
};

export const deleteTransaction = (id, token) => {
  return methodDelete('transaction/delete?id=' + id, token);
};

export const confirmTransaction = (id, token) => {
  return methodPost('transaction/confirm/', { id: id }, token);
};
