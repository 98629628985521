import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Button } from 'antd';
import { capitalize } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import log from 'eslint-plugin-react/lib/util/log';

export default function CheckoutForm () {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
      case 'succeeded':
        setMessage('Payment succeeded!');
        console.log('Payment succeeded!');
        console.log('Intent', paymentIntent);
        break;
      case 'processing':
        setMessage('Your payment is processing.');
        console.log('Your payment is processing.');
        console.log('Intent', paymentIntent);
        break;
      case 'requires_payment_method':
        setMessage('Your payment was not successful, please try again.');
        console.log('Your payment was not successful, please try again.');
        console.log('Intent', paymentIntent);
        break;
      default:
        setMessage('Something went wrong.');
        console.log('Something went wrong.');
        console.log('Intent', paymentIntent);
        break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'http://localhost:3000/user/profile/success'
      }
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15, width: '100%' }}>
        <button disabled={isLoading || !stripe || !elements} id="submit" >
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner">...</div> : <>{capitalize(t('buy'))}</>}
          </span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
