import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllAppversions = (token) => {
  return methodGet('appversion/all', '', token);
};

export const getAppversionById = (id, token) => {
  return methodGet('appversion/' + id, '', token);
};

export const getAppversionByQuery = (req, token) => {
  return methodGet('appversion', req, token);
};

export const createAppversion = (data, token) => {
  return methodPost('appversion/create', data, token);
};

export const patchAppversion = (id, data, token) => {
  return methodPatch('appversion/update?id=' + id, data, token);
};
