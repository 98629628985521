import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getAllCalendartemplates = (token) => {
  return methodGet('calendartemplate/all', '', token);
};

export const getCalendartemplateById = (id, token) => {
  return methodGet('calendartemplate/' + id, '', token);
};

export const getCalendartemplateByQuery = (req, token) => {
  return methodGet('calendartemplate', req, token);
};

export const createCalendartemplate = (data, token) => {
  return methodPost('calendartemplate/create', data, token);
};

export const patchCalendartemplate = (id, data, token) => {
  return methodPatch('calendartemplate/update?id=' + id, data, token);
};

export const deleteCalendartemplate = (id, token) => {
  return methodDelete('calendartemplate/delete?id=' + id, token);
};
