import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getAllBooksessions = (token) => {
  return methodGet('booksession/all', '', token);
};

export const getBooksessionById = (id, token) => {
  return methodGet('booksession/' + id, '', token);
};

export const getBooksessionByQuery = (req, token) => {
  return methodGet('booksession', req, token);
};

export const createBooksession = (data, token) => {
  return methodPost('booksession/create', data, token);
};

export const patchBooksession = (id, data, token) => {
  return methodPatch('booksession/update?id=' + id, data, token);
};

export const deleteBooksession = (id, token) => {
  return methodDelete('booksession/delete?id=' + id, token);
};
