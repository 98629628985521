import React, { useState, useContext, useEffect } from 'react';
import { Layout, Form, Input, Button, Image } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { app } from '../../services/firebase/firebaseConfig';
import { Context as Auth } from '../../services/auth/AuthContext';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
  const { Content, Footer } = Layout;
  const { user, signin } = useContext(Auth);
  const [error, seterror] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (user.token) {
      navigate('/redirect');
    }
  }, [history, user]);

  const correctClave = async e => {
    const { usuario, clave } = e;
    const res = await signin({ email: usuario.toLowerCase(), password: clave });
    if (res !== 'Usuario o contraseña incorrectos') {
      seterror('');
    } else {
      seterror('Correo o contraseña incorrectos');
    }

    // const auth = getAuth(app);
    // await signInWithEmailAndPassword(auth, usuario, clave)
    //   .then(result => {
    //     // history.push('/dashboard');
    //   })
    //   .catch(error => {
    //     seterror(error.message);
    //   });
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Content
        className={'content-login'}
      >
        {/* <Image src={'https://sei-platform-test.s3.eu-west-3.amazonaws.com/assets/sei-logo-text.png'} alt="logo" style={{ height: '200px', width: '408px', padding: 10 }} preview={false} /> */}
        {/* <h1>Ingreso</h1> */}
        <div
          className={'content-login-form'}
        >
          <Form className="login-form" onFinish={correctClave}>
            <Form.Item
              style={{ marginBottom: '0px' }}
              name="usuario">
              <Input
                className={'input-login'}
                prefix={
                  <UserOutlined />
                }
                name="usuario"
                placeholder="Correo electrónico"
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: '0px' }} name="clave">
              <Input
                className={'input-login'}
                prefix={
                  <LockOutlined />
                }
                name="clave"
                type="password"
                placeholder="Clave"
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: '0px' }}>
              <Button
                className={'submit-login'}
                type="primary"
                shape="round"
                htmlType="submit"
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                 Entrar
              </Button>
            </Form.Item>
          </Form>
        </div>

      </Content>

      {error ? <Form.Item>{error}</Form.Item> : null}
      <Footer style={{ textAlign: 'center' }}>
      </Footer>
    </Layout>
  );
};
export default Login;
