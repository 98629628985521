import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getIdToken } from 'firebase/auth';
import { confirmTransaction, getTransactionByQuery } from '../../../services/transaction.service';
import Spinner from '../../../components/navigation/Spinner';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const SuccessPay = (props) => {
  const { user } = useContext(Auth);
  const [transaction, setTransaction] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    loadTransaction();
  }, []);

  const loadTransaction = async () => {
    const paymentIntent = searchParams.get('payment_intent');
    const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');

    if (!paymentIntent || !paymentIntentClientSecret) {
      setMessage(capitalize(t('somethingWrong')));
      setPaymentSuccess(false);
      setLoading(false);
      return;
    }

    const { status, data } = await getTransactionByQuery({ id: paymentIntent, secret: paymentIntentClientSecret }, user.token);
    if (status === 200) {
      setTransaction(data.data[0]);
      await confirmNewTransaction(data.data[0]._id);
    }
  };

  const confirmNewTransaction = async (transactionId) => {
    const { status, data } = await confirmTransaction(transactionId, user.token);
    if (status === 200) {
      setMessage(capitalize(t('paymentSuccess')));
      setPaymentSuccess(true);
      setLoading(false);
    } else {
      setMessage(capitalize(t('somethingWrong')));
      setPaymentSuccess(false);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Spinner />
    );
  }

  if (!paymentSuccess) {
    return (
      <div className='paymentConfirm'>
        <div className='paymentConfirmMessage'>
          <h1 style={{ color: 'red', marginBottom: 0 }}>{message}</h1>
          <span style={{ color: 'red', fontSize: '2.5rem' }}>
            <CloseCircleOutlined />
          </span>
          <p style={{ marginTop: 15 }}>
            {t('paymentNotSuccess')}
          </p>
          <Button type='primary' onClick={() => navigate('/user/profile')}>
            {capitalize(t('back'))}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className='paymentConfirm'>
      <div className='paymentConfirmMessage'>
        <h1 style={{ color: 'green', marginBottom: 0 }}>{message}</h1>
        <span style={{ color: 'green', fontSize: '2.5rem' }}>
          <CheckCircleOutlined />
        </span>
        <p style={{ marginTop: 15 }}>
          {t('paymentSuccessMessage')}
        </p>
        <div className='paymentSuccessTableDiv'>
          <table className='paymentSuccessTable'>
            <tr>
              <td className='paymentSuccessTableLeft'>{capitalize(t('credits'))}</td>
              <td className='paymentSuccessTableRight'>{transaction.credits}ud.</td>
            </tr>
            <tr>
              <td className='paymentSuccessTableLeft'>{capitalize(t('actualCredits'))}</td>
              <td className='paymentSuccessTableRight'>{user.account.credits + transaction.credits}ud.</td>
            </tr>
            <tr>
              <td className='paymentSuccessTableLeft'>{capitalize(t('email'))}</td>
              <td className='paymentSuccessTableRight'>{user.account.email}</td>
            </tr>
            <tr>
              <td className='paymentSuccessTableLeft'>{capitalize(t('total'))}</td>
              <td className='paymentSuccessTableRight'>{parseFloat(transaction.totalPrice / 100).toFixed(2)}€</td>
            </tr>
          </table>
        </div>
        <Button type='primary' onClick={() => navigate('/user/profile')}>
          {capitalize(t('back'))}
        </Button>
      </div>
    </div>
  );
};

export default SuccessPay;
