import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table, Collapse } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getSettingByQuery } from '../../../services/setting.service';
import TagExercise from '../../../components/tags/TagExercise';
import { getAccountByQuery } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { getProgramSessionByQuery, getSessionPdf, getProgramSessionForSessions } from '../../../services/programsession.service';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { getCenterByQuery, getCenterById, getAllCenters } from '../../../services/center.service';
import moment from 'moment';
import 'moment/locale/es';

const CenterSessions = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [centers, setCenters] = useState([]);
  const { t } = useTranslation();
  const { Panel } = Collapse;

  useEffect(() => {
    if (user.account) {
      loadCenters();
    }
  }, [user.account]);

  const loadCenters = async () => {
    const { status, data } = await getAllCenters(user.token);
    if (status === 200) {
      setCenters(data.data);
      for await (const center of data.data) {
        await loadProgramSessions(center._id, data.data);
      }
      setLoading(false);
    }
  };

  const loadProgramSessions = async (centerId, centers) => {
    const { status, data } = await getProgramSessionForSessions({ center: centerId }, user.token);
    if (status === 200) {
      separateProgramSessionsByMonth(data.data.reverse(), centerId, centers);
    }
  };

  const separateProgramSessionsByMonth = (programSessions, center, centers) => {
    const programSessionsByMonth = {};
    programSessions.forEach((programSession) => {
      const date = moment(programSession.timestamp.updatedAt).format('MMMM YYYY');
      if (!programSessionsByMonth[date]) {
        programSessionsByMonth[date] = 1;
      }
      programSessionsByMonth[date] = programSessionsByMonth[date] + 1;
    });
    // add to centers
    const centerIndex = centers.findIndex(centerItem => centerItem._id === center);
    const newCenters = [...centers];
    newCenters[centerIndex].programSessionsByMonth = programSessionsByMonth;
    setCenters(newCenters);
  };

  const columns = [
    {
      title: capitalize(t('sessions')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <React.Fragment>
            {record[0]}
            <br />
            {record[1]} {capitalize(t('sessions'))}
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => new Date(b.timestamp.updatedAt) - new Date(a.timestamp.updatedAt),
        multiple: 1
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('month')),
      key: 'month',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <React.Fragment>
            {record[0]}
          </React.Fragment>
        );
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('sessions')),
      key: 'dni',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <React.Fragment>
            {record[1]}
          </React.Fragment>
        );
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  const renderMonthPanel = (center) => {
    return (
      <Panel header={<b>{center.name}</b>} key={center._id}>
        <Table columns={columns} dataSource={Object.keys(center.programSessionsByMonth).map((key) => [String(key), center.programSessionsByMonth[key]])} />
      </Panel>
    );
    // return (
    //   <Panel header={<b>{capitalize(month)}</b>} key={month} extra={programSessionsByMonth[month].length + ' ' + capitalize(t('sessions'))}>
    //     <Table columns={columns} dataSource={programSessionsByMonth[month]} />
    //   </Panel>
    // );
  };

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b> {capitalize(t('sessions'))}</b>
        </div>
        <Collapse
          // defaultActiveKey={['1']}
          expandIconPosition={'left'}
          style={{ marginTop: '10px' }}
        >
          {centers && centers.map(renderMonthPanel)}
        </Collapse>
        {/* <Table columns={columns} dataSource={programSessions} onChange={onChange} onRow={(record, rowIndex) => { */}
        {/*  return { */}
        {/*    onClick: event => {}, // click row */}
        {/*    onDoubleClick: event => {}, // double click row */}
        {/*    onContextMenu: event => {}, // right button click row */}
        {/*    onMouseEnter: event => {}, // mouse enter row */}
        {/*    onMouseLeave: event => {} // mouse leave row */}
        {/*  }; */}
        {/* }} rowClassName={'row-pointer'} */}
        {/* /> */}
        <br />
      </>
    );
  };
};

export default CenterSessions;
