import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getCenterByQuery } from '../../../services/center.service';
import { getIdToken } from 'firebase/auth';
import { getBookingPopulated } from '../../../services/booking.service';
import Spinner from '../../../components/navigation/Spinner';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Col, Row } from 'antd';
import BookingHourButton from '../../../components/buttons/BookingHour';
import BookingUserCreate from '../../../components/modals/BookingUserCreate';
import { getAccountById } from '../../../services/user.service';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import moment from 'moment';
import 'moment/locale/es';

const UserBooking = (props) => {
  const { user } = useContext(Auth);
  // eslint-disable-next-line no-unused-vars
  const [center, setCenter] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [dayBookings, setDayBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookingDataVisible, setBookingDataVisible] = useState(false);
  const [value, onChange] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
      const interval = setInterval(() => {
        loadCenter();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [user.account]);

  const loadAccount = async () => {
    const { status, data } = await getAccountById(user.account._id, user.token);
    if (status === 200) {
      setAccount(data.data);
    }
  };

  useEffect(() => {
    if (center) {
      setLoading(false);
    }
  }, [center]);

  useEffect(() => {
    handleGetDayBookings();
  }, [value, bookings]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ _id: user.account.center }, user.token);
    if (status === 200) {
      await loadAccount();
      await loadBooks(data.data[0]._id, data.data[0].bookingSettings.maxDaysCalendar);
      setCenter(data.data[0]);
    }
  };

  const loadBooks = async (centerId, maxDate) => {
    const { status, data } = await getBookingPopulated({ center: centerId, start: moment().toDate(), end: moment().add(maxDate, 'days').toDate() }, user.token);
    if (status === 200) {
      setBookings(data.data);
    }
  };

  const handleGetDayBookings = () => {
    const dayBooks = bookings.filter(booking => {
      const date = new Date(booking.start);
      const dateValue = new Date(new Date(value.getTime()).setHours(0, 0, 0, 0));
      const date24 = new Date(dateValue.getTime());
      return date.getTime() < new Date(date24.setHours(date24.getHours() + 24)).getTime() && date.getTime() > dateValue.getTime();
    });
    setDayBookings(dayBooks);
  };

  const handleUserCreateBooking = (booking) => {
    setLoading(true);
    setBookingDataVisible(false);
    loadCenter();
  };

  const renderBookings = (booking) => {
    let colour = 'lightgreen';
    let disabled = false;
    let text = capitalize(t('free'));
    if (booking.isGroup) {
      if (booking.group?.length > booking.slots - 1) {
        colour = 'orange';
        disabled = false;
        text = capitalize(t('occupied'));
      }
      if (booking.queue.length > 4) {
        colour = 'red';
        disabled = true;
        text = capitalize(t('full'));
      }
      if (booking.queue.filter((b) => b === account._id).length > 0) {
        colour = 'beige';
        disabled = false;
        text = capitalize(t('inQueue'));
      }
      if (booking.group?.includes(account._id)) {
        colour = 'lightblue';
        disabled = false;
        text = capitalize(t('booked'));
      }
    } else {
      if (booking.account && booking.account !== 'undefined') {
        colour = 'orange';
        disabled = false;
        text = capitalize(t('occupied'));
      }
      if (booking.queue.length > 4) {
        colour = 'red';
        disabled = true;
        text = capitalize(t('full'));
      }
      if (booking.queue.filter((b) => b === account._id).length > 0) {
        colour = 'beige';
        disabled = false;
        text = capitalize(t('inQueue'));
      }
      if (booking.account && booking.account._id === account._id) {
        colour = 'lightblue';
        disabled = false;
        text = capitalize(t('booked'));
      }
    }

    //
    //
    // const trainer = trainers.find(trainer => trainer._id === booking.trainer);
    return (
      <div style={{ margin: 5, width: 280 }}>
        <BookingHourButton
          booking={booking}
          startHour={booking.start}
          endHour={booking.end}
          text = {text}
          colour={colour}
          disabled={disabled}
          setSelectedBooking={setSelectedBooking}
          setBookingDataVisible={setBookingDataVisible}
        />
      </div>
    );
  };

  if (loading) {
    return <Spinner/>;
  }

  return (
    <div>
      <Row justify="center" align="middle" style={{ flexWrap: 'wrap' }}>
        <Col xs={24} lg={7} style={{ minWidth: 250, display: 'flex', justifyContent: 'center' }}>
          <Calendar onChange={onChange} value={value} locale='es-ES' minDate={moment().toDate()} maxDate={moment().add(center.bookingSettings.maxDaysCalendar, 'days').toDate()} />
        </Col>
        <Col xs={24} lg={16} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          {dayBookings.length > 0 && dayBookings.map(booking => renderBookings(booking))}
        </Col>
      </Row>
      <Row justify="center" align="middle" style={{ flexWrap: 'wrap', marginTop: 30 }}>
        <Col xs={24} lg={24} style={{ minWidth: 250, display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', marginLeft: 10, marginRight: 10, alignItems: 'center' }}><div style={{ height: 20, width: 20, border: '1px solid black', background: 'lightgreen', marginRight: 3 }}></div> {capitalize(t('free'))}</div>
          <div style={{ display: 'flex', marginLeft: 10, marginRight: 10, alignItems: 'center' }}><div style={{ height: 20, width: 20, border: '1px solid black', background: 'lightblue', marginRight: 3 }}></div> {capitalize(t('booked'))}</div>
          <div style={{ display: 'flex', marginLeft: 10, marginRight: 10, alignItems: 'center' }}><div style={{ height: 20, width: 20, border: '1px solid black', background: 'orange', marginRight: 3 }}></div> {capitalize(t('occupied'))}</div>
          <div style={{ display: 'flex', marginLeft: 10, marginRight: 10, alignItems: 'center' }}><div style={{ height: 20, width: 20, border: '1px solid black', background: 'beige', marginRight: 3 }}></div> {capitalize(t('inQueue'))}</div>
          <div style={{ display: 'flex', marginLeft: 10, marginRight: 10, alignItems: 'center' }}><div style={{ height: 20, width: 20, border: '1px solid black', background: 'red', marginRight: 3 }}></div> {capitalize(t('full'))}</div>
        </Col>
      </Row>
      {selectedBooking && <BookingUserCreate bookingDataVisible={bookingDataVisible} handleUserCreateBooking={handleUserCreateBooking} booking={selectedBooking} center={center} user={user} account={account} />}
    </div>
  );
};

export default UserBooking;
