import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, message, Popconfirm, Upload } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/navigation/Spinner';
import { getIdToken } from 'firebase/auth';
import { createHistory, getHistoryById, patchHistory } from '../../../services/history.service';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { uploadFile } from '../../../services/file.service';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const ExternalCreateHistory = (props) => {
  const params = useParams();
  const [history, setHistory] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    // if (user.account) {
    if (params.id) {
      loadHistory(params.id);
    } else {
      setLoading(false);
    }
    // }
  }, []);

  const loadHistory = async (id) => {
    const { status, data } = await getHistoryById(id);
    if (status === 200) {
      setHistory(data.data);
      fillForm(data.data);
      if (data.data.attachments) {
        const attachments = [];
        data.data.attachments.forEach((attachment) => {
          attachments.push({
            uid: attachment,
            name: attachment.split('-_-_-').pop(),
            status: 'uploaded',
            url: attachment
          });
        });
        setFileList(attachments);
      }
      setLoading(false);
    }
  };

  const fillForm = (history) => {
    form.setFieldsValue({
      name: history.name,
      description: history.description
    });
  };

  const deactivateHistory = async () => {
    if (history) {
      let account;
      if (params.id) account = history.account;
      else account = params.account;
      const { status } = await patchHistory(history._id, { isActive: false });
      if (status === 200) {
        navigate('/ext/histories/' + params.nif);
        message.success(capitalize(t('historyDeleted')));
      }
    }
  };

  const upload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const res = await uploadFile(formData);

    return res.data.data.Location;
  };

  const createNewHistory = async (data, token) => {
    const attachments = [];
    for await (const file of fileList) {
      if (file.status && file.status === 'uploaded') {
        attachments.push(file.url);
      } else {
        attachments.push(await upload(file));
      }
    }

    let account;
    if (params.id) account = history.account;
    else account = params.account;
    const formData = {
      name: data.name,
      description: data.description,
      account: account,
      attachments: attachments
    };
    if (params.id) await patchHistory(params.id, formData, token);
    else await createHistory(formData, token);
    navigate('/ext/histories/' + params.nif);
  };

  const correctClave = async e => {
    await createNewHistory(e);
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {history ? <h1>{capitalizeAll(t('editHistory'))}</h1> : <h1>{capitalizeAll(t('createHistory'))}</h1>}
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={history ? history.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>{capitalize(t('description'))}: </div>
        <Form.Item
          name="description"
          rules={[{
            required: true,
            message: capitalize(t('description')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            addonBefore={capitalize(t('description'))}
            allowClear={true}
            name="description"
            defaultValue={history ? history.description : ''}
            placeholder={capitalize(t('description'))}
          />
        </Form.Item>
        <Form.Item>
          <Upload
            onRemove={file => {
              const newFileList = fileList.filter(f => f.name !== file.name);
              setFileList(newFileList);
            }}
            beforeUpload={file => {
              setFileList([...fileList, file]);
              return false;
            }}
            listType="picture"
            accept="image/*,.pdf"
            fileList={fileList}
          >
            <Button icon={<UploadOutlined />}>{capitalize(t('upload'))}</Button>
          </Upload>
        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item>
            <Button
              type="warning"
              className="login-form-button"
              style={{ marginRight: 10 }}
              onClick={() => {
                navigate('/ext/histories/' + params.nif);
              }}
            >
              {capitalizeAll(t('back'))}
            </Button>
          </Form.Item>
          {history && (
            <Popconfirm
              title={capitalize(t('confirmDeleteHistory'))}
              onConfirm={deactivateHistory}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {history ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createHistory'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default ExternalCreateHistory;
