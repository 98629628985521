import React, { useState, useEffect } from 'react';
import { notification, Button } from 'antd';
import { getAllAppversions, getAppversionByQuery, patchAppversion } from './services/appversion.service';
import * as serviceWorker from './serviceWorker';
import log from 'eslint-plugin-react/lib/util/log';

const ServiceWorkerWrapper = () => {
  const [actualVersion, setActualVersion] = useState(null);
  // const [lastVersion, setLastVersion] = useState(null);
  const [showReload, setShowReload] = useState(false);
  // const [waitingWorker, setWaitingWorker] = useState(null);

  // const onSWUpdate = (registration) => {
  //   setShowReload(true);
  //   setWaitingWorker(registration.waiting);
  // };

  useEffect(() => {
    console.log('load actual version');
    loadActualAppVersion();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkLastVersion();
      console.log('checking for update', process.env.NODE_ENV);
      // serviceWorker.register({ onUpdate: onSWUpdate });
    }, 43200000);

    return () => {
      clearInterval(interval);
    };
  }, [actualVersion]);

  useEffect(() => {
    if (showReload) {
      openNotification();
    }
  }, [showReload]);

  const loadActualAppVersion = async () => {
    const { status, data } = await getAllAppversions();
    if (status === 200) {
      setActualVersion(data.data[data.data.length - 1]);
    }
  };

  const checkLastVersion = async () => {
    const { status, data } = await getAllAppversions();
    if (status === 200) {
      console.log('actualVersion', actualVersion);
      console.log('lastVersion', data.data[data.data.length - 1]);
      console.log('diffVersion', actualVersion.version, data.data[data.data.length - 1].version, actualVersion.version !== data.data[data.data.length - 1].version);
      if (actualVersion.version !== data.data[data.data.length - 1].version) {
        setShowReload(true);
      }
    }
  };

  const reloadPage = async () => {
    // waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  const openNotification = () => {
    const args = {
      message: 'Nueva versión disponible',
      description:
        <>
          <Button onClick={reloadPage}>ACTUALIZAR</Button>
        </>,
      duration: 0
    };
    notification.open(args);
  };

  return (
    <>
    </>
  );
};

export default ServiceWorkerWrapper;
