import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getAllHistories = (token) => {
  return methodGet('comunicationtemplate/all', '', token);
};

export const getComunicationtemplateById = (id, token) => {
  return methodGet('comunicationtemplate/' + id, '', token);
};

export const getComunicationtemplateByQuery = (req, token) => {
  return methodGet('comunicationtemplate', req, token);
};

export const createComunicationtemplate = (data, token) => {
  return methodPost('comunicationtemplate/create', data, token);
};

export const patchComunicationtemplate = (id, data, token) => {
  return methodPatch('comunicationtemplate/update?id=' + id, data, token);
};

export const deleteComunicationtemplate = (id, token) => {
  return methodDelete('comunicationtemplate/delete?id=' + id, token);
};
