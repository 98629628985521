import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useSound from 'use-sound';
import beep1 from '../../assets/sounds/beep1.mp3';
import beep2 from '../../assets/sounds/beep2.mp3';
import beep3 from '../../assets/sounds/beep3.mp3';
import beep4 from '../../assets/sounds/beep4.mp3';
import beep1c from '../../assets/sounds/beep1c.mp3';
import moment from 'moment';
import 'moment/locale/es';

const RepChrono = (props) => {
  const [diff, setDiff] = useState(null);
  const [initial, setInitial] = useState(null);
  const [concentric, setConcentric] = useState(true);
  const [eccentric, setEccentric] = useState(false);
  const [squeeze, setSqueeze] = useState(false);
  const [startChrono, setStartChrono] = useState(false);

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    if (concentric) {
      playBeep2();
    } else if (eccentric) {
      playBeep3();
    } else if (squeeze) {
      playBeep4();
    }
  }, [eccentric, concentric, squeeze]);

  const [playBeep1] = useSound(beep1);
  const [playBeep1c] = useSound(beep1c);
  const [playBeep2] = useSound(beep2);
  const [playBeep3] = useSound(beep3);
  const [playBeep4] = useSound(beep4);

  const tick = () => {
    const update = new Date(+new Date() - initial);
    let total;
    if (concentric) {
      total = props.concentric;
      // props.handleProgress((update.getSeconds().toString() + update.getMilliseconds().toString().padStart(3, '0')), total * 1000, 'concentric');
      props.handleProgress((moment(update).format('s') + moment(update).format('SSS')), total * 1000, 'concentric');
    }
    if (eccentric) {
      total = props.eccentric;
      props.handleProgress((moment(update).format('s') + moment(update).format('SSS')), total * 1000, 'eccentric');
    };
    if (squeeze) {
      total = props.squeeze;
      props.handleProgress((moment(update).format('s') + moment(update).format('SSS')), total * 1000, 'squeeze');
    }
    if (update.getSeconds() === props.concentric && concentric) {
      setInitial(+new Date());
      setConcentric(false);
      if (props.squeeze) {
        setSqueeze(true);
      } else {
        setEccentric(true);
      }
      // playBeep1();
      // props.beep1();
      // loadBeep1();
      props.handleReps();
    }
    if (update.getSeconds() === props.squeeze && squeeze) {
      setInitial(+new Date());
      setSqueeze(false);
      setEccentric(true);
      // playBeep3();
      // props.beep3();
      // loadBeep3();
    }
    if (update.getSeconds() === props.eccentric && eccentric) {
      setInitial(+new Date());
      setEccentric(false);
      setConcentric(true);
      // playBeep2();
      // props.beep2();
      // loadBeep2();
    }
    setDiff(update);
  };
  const start = () => {
    if (!startChrono) {
      setInitial(+new Date());
      setStartChrono(true);
    }
    if (startChrono) setStartChrono(false);
  };

  useEffect(() => {
    //
    if (initial && startChrono) {
      tick();
    }
  }, [initial]);

  useEffect(() => {
    if (diff && startChrono) {
      tick();
    }
  }, [diff]);

  const timeFormat = (date) => {
    if (!date) return '00:00';

    // let ss = date.getSeconds();
    // let cm = Math.round(date.getMilliseconds() / 100);
    //
    // ss = ss < 10 ? '0' + ss : ss;
    // cm = cm === 10 ? '00' : cm + '0';

    // return `${ss}:${cm}`;
    return moment(date).format('ss:S') + '0';
  };

  return (
    <div className="App" onClick={start}>
      {timeFormat(diff)}
    </div>
  );
};

export default RepChrono;
