import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { app } from '../../services/firebase/firebaseConfig';
import { Context as Auth } from '../../services/auth/AuthContext';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { createAccount } from '../../services/user.service';

const Login = (props) => {
  const { firebaseUser } = useContext(Auth);
  const [error, seterror] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (firebaseUser) {
      navigate('/dashboard');
    }
  }, [history, firebaseUser]);

  const createNewAccount = async (data, uid, token) => {
    const formData = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      firebaseUid: uid,
      birthday: data.birthday,
      phone: data.phone,
      nif: data.nif
    };
    await createAccount(formData, token);
  };

  const correctClave = async e => {
    const { email, passwd } = e;
    const auth = getAuth(app);
    await createUserWithEmailAndPassword(auth, email, passwd)
      .then(result => {
        createNewAccount(e, result.user.uid, result.user.accessToken);
      })
      .catch(error => {
        seterror(error.message);
      });
  };

  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          <h1>Ingreso</h1>
        </Form.Item>
        {error ? <Form.Item>{error}</Form.Item> : null}
        <Form.Item
          name="email">
          <Input
            prefix={
              <UserOutlined />
            }
            name="email"
            placeholder="Usuario"
          />
        </Form.Item>
        <Form.Item name="passwd">
          <Input
            prefix={
              <LockOutlined />
            }
            name="passwd"
            type="password"
            placeholder="Clave"
          />
        </Form.Item>
        <Form.Item
          name="name">
          <Input
            prefix={
              <UserOutlined />
            }
            name="name"
            placeholder="Nombre"
          />
        </Form.Item>
        <Form.Item
          name="surname">
          <Input
            prefix={
              <UserOutlined />
            }
            name="surname"
            placeholder="Apellidos"
          />
        </Form.Item>
        <Form.Item
          name="phone">
          <Input
            prefix={
              <UserOutlined />
            }
            name="phone"
            placeholder="Teléfono"
          />
        </Form.Item>
        <Form.Item
          name="nif">
          <Input
            prefix={
              <UserOutlined />
            }
            name="nif"
            placeholder="Nif"
          />
        </Form.Item>
        <Form.Item
          name="birthday">
          <Input
            prefix={
              <UserOutlined />
            }
            name="birthday"
            placeholder="Fecha de nacimiento"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ marginRight: 10 }}
          >
                 Registrate
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;
