import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Input,
  Button,
  Select,
  message,
  Popconfirm,
  Table,
  Upload,
  Image
} from 'antd';
import Form from 'antd/es/form/Form';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { createEvaluation, getEvaluationById, patchEvaluation } from '../../../services/evaluation.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { Option } from 'antd/es/mentions';
import TextArea from 'antd/es/input/TextArea';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined, UploadOutlined, FileImageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { uploadFile } from '../../../services/file.service';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing
      ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
      : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

const CreateEvaluation = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [evaluation, setEvaluation] = useState(null);
  const [evalData, setEvalData] = useState([]);
  const [evalNumber, setEvalNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [evaluationForm] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      if (params.id) {
        await loadEvaluation(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadEvaluation = async (id, token) => {
    const { status, data } = await getEvaluationById(id, token);
    if (status === 200) {
      setEvaluation(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const getLastKey = (evalData) => {
    let lastKey = 0;
    evalData.forEach((item) => {
      if (item.key > lastKey) {
        lastKey = item.key;
      }
    });
    return lastKey;
  };

  const fillForm = (evaluation) => {
    setEvalData(evaluation.eval);
    setEvalNumber(getLastKey(evaluation.eval) + 1);
    evaluationForm.setFieldsValue({
      name: evaluation.name,
      description: evaluation.description
    });
  };

  const deactivateEvaluation = async () => {
    if (evaluation) {
      let account;
      if (params.id) account = evaluation.account;
      else account = params.account;
      const { status } = await patchEvaluation(evaluation._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/users/evaluation/' + account);
        message.success(capitalize(t('evaluationDeleted')));
      }
    }
  };

  const createNewEvaluation = async (data, token) => {
    let account;
    if (params.id) account = evaluation.account;
    else account = params.account;

    const formData = {
      name: data.name,
      description: data.description,
      eval: evalData,
      account: account
    };
    if (params.id) await patchEvaluation(params.id, formData, token);
    else await createEvaluation(formData, token);
    navigate('/dashboard/users/evaluation/' + account);
  };

  const correctClave = async e => {
    await createNewEvaluation(e, user.token);
  };

  const handleDelete = (key) => {
    const newData = evalData.filter((item) => item.key !== key);
    setEvalData(newData);
  };

  const handleAdd = () => {
    const newData = {
      key: evalNumber,
      evaluation: '.',
      description: '.'
    };
    setEvalData([...evalData, newData]);
    setEvalNumber(evalNumber + 1);
  };

  const handleSave = (row) => {
    const newData = [...evalData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setEvalData(newData);
  };

  const defaultColumns = [
    {
      title: capitalize(t('evaluation')),
      dataIndex: 'evaluation',
      editable: true
    },
    {
      title: capitalize(t('description')),
      dataIndex: 'description',
      editable: true
    },
    {
      title: capitalize(t('actions')),
      dataIndex: 'actions',
      width: '20px',
      editable: false,
      render: (_, record) =>
        evalData.length >= 1
          ? (
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '16px' }} >
              <Popconfirm title={capitalize(t('confirmDeleteRow'))} onConfirm={() => handleDelete(record.key)}>
                <a><DeleteOutlined/></a>
              </Popconfirm>
            </div>
          )
          : null
    },
    {
      title: capitalize(t('uploadImage')),
      dataIndex: 'upload',
      width: '20px',
      editable: false,
      render: (_, record) => {
        if (evalData.find((item) => item.key === record.key).image) {
          return <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Button type="link" style={{ fontSize: 22, padding: 0 }} onClick={() => {
              setPreviewImage(record.image);
              setVisible(true);
            }}>
              <FileImageOutlined />
            </Button>
            <Image
              width={0}
              style={{ display: 'none' }}
              preview={{
                visible,
                src: previewImage,
                mask: true,
                onVisibleChange: value => {
                  setVisible(value);
                }
              }}
            />
            <Popconfirm title={capitalize(t('confirmDeleteImage'))} onConfirm={() => handleRemoveFile(record.key)}>
              <a><DeleteOutlined size={12}/></a>
            </Popconfirm>
          </div>;
        }
        return evalData.length >= 1
          ? (
            <Upload
              onRemove={file => {
                handleRemoveFile(record.key, file);
              }}
              beforeUpload={(file) => {
                upload(file, record.key);
                return false;
              }}
              maxCount={1}
              listType="text"
              accept="image/*,.pdf"
            >
              <Button icon={<UploadOutlined/>}>{capitalize(t('upload'))}</Button>
            </Upload>
          )
          : null;
      }
    }
  ];

  const upload = async (file, key) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destination', 'evaluation');

    const res = await uploadFile(formData);

    const data = [...evalData];
    data.find((item) => item.key === key).image = res.data.data.Location;
    setEvalData(data);

    return res.data.data.Location;
  };

  const handleRemoveFile = (key) => {
    const data = [...evalData];
    data.find((item) => item.key === key).image = null;
    setEvalData(data);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={evaluationForm} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 700
      }}>
        <Form.Item>
          {evaluation ? <h1>{capitalizeAll(t('editEvaluation'))}</h1> : <h1>{capitalizeAll(t('createEvaluation'))}</h1>}
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={evaluation ? evaluation.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>
          {capitalize(t('description'))}:
        </div>
        <Form.Item
          name="description"
          rules={[{
            required: false,
            message: capitalize(t('description')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            addonBefore={capitalize(t('description'))}
            rows={4}
            name="description"
            defaultValue={evaluation ? evaluation.description : ''}
            placeholder={capitalize(t('description'))}
          />
        </Form.Item>
        <div>
          <Button
            onClick={handleAdd}
            type="primary"
            style={{
              marginBottom: 16
            }}
          >
            {capitalize(t('addEvaluation'))}
          </Button>
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={evalData}
            columns={columns}
          />
        </div>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {evaluation && (
            <Popconfirm
              title={capitalize(t('confirmDeleteEvaluation'))}
              onConfirm={deactivateEvaluation}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {evaluation ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createEvaluation'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateEvaluation;
