// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyClu38GTAPKfKtM_8BvTuz_Ow4GDtlVkn4',
  authDomain: 'app-back-3e422.firebaseapp.com',
  projectId: 'app-back-3e422',
  storageBucket: 'app-back-3e422.appspot.com',
  messagingSenderId: '31192908541',
  appId: '1:31192908541:web:d00e1d3e3b158eff0cb494',
  measurementId: 'G-V07WTLMJ8R'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const signUpApp = initializeApp(firebaseConfig, 'Secondary');
const analytics = getAnalytics(app);

export { app, analytics, signUpApp };
