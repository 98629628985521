import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { getExerciseById } from '../../services/exercise.service';
import { getIdToken } from 'firebase/auth';

const TagExercise = (props) => {
  const [exercise, setExercise] = useState();
  useEffect(() => {
    getExercise(props.id, props.token);
  }, []);

  const getExercise = async (id, token) => {
    const { status, data } = await getExerciseById(id, await token);
    if (status === 200 && data.data.isActive) {
      setExercise(data.data);
    }
  };

  if (!exercise) {
    return null;
  }

  return (
    <Tag color="green">{exercise?.name}</Tag>
  );
};

export default TagExercise;
