import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  CalendarOutlined, ContainerOutlined, ControlOutlined,
  DesktopOutlined,
  PieChartOutlined,
  TeamOutlined
} from '@ant-design/icons';
import BookingCalendar from './BookingCalendar';
import { useMediaQuery } from 'react-responsive';
import CalendarTemplates from './CalendarTemplates';
import CreateCalendarTemplate from './CreateCalendarTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import BookingSettings from './Settings';

const getItem = (label, key, icon, page, component) => {
  return {
    key,
    icon,
    label,
    page,
    component
  };
};

const BookingDashboard = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedKey, setSelectedKey] = useState('1');
  const { Content, Sider } = Layout;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation();

  useEffect(() => {
    if (params.selected) {
      if (params.selected === 'assign') {
        setSelectedPage(<BookingCalendar/>);
        setSelectedKey('1');
      } else if (params.selected === 'templates') {
        setSelectedKey('2');
        if (params.action === 'add') {
          setSelectedPage(<CreateCalendarTemplate/>);
        } else if (params.action === 'edit') {
          setSelectedPage(<CreateCalendarTemplate/>);
        } else {
          setSelectedPage(<CalendarTemplates/>);
        }
      } else if (params.selected === 'settings') {
        setSelectedPage(<BookingSettings/>);
        setSelectedKey('3');
      } else if (params.selected === 'subscriptions') {
        setSelectedPage(<BookingSettings/>);
        setSelectedKey('4');
      }
    } else {
      setSelectedPage(<BookingCalendar />);
      setSelectedKey('1');
    }
    if (isTabletOrMobile) {
      setCollapsed(true);
    }
  }, []);

  const items = [
    getItem(capitalize(t('assignSlots')), '1', <CalendarOutlined />, '/dashboard/booking/assign'),
    getItem(capitalize(t('templates')), '2', <ContainerOutlined />, '/dashboard/booking/templates'),
    // getItem(capitalize(t('subscriptions')), '4', <DesktopOutlined />, '/dashboard/booking/subscriptions'),
    getItem(capitalize(t('settings')), '3', <ControlOutlined />, '/dashboard/booking/settings')
  ];

  return (
    <Layout
      style={{
        minHeight: '100vh',
        padding: '0px',
        background: '#FFFFFF'
      }}
    >
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div style={{ marginTop: 25 }}/>
        <Menu theme="dark" defaultSelectedKeys={[selectedKey]} selectedKeys={[selectedKey]} mode="inline" >
          {items.map((item) => {
            return (
              <Menu.Item key={item.key} icon={item.icon} onClick={() => {
                // setSelectedPage(item.component);
                setSelectedKey(item.key);
                navigate(item.page);
                navigate(0);
              }}>
                {item.label}
              </Menu.Item>
            );
          }
          )}
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ background: '#FFFFFF' }}>
        <Content
          style={{
            margin: '0 16px',
            background: '#FFFFFF'
          }}
        >
          <div
            className="site-layout-background calendar-padding"
            style={{
              minHeight: 360,
              background: '#FFFFFF'
            }}
          >
            {selectedPage}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default BookingDashboard;
