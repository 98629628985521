import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import BookingCreate from '../../../components/modals/BookingCreate';
import moment from 'moment';
import { getBookingPopulated, patchBooking } from '../../../services/booking.service';
import { getIdToken } from 'firebase/auth';
import BookingEdit from '../../../components/modals/BookingEdit';
import Spinner from '../../../components/navigation/Spinner';
import ButtonGroup from 'antd/es/button/button-group';
import { Button, Col, Form, Input, message, Popconfirm, Row } from 'antd';
import BookingTemplateCreate from '../../../components/modals/BookingTemplateCreate';
import { getCenterByQuery } from '../../../services/center.service';
import { getAccountByQuery } from '../../../services/user.service';
import { Context as Auth } from '../../../services/auth/AuthContext';
import BookingTemplateEdit from '../../../components/modals/BookingTemplateEdit';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  createCalendartemplate,
  getCalendartemplateByQuery,
  patchCalendartemplate
} from '../../../services/calendartemplate.service';
import { useNavigate, useParams } from 'react-router-dom';
require('moment/locale/es.js');

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const CreateCalendarTemplate = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const navigate = useNavigate();
  const [bookingDate, setBookingDate] = useState([]);
  const [bookingCreateVisible, setBookingCreateVisible] = useState(false);
  const [bookingEditVisible, setBookingEditVisible] = useState(false);
  const [startData, setStartData] = useState(new Date());
  const [bookingEdit, setBookingEdit] = useState();
  const [booking, setBooking] = useState();
  const [endData, setEndData] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [center, setCenter] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [template, setTemplate] = useState(null);
  const [form] = Form.useForm();
  const [bcView, setBCView] = useState('day');
  const { t } = useTranslation();
  const calendarRef = useRef();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      const trainers = await loadTrainers(data.data[0]._id);
      if (params.id) {
        await loadTemplate(trainers);
      }
      setLoading(false);
    }
  };

  const loadTemplate = async (trainers) => {
    const { status, data } = await getCalendartemplateByQuery({ _id: params.id }, user.token);
    if (status === 200) {
      setTemplate(data.data[0]);
      fillForm(data.data[0], trainers);
    }
  };

  const loadTrainers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ roles: 'trainer', center: centerId, isActive: true }, user.token);
    if (status === 200) {
      setTrainers(data.data);
      return data.data;
    }
  };

  const updateBooking = useCallback(({ bookingId, start, end }) => {
    const book = bookings.find(book => book.id === bookingId);
    book.start = start;
    book.end = end;
  }, [bookings]);

  const moveBook = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false
    }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      updateBooking({ bookingId: event.id, start: start, end: end });
      setBookingDate((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, resourceId, allDay }];
      });
    },
    [setBookingDate, bookings, setBookings]
  );

  const resizeBook = useCallback(
    ({ event, start, end }) => {
      updateBooking({ bookingId: event.id, start: start, end: end });
      setBookingDate((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setBookingDate, bookings, setBookings]
  );

  const handleAddBook = (book) => {
    const bookingsData = [...bookings, book];
    setBookings(bookingsData);
  };

  const handleEditBook = (book) => {
    const bookingsData = [...bookings];
    const index = bookingsData.findIndex(b => b.id === book.id);
    bookingsData[index] = book;
    setBookings(bookingsData);
  };

  const handleDeleteBook = (book) => {
    const bookingsData = [...bookings];
    const index = bookingsData.findIndex(b => b.id === book);
    bookingsData.splice(index, 1);
    setBookings(bookingsData);
  };

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setStartData(start);
      setEndData(end);
      setBookingCreateVisible(true);
    }
    // [setStart, setEnd]
  );

  const handleEditBookSlot = (id, title, start, end, color) => {
    const bookingDates = [...bookingDate];
    const index = bookingDates.findIndex(b => b.id === id);
    bookingDates.splice(index, 1, { id, title, start, end, color });
    setBookingDate(bookingDates);
  };

  const handleDeleteBookSlot = (id) => {
    const bookingDates = [...bookingDate];
    const index = bookingDates.findIndex(b => b.id === id);
    bookingDates.splice(index, 1);
    setBookingDate(bookingDates);
  };

  const handleBookSlot = useCallback(
    (id, title, start, end, color) => {
      if (id) {
        setBookingDate((prev) => [...prev, { id, start, end, title, color }]);
      }
      // props.loadBooks(props.center._id);
    },
    [setBookingDate]
  );
  //
  //

  const handleSelectBook = useCallback(
    (event) => {
      setBookingEdit(event);
      setBooking(bookings.find(book => book.id === event.id));
      setBookingEditVisible(true);
      // window.alert(event.title)
    },
    []
  );

  const fillForm = (template, trainers) => {
    form.setFieldsValue({
      name: template.name
    });
    setBookings(template.bookings);
    setBCView(template.type);
    const bookingsData = [];
    template.bookings.forEach(book => {
      const trainer = trainers.find(trainer => trainer._id === book.trainer);
      const title = `${trainer.name} ${trainer.surname} - ${trainer.email}`;
      const test = { id: book.id, title: title, start: new Date(book.start), end: new Date(book.end), color: 'lightgreen' };
      bookingsData.push(test);
      setBookingDate(bookingsData);
      // handleBookSlot(book.id, title, book.start, book.end, 'lightgreen');
    });
  };

  const deactivateCalendartemplate = async () => {
    if (template) {
      const { status } = await patchCalendartemplate(template._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/booking/templates');
        navigate(0);
        message.success(capitalize(t('templateDeleted')));
      }
    }
  };

  const createNewCalendartemplate = async (data, token) => {
    const formData = {
      name: data.name,
      type: bcView,
      center: center._id,
      bookings: [...bookings]
    };
    if (params.id) await patchCalendartemplate(params.id, formData, token);
    else await createCalendartemplate(formData, token);
    navigate('/dashboard/booking/templates');
    navigate(0);
  };

  const correctClave = async e => {
    await createNewCalendartemplate(e, user.token);
  };

  if (loading) {
    return (<Spinner/>);
  }

  const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'];

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Form form={form} onFinish={correctClave} style={{
              width: '100%',
              maxWidth: 400
            }}>
              <Form.Item>
                {template ? <h1>{capitalize(t('editTemplate'))}</h1> : <h1>{capitalize(t('createTemplate'))}</h1>}
              </Form.Item>
              <Row justify='space-between'>
                <Col>
                  <Form.Item
                    name="name"
                    rules={[{
                      required: true,
                      message: capitalize(t('name')) + ' ' + t('isRequired')
                    }]}>
                    <Input
                      addonBefore={capitalize(t('name'))}
                      name="name"
                      defaultValue={template ? template.name : ''}
                      placeholder={capitalize(t('name'))}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
                  {template && (
                    <Popconfirm
                      title={capitalize(t('confirmDeleteTemplate'))}
                      onConfirm={deactivateCalendartemplate}
                      // onCancel={cancel}
                      okText={capitalize(t('yes'))}
                      cancelText={capitalize(t('no'))}
                    >
                      <Form.Item>
                        <Button
                          type="primary"
                          danger
                          className="delete-form-button"
                          style={{ marginRight: 10 }}
                          // onClick={deactivateUser}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Form.Item>
                    </Popconfirm>
                  )}
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      style={{ marginRight: 10 }}
                    >
                      {template ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createTemplate'))}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Row>
          <Row>
            <Col span={24}>
              <ButtonGroup style={{ marginBottom: 12 }}>
                <Button onClick={() => {
                  setBCView('day');
                }}>{capitalize(t('day'))}</Button>
                <Button onClick={() => {
                  setBCView('week');
                }}>{capitalize(t('week'))}</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{ height: 600 }}>
                <DragAndDropCalendar
                  ref={calendarRef}
                  dayLayoutAlgorithm={'no-overlap'}
                  defaultView={Views.DAY}
                  date={new Date(0)}
                  events={bookingDate}
                  toolbar={false}
                  // components={{
                  //   toolbar: CustomToolbar
                  // }}
                  localizer={localizer}
                  onEventDrop={moveBook}
                  onEventResize={resizeBook}
                  resizable
                  resizableAccessor={() => true}
                  selectable
                  showMultiDayTimes={true}
                  timeslots={2}
                  step={15}
                  onSelectEvent={handleSelectBook}
                  onSelectSlot={handleSelectSlot}
                  components={{ header: ({ date }) => <span>{days[moment(date).day()]}</span> }}
                  // onView={(view) => { props.setCalendarView(view); }}
                  views={['day', 'week']}
                  view={bcView}
                  onView={setBCView}
                  culture={'es'}
                  // onNavigate={(date) => { props.setSelectedDay(date); }}
                  scrollToTime={new Date(new Date().setHours(8, 0))}
                  eventPropGetter={event => ({
                    style: {
                      backgroundColor: event.color,
                      color: 'black'
                    }
                  })}
                  messages={
                    {
                      allDay: 'Todo el día',
                      previous: 'Anterior',
                      next: 'Siguiente',
                      today: 'Hoy',
                      month: 'Mes',
                      week: 'Semana',
                      day: 'Día',
                      agenda: 'Agenda',
                      date: 'Fecha',
                      time: 'Hora',
                      event: 'Evento',
                      showMore: total => `+ ${total} más`
                    }
                  }
                />
              </div>
            </Col>

          </Row>
        </Col>
      </Row>

      <BookingTemplateCreate bookingDataVisible={bookingCreateVisible} setBookingDataVisible={setBookingCreateVisible} bookings={bookings} start={startData} end={endData} handleBookSlot={handleBookSlot} trainers={trainers} center={center} user={user} handleAddBook={handleAddBook}/>
      {bookingEdit && <BookingTemplateEdit bookingDataVisible={bookingEditVisible} setBookingDataVisible={setBookingEditVisible} handleEditBookSlot={handleEditBookSlot} handleEditBook={handleEditBook} handleDeleteBook={handleDeleteBook} bookings={bookings} handleDeleteBookSlot={handleDeleteBookSlot} edit={bookingEdit} trainers={trainers} center={center} user={user} />}
    </>
  );
};

export default CreateCalendarTemplate;
