import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getAccountById } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import Spinner from '../../../components/navigation/Spinner';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { getExerciseSessionByQuery } from '../../../services/exercisesession.service';
import { getCenterById } from '../../../services/center.service';
import { Button, Col, Row } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

const UserExercises = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [exercises, setExercises] = useState([]);
  // const [programs, setPrograms] = useState([]);
  // const [programSessions, setProgramSessions] = useState([]);
  const [exerciseSessions, setExerciseSessions] = useState([]);
  const [account, setAccount] = useState();
  const [center, setCenter] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const params = useParams();

  useEffect(() => {
    if (user.account) {
      loadAccount();
    }
  }, [user.account]);

  const loadAccount = async () => {
    const { status, data } = await getAccountById(params.account ? params.account : user.account._id, user.token);
    if (status === 200) {
      setAccount(data.data);
      const centerId = await loadCenter(data.data.center);
      await loadExercises(data.data._id, centerId);
      setLoading(false);
    }
  };

  const loadCenter = async (centerId) => {
    const { status, data } = await getCenterById(centerId, user.token);
    if (status === 200) {
      setCenter(data.data);
      return data.data._id;
    }
  };

  // const loadPrograms = (userId) => {
  //   const { status, data } = getProgramByQuery({ account: userId, isActive: true });
  //   if (status === 200) {
  //     setPrograms(data.data);
  //   }
  // };
  //
  // const loadProgramSession = async (programId) => {
  //   const { status, data } = await getProgramSessionByQuery(user.account._id, user.token);
  //   if (status === 200) {
  //     setProgramSessions(data.data);
  //   }
  // };

  const loadExercises = async (userId, centerId) => {
    const { status, data } = await getExerciseByQuery({ isActive: true, center: centerId }, user.token);
    if (status === 200) {
      const exerciseSessions = await loadExerciseSession(userId);
      const accountExercises = [];

      data.data.forEach(exercise => {
        exerciseSessions.forEach(exerciseSession => {
          if (exerciseSession.exercise === exercise._id && accountExercises.indexOf(exercise) === -1) {
            accountExercises.push(exercise);
          }
        });
      });
      setExercises(accountExercises);
    }
  };

  const loadExerciseSession = async (userId) => {
    const { status, data } = await getExerciseSessionByQuery({ account: userId }, user.token);
    if (status === 200) {
      setExerciseSessions(data.data);
      return (data.data);
    }
  };

  const percentBetween = (numA, numB) => {
    return (numA - numB) / numB * 100;
  };

  const renderExercises = (exercise) => {
    const sessions = exerciseSessions.filter(exerciseSession => exerciseSession.exercise === exercise._id);
    const last30 = [...sessions.slice(Math.max(sessions.length - 30, 0))];
    return (
      <>
        <div className='exerciseCard' style={{ cursor: 'pointer' }} onClick={() => {
          if (params.account) {
            navigate(`/user/exercise/${exercise._id}/${params.account}`);
          } else {
            navigate(`/user/exercise/${exercise._id}`);
          }
        }}>
          <div></div>
          <div style={{ color: 'white' }}>{exercise.name}</div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', width: '100%', color: 'lightgray', fontSize: '0.8em' }}>
            {exercise.type === 'isometric'
              ? (capitalize(t('isometric')))
              : (<>
                {capitalize(t('evolution'))} {percentBetween(last30[last30.length - 1].weight, last30[0].weight).toFixed(2)}%
              </>)}
          </div>
        </div>
      </>
    );
  };

  // const renderExercises = (exercise) => {
  //   return (
  //     <>
  //       <Row justify='center' align='middle' style={{ marginTop: 25, paddingTop: 5, paddingBottom: 0 }}>
  //         <Col md={6} style={{ width: '100%', height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#708090', color: 'white', fontWeight: 'bold' }}>
  //           {exercise.name}
  //         </Col>
  //         <Col md={6} style={{ width: '100%', height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'beige', fontWeight: 'bold' }}>
  //           00%
  //         </Col>
  //         <Col md={6} style={{ width: '100%', height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#708090', color: 'white', fontWeight: 'bold' }}>
  //           <Button color="primary" type='text' style={{ width: '100%', color: 'white', fontWeight: 'bold' }} onClick={() => {
  //             navigate(`/user/exercise/${exercise._id}`);
  //           }}>
  //             {t('see').toUpperCase()} <CaretRightOutlined />
  //           </Button>
  //         </Col>
  //       </Row>
  //       <Row justify='center' align='middle' style={{ }}>
  //         <Col md={6} style={{ width: '100%', height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#e6e6e6' }}>

  //         </Col>
  //         <Col md={6} style={{ width: '100%', height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#e6e6e6' }}>
  //           {capitalize(t('evolution'))} %
  //         </Col>
  //         <Col md={6} style={{ width: '100%', height: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#e6e6e6' }}>

  //         </Col>
  //       </Row>
  //     </>

  //   );
  // };

  //
  //

  if (loading) {
    return <Spinner/>;
  }

  return (
    <Row justify='center'>
      {exercises.map(exercise => renderExercises(exercise))}
    </Row>
  );
};

export default UserExercises;
