import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useParams } from 'react-router-dom';
import { getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { getAccountById } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';

const CenterDetail = (props) => {
  const { id } = useParams();
  const { user } = useContext(Auth);
  const [center, setCenter] = useState(null);
  const [organizer, setOrganizer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadCenter(id);
  }, []);

  const loadCenter = async (id) => {
    const { status, data } = await getCenterById(id, user.token);
    if (status === 200) {
      setCenter(data.data);
      await loadOrganizer(data.data.organizer, user.token);
      setLoading(false);
    }
  };

  const loadOrganizer = async (id, token) => {
    const { status, data } = await getAccountById(id, token);
    if (status === 200) {
      setOrganizer(data.data);
      setLoading(false);
    }
  };

  return (
    <>
      {loading
        ? (
          <Spinner/>
        )
        : (
          <>
            <b>Center:</b>
            <p>
      Name: {center.name}<br/>
      Mail: {center.email}<br/>
      Phone: {center.phone}<br/>
            </p>
            <b>Organizer:</b>
            <p>
      Name: {organizer.name}<br/>
      Surname: {organizer.surname}<br/>
      Mail: {organizer.email}<br/>
      Phone: {organizer.phone}<br/>
            </p>
            <b>Trainers:</b>
            <p>

            </p>
            <b>Users:</b>
            <p>

            </p>
          </>
        )}
    </>
  );
};

export default CenterDetail;
