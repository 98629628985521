import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllCompositions = (token) => {
  return methodGet('composition/all', '', token);
};

export const getCompositionById = (id, token) => {
  return methodGet('composition/' + id, '', token);
};

export const getCompositionByQuery = (req, token) => {
  return methodGet('composition', req, token);
};

export const createComposition = (data, token) => {
  return methodPost('composition/create', data, token);
};

export const patchComposition = (id, data, token) => {
  return methodPatch('composition/update?id=' + id, data, token);
};
