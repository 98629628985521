import React, { useEffect, useState } from 'react';
import { Button, Radio } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { patchAccount } from '../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from './CheckoutForm';
import Spinner from '../navigation/Spinner';
// import './AppStripe.css';
const stripePromise = loadStripe('pk_test_51KpwDAGYouUTfreSIn4WQZcsxi3sEgwjHJJlRu0o6uCsfkHxBTVDh2ycsD3E2K3e2i6zoNHvdXERaMFNgyBQ4UKY004eQ1c2wm');

const BuyCredits = (props) => {
  const [credits, setCredits] = useState('10');
  const [creditLoading, setCreditLoading] = useState(false);
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    setCreditLoading(true);
    fetch('http://localhost:4000/api/v1/transaction/intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ account: props.account._id, email: props.account.email, center: props.account.center, amount: parseInt(credits) * 100, credits: parseInt(credits), description: `${credits} credits` })
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .then(() => setCreditLoading(false));
  }, [credits]);

  const addCredits = async () => {
    let actualCredits = 0;
    if (props.account.credits) {
      actualCredits = props.account.credits;
    }

    await patchAccount(props.account._id, {
      credits: actualCredits + parseInt(credits)
    }, props.user.token);
  };

  const appearance = {
    theme: 'stripe'
  };
  const options = {
    locale: 'es',
    clientSecret,
    appearance
  };

  return (
    <>
      <Modal title={capitalizeAll(t('buyCredits'))} visible={props.buyCreditsModalVisible}
        onCancel={() => {
          props.handleCloseBuyCreditsModal();
        }}

        onPan={(p) => { }}
        centered
        footer={[
          <div key={'total'} style={{ display: 'flex', width: '100%' }}>
            <span style={{ fontWeight: 600, fontSize: '1.5rem' }}>Total: {credits}€</span>
          </div>
          // <Button key="OK" onClick={ async () => {
          //   await addCredits();
          //   props.handleCloseBuyCreditsModal();
          // }}>
          //   {t('buy').toUpperCase()}
          // </Button>
        ]}
      >
        <Radio.Group defaultValue={credits} style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }} buttonStyle="solid" onChange={(e) => setCredits(e.target.value)}>
          <Radio.Button value="1">1 {capitalize(t('credit'))}</Radio.Button>
          <Radio.Button value="5">5 {capitalize(t('credits'))}</Radio.Button>
          <Radio.Button value="10">10 {capitalize(t('credits'))}</Radio.Button>
          <Radio.Button value="20">20 {capitalize(t('credits'))}</Radio.Button>
        </Radio.Group>
        {clientSecret && !creditLoading
          ? (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm credits={credits}/>
            </Elements>
          )
          : (
            <Spinner/>
          )}
      </Modal>
    </>

  );
};

export default BuyCredits;
