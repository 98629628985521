import React, { useEffect, useState } from 'react';
import Modal from 'antd/es/modal/Modal';
import { Button, Col, Row, Select, Input } from 'antd';
import { getIdToken } from 'firebase/auth';
import { getBooksessionByQuery } from '../../services/booksession.service';
import { deleteBooking, patchBooking } from '../../services/booking.service';
import Spinner from '../navigation/Spinner';
import { getAccountById } from '../../services/user.service';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
import moment from 'moment';
import 'moment/locale/es';

const BookingTemplateEdit = (props) => {
  const [selectedTrainer, setSelectedTrainer] = useState('null');
  const [selectedSlots, setSelectedSlots] = useState(1);
  const [booksessions, setBooksessions] = useState(null);
  const [selectedBooksession, setSelectedBooksession] = useState('null');
  const [selectedName, setSelectedName] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const { TextArea } = Input;
  const [queue, setQueue] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const { Option } = Select;
  const { t } = useTranslation();

  useEffect(async () => {
    const trainer = props.trainers.find(trainer => trainer.email === props.edit.title.split(' - ')[1]);
    setSelectedTrainer(trainer.name + ' ' + trainer.surname + ' - ' + trainer.email + ' - ' + trainer._id);
    const book = props.bookings.filter(booking => booking.id === props.edit.id);
    setSelectedSlots(book[0].slots);
    setSelectedName(book[0].name);
    setSelectedDescription(book[0].description);
    await loadBookSessions();
    setLoading(false);
  }, [props.edit]);

  useEffect(() => {
    if (selectedBooksession && selectedBooksession !== 'null') {
      const booksession = booksessions.find(booksession => booksession._id === selectedBooksession);
      const trainer = props.trainers.find(trainer => trainer._id === booksession.trainer);
      setSelectedName(booksession.name);
      setSelectedDescription(booksession.description);
      setSelectedSlots(booksession.slots);
      setSelectedTrainer(trainer.name + ' ' + trainer.surname + ' - ' + trainer.email + ' - ' + trainer._id);
    }
  }, [selectedBooksession]);

  const checkFields = () => {
    if (selectedName === '') {
      setMessage('Es necesario un nombre');
      return false;
    }
    if (selectedDescription === '') {
      setMessage('Es necesaria una descripción');
      return false;
    }
    if (selectedTrainer === 'null') {
      setMessage('Es necesario un entrenador');
      return false;
    }
    return true;
  };

  const loadBookSessions = async () => {
    const { status, data } = await getBooksessionByQuery({ center: props.center._id, isActive: true }, props.user.token);
    if (status === 200) {
      setBooksessions(data.data);
      setLoading(false);
    }
  };

  const editBooking = (payload) => {
    props.handleEditBook(payload);
    return payload.id;
  };

  const deleteBook = () => {
    props.handleDeleteBook(props.edit.id);
    return props.edit.id;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        title={capitalizeAll(t('editBookingData'))}
        visible={props.bookingDataVisible}
        onOk={() => {
          props.setBookingDataVisible(false);
          setSelectedBooksession('null');
          setSelectedName('');
          setSelectedDescription('');
          setMessage('');
        }}
        onCancel={() => {
          props.setBookingDataVisible(false);
          setSelectedTrainer('null');
          setSelectedBooksession('null');
          setSelectedName('');
          setSelectedDescription('');
          setMessage('');
        }}

        onPan={(p) => { }}
        centered
        // width={'100%'}
        footer={[
          <Button key="DELETE" type="danger" onClick={async () => {
            const id = deleteBook();
            props.handleDeleteBookSlot(id);
            setSelectedBooksession('null');
            setSelectedName('');
            setSelectedDescription('');
            setSelectedSlots(1);
            props.setBookingDataVisible(false);
            setSelectedTrainer('null');
            setMessage('');
          }}>
            {t('delete').toUpperCase()}
          </Button>,
          <Button key="OK" onClick={async () => {
            try {
              if (!checkFields()) {
                return;
              }
              const newBook = await editBooking({
                id: props.edit.id,
                center: props.center._id,
                trainer: selectedTrainer.split(' - ')[2],
                name: selectedName,
                description: selectedDescription,
                isGroup: selectedSlots > 1,
                slots: selectedSlots,
                start: new Date(props.edit.start),
                end: new Date(props.edit.end)
              });
              props.handleEditBookSlot(newBook, selectedTrainer, props.edit.start, props.edit.end, 'lightgreen');
              setSelectedBooksession('null');
              setSelectedName('');
              setSelectedDescription('');
              setSelectedSlots(1);
              props.setBookingDataVisible(false);
              setSelectedTrainer('null');
              setMessage('');
            } catch (error) {
              setSelectedBooksession('null');
              setSelectedName('');
              setSelectedDescription('');
              setSelectedSlots(1);
              setSelectedTrainer('null');
              setMessage('Error');
            }
          }}>
                    OK
          </Button>
        ]}
      >
        <Row>
          {capitalize(t('center'))}: {props.center.name} <br/>
        </Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <Row>
              {capitalize(t('booksession'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <Select value={selectedBooksession}
                style={{ width: '100%' }}
                onChange={setSelectedBooksession}>
                <Option value="null">{capitalize(t('booksession'))}</Option>
                {booksessions.map((booksession, key) => {
                  const trainer = props.trainers.find((t) => t._id === booksession.trainer);
                  return (
                    <Option key={key} value={booksession._id}>{booksession.name + ' - ' + trainer.name + ' ' + trainer.surname}</Option>
                  );
                })}
              </Select>
            </Row>
            <Row>
              {capitalize(t('name'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <Input type="text"
                style={{ width: '100%' }}
                value={selectedName}
                onChange={(e) => {
                  setSelectedName(e.target.value);
                }}
              />
            </Row>
            <Row>
              {capitalize(t('description'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <TextArea type="text"
                style={{ width: '100%' }}
                value={selectedDescription}
                onChange={(e) => {
                  setSelectedDescription(e.target.value);
                }}
              />
            </Row>
            <Row>
              {capitalize(t('userSlots'))}:
            </Row>
            <Row>
              <Select value={selectedSlots}
                style={{ width: '100%' }}
                onChange={setSelectedSlots}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
                <Option value={7}>7</Option>
                <Option value={8}>8</Option>
                <Option value={9}>9</Option>
                <Option value={10}>10</Option>
                <Option value={11}>11</Option>
                <Option value={12}>12</Option>
                <Option value={13}>13</Option>
                <Option value={14}>14</Option>
                <Option value={15}>15</Option>
                <Option value={16}>16</Option>
                <Option value={17}>17</Option>
                <Option value={18}>18</Option>
                <Option value={19}>19</Option>
                <Option value={20}>20</Option>
                <Option value={21}>21</Option>
                <Option value={22}>22</Option>
                <Option value={23}>23</Option>
                <Option value={24}>24</Option>
                <Option value={25}>25</Option>
                <Option value={26}>26</Option>
                <Option value={27}>27</Option>
                <Option value={28}>28</Option>
                <Option value={29}>29</Option>
                <Option value={30}>30</Option>
                <Option value={31}>31</Option>
                <Option value={32}>32</Option>
                <Option value={33}>33</Option>
                <Option value={34}>34</Option>
                <Option value={35}>35</Option>
                <Option value={36}>36</Option>
                <Option value={37}>37</Option>
                <Option value={38}>38</Option>
                <Option value={39}>39</Option>
                <Option value={40}>40</Option>
                <Option value={41}>41</Option>
                <Option value={42}>42</Option>
                <Option value={43}>43</Option>
                <Option value={44}>44</Option>
                <Option value={45}>45</Option>
                <Option value={46}>46</Option>
                <Option value={47}>47</Option>
                <Option value={48}>48</Option>
                <Option value={49}>49</Option>
                <Option value={50}>50</Option>
              </Select>
            </Row>
            <Row>
              {capitalize(t('trainer'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <Select value={selectedTrainer}
                style={{ width: '100%' }}
                onChange={setSelectedTrainer}>
                <Option value="null">{capitalize(t('trainer'))}</Option>
                {props.trainers.map((trn, key) => {
                  return (
                    <Option key={key} value={trn.name + ' ' + trn.surname + ' - ' + trn.email + ' - ' + trn._id}>{trn.name + ' ' + trn.surname}</Option>
                  );
                })}
              </Select>
            </Row>
          </Col>
        </Row>
        <Row>
          {capitalize(t('startDate'))}: {moment(props.start).format('HH:mm')}
        </Row>
        <Row>
          {capitalize(t('endDate'))}: {moment(props.end).format('HH:mm')}
        </Row>
        <Row>
          <span style={{ color: 'red' }}>{message}</span>
        </Row>
      </Modal>
    </>
  );
};

export default BookingTemplateEdit;
