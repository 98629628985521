import React, { useState, useContext, useEffect, useRef } from 'react';
import { Input, Button, Select, message, Popconfirm, Table } from 'antd';
import Form from 'antd/es/form/Form';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { createStrength, getStrengthById, patchStrength } from '../../../services/strength.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { Option } from 'antd/es/mentions';
import TextArea from 'antd/es/input/TextArea';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex]
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing
      ? (
        <Form.Item
          style={{
            margin: 0
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`
            }
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
      : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

const CreateStrength = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [strength, setStrength] = useState(null);
  const [evalData, setEvalData] = useState([]);
  const [evalNumber, setEvalNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [strengthForm] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      if (params.id) {
        await loadStrength(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadStrength = async (id, token) => {
    const { status, data } = await getStrengthById(id, token);
    if (status === 200) {
      setStrength(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const fillForm = (strength) => {
    setEvalData(strength.eval);
    setEvalNumber(strength.eval.length + 1);
    strengthForm.setFieldsValue({
      name: strength.name,
      description: strength.description
    });
  };

  const deactivateStrength = async () => {
    if (strength) {
      let account;
      if (params.id) account = strength.account;
      else account = params.account;
      const { status } = await patchStrength(strength._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/users/strength/' + account);
        message.success(capitalize(t('strengthTestDeleted')));
      }
    }
  };

  const createNewStrength = async (data, token) => {
    let account;
    if (params.id) account = strength.account;
    else account = params.account;

    const formData = {
      name: data.name,
      description: data.description,
      eval: evalData,
      account: account
    };
    if (params.id) await patchStrength(params.id, formData, token);
    else await createStrength(formData, token);
    navigate('/dashboard/users/strength/' + account);
  };

  const correctClave = async e => {
    await createNewStrength(e, user.token);
  };

  const handleDelete = (key) => {
    const newData = evalData.filter((item) => item.key !== key);
    setEvalData(newData);
  };

  const handleAdd = () => {
    const newData = {
      key: evalNumber,
      tilt: '.',
      weight: '.'
    };
    setEvalData([...evalData, newData]);
    setEvalNumber(evalNumber + 1);
  };

  const handleSave = (row) => {
    const newData = [...evalData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setEvalData(newData);
  };

  const defaultColumns = [
    {
      title: capitalize(t('tilt')),
      dataIndex: 'tilt',
      editable: true
    },
    {
      title: capitalize(t('strength')),
      dataIndex: 'weight',
      editable: true
    },
    {
      title: capitalize(t('actions')),
      dataIndex: 'actions',
      width: '40px',
      render: (_, record) =>
        evalData.length >= 1
          ? (
            <Popconfirm title={capitalize(t('confirmDeleteRow'))} onConfirm={() => handleDelete(record.key)}>
              <a><DeleteOutlined /></a>
            </Popconfirm>
          )
          : null
    }
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    zoomPlugin
    // ChartDataLabels
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: capitalize(t('strength'))
        },
        ticks: {
          stepSize: 5
        }
        // suggestedMin: 20,
        // suggestedMax: 120
      },
      x: {
        title: {
          display: true,
          text: capitalize(t('tilt'))
        }
      }
    },
    plugins: {
      datalabels: {
        font: {
          size: '18px'
        }
      }
    }
  };

  const labels = evalData.map(evalD => evalD.tilt);

  const data = {
    labels,
    datasets: [
      {
        id: 1,
        label: capitalize(t('strength')),
        borderColor: 'rgba(193, 221, 230,1)',
        backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: true,
        data: evalData.map(evalD => evalD.weight)
      }
    ]
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={strengthForm} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 700
      }}>
        <Form.Item>
          {strength ? <h1>{capitalizeAll(t('editStrengthTest'))}</h1> : <h1>{capitalizeAll(t('createStrengthTest'))}</h1>}
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={strength ? strength.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>
          {capitalize(t('description'))}:
        </div>
        <Form.Item
          name="description"
          rules={[{
            required: true,
            message: capitalize(t('description')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            addonBefore={capitalize(t('description'))}
            rows={4}
            name="description"
            defaultValue={strength ? strength.description : ''}
            placeholder={capitalize(t('description'))}
          />
        </Form.Item>
        <div style={{ height: 300 }}>
          <Line
            datasetIdKey='id'
            data={data}
            options={options}
          />
        </div>
        <div>
          <Button
            onClick={handleAdd}
            type="primary"
            style={{
              marginBottom: 16
            }}
          >
            {capitalize(t('addRow'))}
          </Button>
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={evalData}
            columns={columns}
          />
        </div>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {strength && (
            <Popconfirm
              title={capitalize(t('confirmDeleteStrengthTest'))}
              onConfirm={deactivateStrength}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {strength ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createStrengthTest'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateStrength;
