import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Table, Collapse } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getAccountByQuery } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { getTransactionByQuery } from '../../../services/transaction.service';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import { getCenterByQuery } from '../../../services/center.service';
import moment from 'moment';
import 'moment/locale/es';

const Transactions = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsByMonth, setTransactionsByMonth] = useState([]);
  const { t } = useTranslation();
  const { Panel } = Collapse;

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      await loadTransactions(data.data[0]._id);
    }
  };

  const loadTransactions = async (centerId) => {
    const { status, data } = await getTransactionByQuery({ center: centerId, status: 'succeded' }, user.token);
    if (status === 200) {
      separateTransactionsByMonth(data.data.reverse());
      await loadAccounts(centerId);
      setTransactions(data.data);
      setLoading(false);
    }
  };

  const loadAccounts = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId }, user.token);
    if (status === 200) {
      setAccounts(data.data);
    }
  };

  const separateTransactionsByMonth = (transactions) => {
    const transactionsByMonth = [];
    transactions.forEach((transaction) => {
      const date = moment(transaction.timestamp.updatedAt).format('MMMM YYYY');
      if (!transactionsByMonth[date]) {
        transactionsByMonth[date] = [];
      }
      transactionsByMonth[date].push(transaction);
    });
    setTransactionsByMonth(transactionsByMonth);
  };

  const columns = [
    {
      title: capitalize(t('transactions')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const accountSession = accounts.find(account => account._id === record.account);
        return (
          <React.Fragment>
            {moment(record.timestamp.updatedAt).format('DD/MM/YYYY HH:mm')}<br/>
            {accountSession.name} {accountSession.surname}<br/>
            {(record.totalPrice / 100).toFixed(2)}<br/>
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => new Date(b.timestamp.updatedAt) - new Date(a.timestamp.updatedAt),
        multiple: 1
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      key: 'date',
      render: (record) => (
        <React.Fragment>
          {moment(record.timestamp.updatedAt).format('DD/MM/YYYY HH:mm')}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => new Date(b.timestamp.updatedAt) - new Date(a.timestamp.updatedAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('user')),
      key: 'user',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const accountSession = accounts.find(account => account._id === record.account);
        return (
          <React.Fragment>
            {accountSession.name} {accountSession.surname}<br/>
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => accounts.find(account => account._id === a.account).name.localeCompare(accounts.find(account => account._id === b.account).name)
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('paid')),
      key: 'totalPrice',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        return (
          <React.Fragment>
            {(record.totalPrice / 100).toFixed(2)}<br/>
          </React.Fragment>
        );
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  const renderMonthPanel = (month) => {
    return (
      <Panel header={<b>{capitalize(month)}</b>} key={month} extra={transactionsByMonth[month].length + ' ' + capitalize(t('transactions'))}>
        <Table columns={columns} dataSource={transactionsByMonth[month]} />
      </Panel>
    );
  };

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b> {capitalize(t('transactions'))}</b>
        </div>
        <Collapse
          // defaultActiveKey={['1']}
          expandIconPosition={'left'}
          style={{ marginTop: '10px' }}
        >
          {transactionsByMonth && Object.keys(transactionsByMonth).map(renderMonthPanel)}
        </Collapse>
        {/* <Table columns={columns} dataSource={transactions} onChange={onChange} onRow={(record, rowIndex) => { */}
        {/*  return { */}
        {/*    onClick: event => {}, // click row */}
        {/*    onDoubleClick: event => {}, // double click row */}
        {/*    onContextMenu: event => {}, // right button click row */}
        {/*    onMouseEnter: event => {}, // mouse enter row */}
        {/*    onMouseLeave: event => {} // mouse leave row */}
        {/*  }; */}
        {/* }} rowClassName={'row-pointer'} */}
        {/* /> */}
        <br />
      </>
    );
  };
};

export default Transactions;
