import React, { useEffect, useState } from 'react';
import { Button, Radio, Form } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
import TextArea from 'antd/es/input/TextArea';
import Spinner from '../navigation/Spinner';

const SettingsModal = (props) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState();

  useEffect(() => {
    setSettings(props.settings);
  }, [props.settings]);

  return (
    <>
      <Modal title={capitalizeAll(t('settings'))} visible={props.showSettingsModal}
        onCancel={() => {
          props.handleCloseSettingsModal();
        }}

        onPan={(p) => { }}
        centered
        footer={[
          <Button key="back" onClick={() => {
            props.handleCloseSettingsModal();
          }}> {capitalize(t('cancel'))} </Button>,
          <Button key="OK" type="primary" onClick={() => {
            props.handleSaveSettings(settings);
          }}> {capitalize(t('save'))} </Button>
        ]}
      >
        <>
          <div className={`text-color${props.center.theme}`} style={{ textAlign: 'left' }}>
            {capitalize(t('settings'))}:
          </div>
          <Form.Item
            name="settings"
            rules={[{
              required: true,
              message: capitalize(t('settings')) + ' ' + t('isRequired')
            }]}>
            <TextArea
              addonBefore={capitalize(t('settings'))}
              rows={4}
              name="settings"
              onChange={(e) => {
                setSettings(e.target.value);
              }}
              defaultValue={settings || ''}
              placeholder={capitalize(t('settings'))}
            />
          </Form.Item>
        </>
      </Modal>
    </>

  );
};

export default SettingsModal;
