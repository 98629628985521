import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllStrengths = (token) => {
  return methodGet('strength/all', '', token);
};

export const getStrengthById = (id, token) => {
  return methodGet('strength/' + id, '', token);
};

export const getStrengthByQuery = (req, token) => {
  return methodGet('strength', req, token);
};

export const createStrength = (data, token) => {
  return methodPost('strength/create', data, token);
};

export const patchStrength = (id, data, token) => {
  return methodPatch('strength/update?id=' + id, data, token);
};
