import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import { getAccountByQuery } from '../../../services/user.service';
import '../../../components/layout/layout.css';
import { getAllCenters } from '../../../services/center.service';
import { getIdToken } from 'firebase/auth';

const AdminTrainers = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [trainers, setTrainers] = useState(null);
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user.account) {
      loadCenters();
    }
  }, [user.account]);

  useEffect(() => {
    if (centers.length) {
      loadTrainers();
    }
  }, [centers]);

  const loadTrainers = async () => {
    const { status, data } = await getAccountByQuery({ roles: 'trainer' }, user.token);
    if (status === 200) {
      data.data.forEach(trainer => {
        trainer.key = trainer._id;
        const center = centers.find(center => center._id === trainer.center);
        trainer.centerName = center.name;
      });
      setTrainers(data.data);
      setLoading(false);
    }
  };

  const loadCenters = async () => {
    const { status, data } = await getAllCenters(user.token);
    if (status === 200) {
      setCenters(data.data);
    }
  };

  const columns = [
    {
      title: 'Trainers',
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name} {record.surname}
          <br />
          {record.email}
          <br />
          {record.phone}
          <br />
          {record.nif}
          <br />
          {record.centerName}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 6
      },
      responsive: ['xs']
    },
    {
      title: 'Name',
      dataIndex: ['name', 'surname'],
      key: '_id',
      render: (text, record) => record.name + ' ' + record.surname,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a.phone.localeCompare(b.phone),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: 'Nif',
      dataIndex: 'nif',
      key: 'nif',
      sorter: {
        compare: (a, b) => a.nif.localeCompare(b.nif),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: 'Center',
      dataIndex: 'centerName',
      key: 'centerName',
      sorter: {
        compare: (a, b) => a.centerName.localeCompare(b.centerName),
        multiple: 5
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          Create new trainer
        </Button>
        <Table columns={columns} dataSource={trainers} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { },
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  };
};

export default AdminTrainers;
