import React from 'react';
import { Layout, Image } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const ExternalLayout = (props) => {
  const { Header, Content, Footer } = Layout;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        {!isTabletOrMobile &&
        <>
          <Header style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#2f3840' }}>
            <Image src={'https://sei-platform-test.s3.eu-west-3.amazonaws.com/assets/sei-logo-blanco.png'} alt="logo" style={{ height: '50px', width: '125px', marginLeft: 10 }} preview={false} />
          </Header>
        </>}
        {isTabletOrMobile &&
        <>
          <Header style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#2f3840' }}>
            <Image src={'https://sei-platform-test.s3.eu-west-3.amazonaws.com/assets/sei-logo-blanco.png'} alt="logo" style={{ height: '50px', width: '124px', marginLeft: 10 }} />
          </Header>
        </>
        }
        <Content style={{ margin: '24px 6px 0' }}>
          <div className="site-layout-background" style={{ padding: 14 }}>
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </>
  );
};

export default ExternalLayout;
