import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import Spinner from '../../../components/navigation/Spinner';
import DashboardAdminHome from './AdminHome';
import DashboardOrganizerHome from './OrganizerHome';
import DashboardTrainerHome from './TrainerHome';

const DashboardHome = (props) => {
  const { user } = useContext(Auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user.account) {
      setLoading(false);
    }
  }, [user.account]);

  if (loading) {
    return <Spinner/>;
  }

  if (user.account.roles === 'admin') {
    return <DashboardAdminHome/>;
  } else if (user.account.roles === 'organizer') {
    return <DashboardOrganizerHome/>;
  } else if (user.account.roles === 'trainer') {
    return <DashboardTrainerHome/>;
  }

  return (
    <>
      Sin contenido temporalmente.
    </>
  );
};

export default DashboardHome;
