import React from 'react';
import _ from 'lodash';

function Separator (props) {
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        transform: `rotate(${props.turns}turn) rotate(-90deg)`
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators (props) {
  const turns = 1 / props.count;
  //
  //
  return _.range((props.count / 2) - 1).map(index => (
    <Separator key={index} turns={((index + 1) * turns)} style={props.style} />
  ));
}

export default RadialSeparators;
