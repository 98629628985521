import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getCenterByQuery } from '../../../services/center.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import { getCalendartemplateByQuery } from '../../../services/calendartemplate.service';
import CreateCalendarTemplate from './CreateCalendarTemplate';

const CalendarTemplates = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [templates, setTemplates] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadTemplates = async (centerId) => {
    const { status, data } = await getCalendartemplateByQuery({ center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(template => {
        template.key = template._id;
      });
      setTemplates(data.data);
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      loadTemplates(data.data[0]._id);
    }
  };

  const columns = [
    {
      title: capitalize(t('templates')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
          <br />
          {record.type === 'day' ? capitalize(t('day')) : capitalize(t('week'))}
          <br />
          {record.bookings.length} {t('bookings')}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('type')),
      key: 'type',
      render: (text, record) => {
        if (record.type === 'day') {
          return capitalize(t('day'));
        } else if (record.type === 'week') {
          return capitalize(t('week'));
        }
      },
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('slots')),
      key: '_id',
      render: (text, record) => record.bookings.length + ' ' + t('slots'),
      sorter: {
        compare: (a, b) => a.bookings.localeCompare(b.bookings),
        multiple: 2
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => {
          navigate('add');
          navigate(0);
        }} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewTemplate'))}
        </Button>
        <Table columns={columns} dataSource={templates} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              navigate('edit/' + record._id);
              navigate(0);
            }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  }
};

export default CalendarTemplates;
