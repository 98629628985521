import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Popconfirm, message } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getAuth, createUserWithEmailAndPassword, signOut, getIdToken } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { createAccount, getAccountById, patchAccount, resetPassword } from '../../../services/user.service';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { signUpApp } from '../../../services/firebase/firebaseConfig';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateUser = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [error, seterror] = useState('');
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      if (params.id) {
        await loadUser(params.id, user.token);
      }
      setCenter(data.data[0]);
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      if (params.id) {
        await loadUser(params.id, user.token);
      }
      setCenter(data.data);
      setLoading(false);
    }
  };

  const loadUser = async (id, token) => {
    const { status, data } = await getAccountById(id, token);
    if (status === 200) {
      setEditUser(data.data);
      fillForm(data.data);
    }
  };

  const fillForm = (user) => {
    form.setFieldsValue({
      email: user.email,
      name: user.name,
      surname: user.surname,
      phone: user.phone,
      nif: user.nif,
      birthdate: user.birthdate,
      credits: user.credits
    });
  };

  const deactivateUser = async () => {
    if (params.id) {
      await patchAccount(params.id, { isActive: false }, user.token);
      message.success(capitalizeAll(t('userDeleted')));
      navigate('/dashboard/users');
    }
  };

  const handleResetPassword = async () => {
    if (params.id) {
      await resetPassword(params.id, user.token);
      message.success(capitalizeAll(t('passwordReset')));
    }
  };

  const createNewAccount = async (data) => {
    const formData = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      firebaseUid: null,
      phone: data.phone,
      roles: 'user',
      nif: data.nif,
      birthdate: data.birthdate,
      credits: data.credits,
      center: center._id
    };
    let response;
    if (params.id) response = await patchAccount(params.id, formData, user.token);
    else {
      formData.password = data.passwd;
      response = await createAccount(formData, user.token);
    }
    if (response.status === 200) {
      navigate('/dashboard/users');
    } else {
      seterror(response.data.message);
    }
  };

  const correctClave = async e => {
    await createNewAccount(e);
    // if (params.id) {
    //   await createNewAccount(e, editUser.firebaseUid, user.token);
    // } else {
    //   const { email, passwd } = e;
    //   const auth = getAuth(signUpApp);
    //   await createUserWithEmailAndPassword(auth, email, passwd)
    //     .then(result => {
    //       createNewAccount(e, result.user.uid);
    //     })
    //     .catch(error => {
    //       seterror(error.message);
    //     });
    //   await signOut(auth);
    // }
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>

          {editUser ? <h1>{capitalizeAll(t('editUser'))}</h1> : <h1>{capitalizeAll(t('createUser'))}</h1>}
        </Form.Item>
        {error ? <Form.Item>{error}</Form.Item> : null}
        <Form.Item
          name="email"
          rules={[{
            required: true,
            message: capitalize(t('email')) + ' ' + t('isRequired')
          }]}>
          {editUser
            ? (
              <Input
                addonBefore={capitalize(t('email'))}
                name="email"
                defaultValue={editUser ? editUser.email : ''}
                placeholder={capitalize(t('email'))}
              />
            )
            : (
              <Input
                addonBefore={capitalize(t('email'))}
                name="email"
                placeholder={capitalize(t('email'))}
              />
            )}
        </Form.Item>
        {editUser
          ? null
          : (
            <Form.Item name="passwd"
              rules={[{
                required: true,
                message: capitalize(t('password')) + ' ' + t('isRequired')
              }]}>

              {editUser
                ? (
                  <Input
                    addonBefore={capitalize(t('password'))}
                    name="passwd"
                    type="password"
                    placeholder={capitalize(t('password'))}
                    disabled
                  />
                )
                : (
                  <Input
                    addonBefore={capitalize(t('password'))}
                    name="passwd"
                    type="password"
                    placeholder={capitalize(t('password'))}
                  />
                )}
            </Form.Item>)}
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={editUser ? editUser.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <Form.Item
          name="surname"
          rules={[{
            required: true,
            message: capitalize(t('surname')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('surname'))}
            name="surname"
            defaultValue={editUser ? editUser.surname : ''}
            placeholder={capitalize(t('surname'))}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[{
            required: true,
            message: capitalize(t('phone')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('phone'))}
            name="phone"
            defaultValue={editUser ? editUser.phone : ''}
            placeholder={capitalize(t('phone'))}
          />
        </Form.Item>
        <Form.Item
          name="nif"
          rules={[{
            required: true,
            message: t('nif').toUpperCase() + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={t('nif').toUpperCase()}
            name="nif"
            defaultValue={editUser ? editUser.nif : ''}
            placeholder={t('nif').toUpperCase()}
          />
        </Form.Item>
        <Form.Item
          name="birthdate"
          rules={[{
            required: true,
            message: capitalize(t('birthdate')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('birthdate'))}
            name="birthdate"
            defaultValue={editUser ? editUser.birthdate : ''}
            placeholder={capitalize(t('birthdate'))}
            type="date"
          />
        </Form.Item>
        <Form.Item
          name="credits">
          <Input
            addonBefore={capitalize(t('credits'))}
            name="credits"
            defaultValue={editUser ? editUser.credits : ''}
            placeholder={capitalize(t('credits'))}
            type="number"
            onWheel={(e) => e.target.blur()}
          />
        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {editUser && (
            <>
              <Popconfirm
                title={capitalize(t('confirmDeleteUser'))}
                onConfirm={deactivateUser}
                // onCancel={cancel}
                okText={capitalize(t('yes'))}
                cancelText={capitalize(t('no'))}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    className="delete-form-button"
                    style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                  >
                    <DeleteOutlined />
                  </Button>
                </Form.Item>
              </Popconfirm>
              <Popconfirm
                title={capitalize(t('confirmResetPassword'))}
                onConfirm={handleResetPassword}
                // onCancel={cancel}
                okText={capitalize(t('yes'))}
                cancelText={capitalize(t('no'))}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    className="delete-form-button"
                    style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                  >
                    {capitalize(t('resetPassword'))}
                  </Button>
                </Form.Item>
              </Popconfirm>
            </>

          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {editUser ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createUser'))}
            </Button>
          </Form.Item>
        </div>

      </Form>
    </div>
  );
};
export default CreateUser;
