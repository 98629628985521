import React, { useContext, useEffect } from 'react';
import { Button, Layout, Image } from 'antd';
import TopMenu from '../navigation/TopMenu';
// import './layout.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as Auth } from '../../services/auth/AuthContext';
import { app } from '../../services/firebase/firebaseConfig';
import { getAuth, signOut } from 'firebase/auth';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import { MenuContext } from '@elekajota/react-flexible-sliding-menu-scroll';
import logoSEI from '../../assets/images/logoSEI.png';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/utils';

const MainLayout = (props) => {
  const { Header, Content, Footer } = Layout;
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signout } = useContext(Auth);
  const { toggleMenu } = useContext(MenuContext);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation();

  useEffect(() => {
    if (user.token === null) {
      navigate('/login');
    }
  }, [user.token]);

  const accountSignOut = async () => {
    await signout();
    // const auth = getAuth(app);
    // await signOut(auth).then((response) => {
    // });
  };
  return (
    <>

      <Layout>

        {!isTabletOrMobile &&
        <>
          <Header style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', backgroundColor: '#2f3840' }}>
            {/* <Image src={'https://sei-platform-test.s3.eu-west-3.amazonaws.com/assets/sei-logo-blanco.png'} alt="logo" style={{ height: '50px', width: '125px', marginLeft: 10 }} preview={false} /> */}
            <Button onClick={accountSignOut} style={{ height: '100%', border: 0, display: 'flex', alignItems: 'center' }} icon={<LogoutOutlined style={{ fontSize: 24 }} />} ghost >{capitalize(t('logout'))}</Button>
          </Header>
          <Header style={{ padding: 0 }}>
            <TopMenu location={location} signOut={accountSignOut} mode={'horizontal'}/>
          </Header>
        </>}
        {isTabletOrMobile &&
        <>
          <Header style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', backgroundColor: '#2f3840' }}>
            {/* <Image src={'https://sei-platform-test.s3.eu-west-3.amazonaws.com/assets/sei-logo-blanco.png'} alt="logo" style={{ height: '50px', width: '124px', marginLeft: 10 }} /> */}
            <Button onClick={toggleMenu} style={{ height: '100%', border: 0, display: 'flex', alignItems: 'center' }} ghost ><MenuOutlined style={{ fontSize: 32, margin: '8px' }} /></Button>
          </Header>
        </>
        }
        <Content
          className="mobile-layout">
          <div className="site-layout-background">
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </>
  );
};

export default MainLayout;
