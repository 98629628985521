import React, { useEffect, useState } from 'react';
import Modal from 'antd/es/modal/Modal';
import { Button, Col, Form, Popconfirm, Row, Select, Input, Switch } from 'antd';
import { getIdToken } from 'firebase/auth';
import {
  deleteBooking,
  getBookingByQuery,
  patchBooking
} from '../../services/booking.service';
import Spinner from '../navigation/Spinner';
import { getAccountById, getAccountByQuery, patchAccount } from '../../services/user.service';
import { getBooksessionByQuery } from '../../services/booksession.service';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
import { CloseCircleOutlined } from '@ant-design/icons';
import { createSubscription } from '../../services/subscription.service';

const BookingEdit = (props) => {
  const [selectedTrainer, setSelectedTrainer] = useState('null');
  const [selectedSlots, setSelectedSlots] = useState(1);
  const [booksessions, setBooksessions] = useState(null);
  const [selectedBooksession, setSelectedBooksession] = useState('null');
  const [selectedName, setSelectedName] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const { TextArea } = Input;
  const [queue, setQueue] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountsOptions, setAccountsOptions] = useState([]);
  const [accountsInfo, setAccountsInfo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('null');
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [removeUser, setRemoveUser] = useState(false);
  const [message, setMessage] = useState('');
  const [isSubscription, setIsSubscription] = useState(false);
  const [subscriptionWeeks, setSubscriptionWeeks] = useState(1);
  const { Option } = Select;
  const { t } = useTranslation();
  const filteredOptions = accountsInfo.filter((o) => !selectedGroup.includes(o));

  const filteredOptionsArr = filteredOptions.map((item) => {
    let disabled = false;
    if (!item.credits) {
      disabled = true;
    }
    // eslint-disable-next-line array-callback-return
    selectedGroup.map((user) => {
      if (user === item._id) {
        disabled = false;
      }
    });
    return {
      value: item._id,
      label: item.name + ' ' + item.surname,
      disabled: disabled
    };
  });
  filteredOptionsArr.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(async () => {
    const trainer = props.trainers.find(trainer => trainer.email === props.edit.title.split(' - ')[props.edit.title.split(' - ').length - 1]);
    setSelectedTrainer(trainer.name + ' ' + trainer.surname + ' - ' + trainer.email + ' - ' + trainer._id);
    setSelectedSlots(props.booking.slots);
    setSelectedName(props.booking.name);
    setSelectedDescription(props.booking.description);
    await loadBookSessions();
    setLoading(false);
  }, [props.edit]);

  useEffect(() => {
    if (selectedBooksession && selectedBooksession !== 'null') {
      const booksession = booksessions.find(booksession => booksession._id === selectedBooksession);
      const trainer = props.trainers.find(trainer => trainer._id === booksession.trainer);
      setSelectedName(booksession.name);
      setSelectedDescription(booksession.description);
      setSelectedSlots(booksession.slots);
      setSelectedTrainer(trainer.name + ' ' + trainer.surname + ' - ' + trainer.email + ' - ' + trainer._id);
    }
  }, [selectedBooksession]);

  const checkFields = () => {
    if (selectedName === '') {
      setMessage('Es necesario un nombre');
      return false;
    }
    if (selectedDescription === '') {
      setMessage('Es necesaria una descripción');
      return false;
    }
    if (selectedTrainer === 'null') {
      setMessage('Es necesario un entrenador');
      return false;
    }
    return true;
  };

  const loadBookSessions = async () => {
    const { status, data } = await getBooksessionByQuery({ center: props.center._id, isActive: true }, props.user.token);
    if (status === 200) {
      setBooksessions(data.data);
      setLoading(false);
    }
  };

  useEffect(async () => {
    const queueArr = [];
    await loadAccounts(props.center._id);
    for (const acc of props.booking.queue) {
      const { user } = await loadQueueAccounts(acc);
      queueArr.push(user.data);
    }
    if (props.booking.group.length > 0) {
      const groupArr = [...props.booking.group];
      setSelectedGroup(groupArr);
    }

    setQueue(queueArr);
  }, [props.booking]);

  const editBooking = async (payload) => {
    if (selectedSlots > 1) {
      const usersQuit = [];
      const usersJoin = [];
      props.booking.group.forEach((user) => {
        if (!selectedGroup.includes(user)) {
          usersQuit.push(user);
        }
      });
      selectedGroup.forEach((user) => {
        if (!props.booking.group.includes(user)) {
          usersJoin.push(user);
        }
      });
      if (usersQuit.length > 0) {
        for await (const user of usersQuit) {
          await modifyCredit(user, 'add');
        }
      }
      if (usersJoin.length > 0) {
        for await (const user of usersJoin) {
          await modifyCredit(user, 'remove');
        }
      }
    }
    if (selectedAccount !== 'null') {
      payload.account = selectedAccount;
      modifyCredit(selectedAccount, 'remove');
    } else {
      if (removeUser) {
        payload.account = null;
      }
    }
    if (removeUser) {
      modifyCredit(props.booking.account._id, 'add');
    }
    const { status, data } = await patchBooking(props.edit.id, payload, props.user.token);
    if (status === 200) {
      if (isSubscription) {
        const subscriptionPayload = {
          account: selectedAccount,
          weeks: subscriptionWeeks,
          center: payload.center,
          trainer: payload.trainer,
          startDate: payload.start,
          endDate: payload.end,
          name: payload.name
        };
        const { status, subData } = await createSubscription(subscriptionPayload, props.user.token);
        if (status === 200) {
        }
      }
      return data._id;
    }
    return null;
  };

  const modifyCredit = async (id, action) => {
    const acc = accountsInfo.find(account => account._id === id);
    let newCredits = acc.credits;
    if (action === 'add') {
      newCredits += 1;
    } else {
      if (!isSubscription) {
        newCredits -= 1;
      }
    }
    await patchAccount(id, { credits: newCredits }, props.user.token);
  };

  const deleteBook = async () => {
    const { status, data } = await deleteBooking(props.edit.id, props.user.token);

    if (status === 200) {
      return data._id;
    }
    return null;
  };

  const loadQueueAccounts = async (id) => {
    const { status, data } = await getAccountById(id, props.user.token);

    if (status === 200) {
      return data;
    }
    return null;
  };

  const loadAccounts = async (centerId) => {
    const { status, data } = await getAccountByQuery({ roles: 'user', isActive: true, center: props.center._id }, props.user.token);
    if (status === 200) {
      const accountsWithCredits = data.data.filter(account => account.credits > 0);
      const sortedAccounts = accountsWithCredits.sort((a, b) => a.name.localeCompare(b.name));
      const accountsOptions = sortedAccounts.map((account) => {
        return {
          value: account._id,
          label: account.name + ' ' + account.surname
        };
      });
      accountsOptions.unshift({ value: 'null', label: capitalize(t('user')) });
      setAccountsOptions(accountsOptions);
      setAccounts(accountsWithCredits);
      setAccountsInfo(data.data);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        title={capitalizeAll(t('editBookingData'))}
        visible={props.bookingDataVisible}
        onOk={() => {
          props.setBookingDataVisible(false);
        }}
        onCancel={() => {
          props.setBookingDataVisible(false);
          // setSelectedTrainer('null');
          setSelectedAccount('null');
          // setSelectedBooksession('null');
          // setSelectedName('');
          // setSelectedDescription('');
          // setSelectedSlots(1);
          setSelectedGroup([]);
          setRemoveUser(false);
          setIsSubscription(false);
          setSubscriptionWeeks(1);
          // setMessage('');
        }}

        onPan={(p) => { }}
        centered
        // width={'100%'}
        footer={[
          <Button key="DELETE" type="danger" onClick={async () => {
            await deleteBook();
            props.handleDeleteBookSlot();
            props.setBookingDataVisible(false);
            setSelectedTrainer('null');
          }}>
            {t('delete').toUpperCase()}
          </Button>,
          <Button key="OK" onClick={async () => {
            try {
              if (!checkFields()) {
                return;
              }
              const newBook = await editBooking({
                center: props.center._id,
                trainer: selectedTrainer.split(' - ')[2],
                name: selectedName,
                description: selectedDescription,
                isGroup: selectedSlots > 1,
                group: selectedGroup,
                slots: selectedSlots,
                start: new Date(props.edit.start),
                end: new Date(props.edit.end)
              });
              props.handleEditBookSlot(newBook, selectedTrainer, props.edit.start, props.edit.end);
              props.setBookingDataVisible(false);
              setSelectedTrainer('null');
              setSelectedAccount('null');
              setSelectedBooksession('null');
              setSelectedName('');
              setSelectedDescription('');
              setSelectedSlots(1);
              setSelectedGroup([]);
              setRemoveUser(false);
              setIsSubscription(false);
              setSubscriptionWeeks(1);
              setMessage('');
            } catch (error) {
              setSelectedTrainer('null');
              setSelectedAccount('null');
              setSelectedBooksession('null');
              setSelectedName('');
              setSelectedDescription('');
              setSelectedSlots(1);
              setSelectedGroup([]);
              setRemoveUser(false);
              setIsSubscription(false);
              setSubscriptionWeeks(1);
              setMessage('');
            }
          }}>
                    OK
          </Button>
        ]}
      >
        <Row>
          {capitalize(t('center'))}: {props.center.name} <br/>
        </Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <Row>
              {capitalize(t('booksession'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <Select value={selectedBooksession}
                style={{ width: '100%' }}
                onChange={setSelectedBooksession}>
                <Option value="null">{capitalize(t('booksession'))}</Option>
                {booksessions.map((booksession, key) => {
                  const trainer = props.trainers.find((t) => t._id === booksession.trainer);
                  return (
                    <Option key={key} value={booksession._id}>{booksession.name + ' - ' + trainer.name + ' ' + trainer.surname}</Option>
                  );
                })}
              </Select>
            </Row>
            <Row>
              {capitalize(t('name'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <Input type="text"
                style={{ width: '100%' }}
                value={selectedName}
                onChange={(e) => {
                  setSelectedName(e.target.value);
                }}
              />
            </Row>
            <Row>
              {capitalize(t('description'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <TextArea type="text"
                style={{ width: '100%' }}
                value={selectedDescription}
                onChange={(e) => {
                  setSelectedDescription(e.target.value);
                }}
              />
            </Row>
            <Row>
              {capitalize(t('userSlots'))}:
            </Row>
            <Row>
              <Select value={selectedSlots}
                style={{ width: '100%' }}
                onChange={setSelectedSlots}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
                <Option value={7}>7</Option>
                <Option value={8}>8</Option>
                <Option value={9}>9</Option>
                <Option value={10}>10</Option>
                <Option value={11}>11</Option>
                <Option value={12}>12</Option>
                <Option value={13}>13</Option>
                <Option value={14}>14</Option>
                <Option value={15}>15</Option>
                <Option value={16}>16</Option>
                <Option value={17}>17</Option>
                <Option value={18}>18</Option>
                <Option value={19}>19</Option>
                <Option value={20}>20</Option>
                <Option value={21}>21</Option>
                <Option value={22}>22</Option>
                <Option value={23}>23</Option>
                <Option value={24}>24</Option>
                <Option value={25}>25</Option>
                <Option value={26}>26</Option>
                <Option value={27}>27</Option>
                <Option value={28}>28</Option>
                <Option value={29}>29</Option>
                <Option value={30}>30</Option>
                <Option value={31}>31</Option>
                <Option value={32}>32</Option>
                <Option value={33}>33</Option>
                <Option value={34}>34</Option>
                <Option value={35}>35</Option>
                <Option value={36}>36</Option>
                <Option value={37}>37</Option>
                <Option value={38}>38</Option>
                <Option value={39}>39</Option>
                <Option value={40}>40</Option>
                <Option value={41}>41</Option>
                <Option value={42}>42</Option>
                <Option value={43}>43</Option>
                <Option value={44}>44</Option>
                <Option value={45}>45</Option>
                <Option value={46}>46</Option>
                <Option value={47}>47</Option>
                <Option value={48}>48</Option>
                <Option value={49}>49</Option>
                <Option value={50}>50</Option>
              </Select>
            </Row>
            <Row>
              {capitalize(t('trainer'))}:
            </Row>
            <Row style={{ width: '100%' }}>
              <Select value={selectedTrainer}
                style={{ width: '100%' }}
                onChange={setSelectedTrainer}>
                <Option value="null">{capitalize(t('trainer'))}</Option>
                {props.trainers.map((trn, key) => {
                  return (
                    <Option key={key} value={trn.name + ' ' + trn.surname + ' - ' + trn.email + ' - ' + trn._id}>{trn.name + ' ' + trn.surname}</Option>
                  );
                })}
              </Select>
            </Row>
          </Col>
        </Row>
        {/* eslint-disable-next-line multiline-ternary */}
        {props.booking.isGroup ? (
          <>
            {props.booking.group.length > 0
              ? (
                <>
                  <Row>
                    {capitalize(t('users'))}:
                  </Row>
                  {selectedGroup.length > selectedSlots && (
                    <Row style={{ color: 'red' }}>
                      {t('groupExceededSlots')}
                    </Row>
                  )}
                  <Select
                    mode="multiple"
                    placeholder={capitalizeAll(t('selectUsers'))}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    value={selectedGroup}
                    onChange={setSelectedGroup}
                    options={filteredOptionsArr}
                    style={{
                      width: '100%'
                    }}
                  >
                  </Select>
                </>
              )
              : (
                <>
                  <Row>
                    {capitalize(t('users'))}:
                  </Row>
                  {selectedGroup.length > selectedSlots && (
                    <Row style={{ color: 'red' }}>
                      {t('groupExceededSlots')}
                    </Row>
                  )}
                  <Select
                    mode="multiple"
                    placeholder={capitalizeAll(t('selectUsers'))}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    value={selectedGroup}
                    options={filteredOptionsArr}
                    onChange={setSelectedGroup}
                    style={{
                      width: '100%'
                    }}
                  >

                  </Select>
                </>
              )}
          </>
        ) : props.booking.account && props.booking.account !== 'undefined' && !removeUser
          ? (
            <Row style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                {capitalize(t('user'))}: {props.booking.account.name + ' ' + props.booking.account.surname}<br/>
              </div>
              <div>
                <Popconfirm
                  title={capitalize(t('removeUserBook'))}
                  onConfirm={() => { setRemoveUser(true); }}
                  // onCancel={cancel}
                  okText={capitalize(t('yes'))}
                  cancelText={capitalize(t('no'))}
                  style={{ width: '100%', alignItems: 'center', height: '100%' }}
                >
                  <Button
                    type="secondary"
                    danger
                    className="delete-form-button"
                    style={{ border: 0, fontSize: '1.2rem', height: '100%' }}
                    // onClick={deactivateUser}
                  >
                    <CloseCircleOutlined />
                  </Button>
                </Popconfirm>
              </div>

            </Row>
          )
          : (
            <>
              <Row>
                {capitalize(t('user'))}:
              </Row>
              <Row style={{ width: '100%' }}>
                <Select value={selectedAccount}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  style={{ width: '100%' }}
                  options={accountsOptions}
                  onChange={setSelectedAccount}>
                  {/* <Option value="null">{capitalize(t('user'))}</Option> */}
                  {/* {accounts.map((acc, key) => {
                    return (
                      <Option key={key} value={acc._id}>{acc.name + ' ' + acc.surname}</Option>
                    );
                  })} */}
                </Select>
              </Row>
            </>

          )}
        {queue.length > 0 && (
          <Row>
            <Col>
              {capitalize(t('queue'))}:
              {queue.map((q, key) => {
                return (
                  <Row key={key}>
                    {(key + 1) + ' - ' + q.name + ' ' + q.surname + ' [' + q.email + ']'}
                  </Row>
                );
              })}
            </Col>
          </Row>
        )}
        {selectedAccount !== 'null' && (
          <>
            <Row style={{ marginTop: 12 }}>
              <Col>
                {accounts.find(acc => acc._id === selectedAccount).credits} {capitalize(t('credits')) + ' ' + capitalize(t('availables'))}
              </Col>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'space-between', marginTop: 12 }}>
              <Col>
                <Switch style={{ marginRight: 8 }} checked={isSubscription} onChange={(e) => setIsSubscription(e)} />
                {capitalize(t('subscription'))}
              </Col>
              <Col>
                <Input style={{ width: 60, marginRight: 8 }}
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  min={'1'}
                  max={accounts.find(acc => acc._id === selectedAccount).credits}
                  value={subscriptionWeeks}
                  disabled={!isSubscription}
                  onChange={async (e) => {
                    // const data = await getBookingByQuery({ center: center._id }, props.user.token);
                    setSubscriptionWeeks(e.target.value);
                  }} />
                {capitalize(t('weeks'))}
              </Col>
            </Row>
          </>
        )}

        <Row>
          {capitalize(t('startDate'))}: {props.edit.start.toLocaleString()}
        </Row>
        <Row>
          {capitalize(t('endDate'))}: {props.edit.end.toLocaleString()}
        </Row>
        <Row>
          <span style={{ color: 'red' }}>{message}</span>
        </Row>
      </Modal>
    </>
  );
};

export default BookingEdit;
