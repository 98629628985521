import React, { useEffect, useState } from 'react';
import { Button, Radio, Table, Tag } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
import { getProgramByQueryWithExercises } from '../../services/program.service';
import { getIdToken } from 'firebase/auth';
import { getProgramSessionWithExerciseSessions } from '../../services/programsession.service';
import moment from 'moment';
import 'moment/locale/es';
import { ArrowDownOutlined, ArrowUpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import Spinner from '../navigation/Spinner';

const ProgressControl = (props) => {
  const { t } = useTranslation();
  const [program, setProgram] = useState(null);
  const [programSessions, setProgramSessions] = useState([]);
  const [programGroups, setProgramGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [noProgress, setNoProgress] = useState(false);
  // const [exercises, setExercises] = useState(null);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadProgram();
  }, [props.program]);

  useEffect(() => {
    if (programGroups.length > 0 && !noProgress) {
      fillColumns(programGroups[selectedGroup]);
    }
  }, [programGroups, selectedGroup]);

  const loadProgram = async () => {
    const { status, data } = await getProgramByQueryWithExercises({ _id: props.program });
    if (status === 200) {
      await loadProgramSessions();
      // await loadExercises();
      setProgram(data.data[0]);
      setLoading(false);
    }
  };

  const loadProgramSessions = async () => {
    const { status, data } = await getProgramSessionWithExerciseSessions({ program: props.program });
    if (status === 200) {
      if (!data.data.length > 0) {
        setNoProgress(true);
      } else {
        setNoProgress(false);
        setProgramSessions(data.data);
        setProgramGroups(groupByN(8, data.data));
        setSelectedGroup(groupByN(8, data.data).length - 1);
      }
    }
  };

  const handleChangeGroup = (group) => {
    switch (group) {
    case 0:
      if (selectedGroup > 0) {
        setSelectedGroup(selectedGroup - 1);
      }
      break;
    case 1:
      if (selectedGroup < programGroups.length - 1) {
        setSelectedGroup(selectedGroup + 1);
      }
      break;
    default:
      setSelectedGroup(selectedGroup);
      break;
    }
  };

  const groupByN = (n, data) => {
    const result = [];
    let j = 1;
    for (let i = 0; i < data.length; i += n) {
      let from = data.length - (n * j);
      const to = data.length - i;
      if (from < 0) {
        from = 0;
      }
      result.push(data.slice(from, to));
      j++;
    }
    return result.reverse();
  };

  const fillColumns = (programSessions) => {
    const columns = [{
      title: capitalize(t('exercise')),
      key: '_id',
      render: (record) => {
        return {
          props: {
            style: {
              // backgroundColor: pair ? '#d5bcbc' : '#c4d4da',
              // textAlign: 'center',
              // fontWeight: 'bold',
              width: '350px'
              // borderRight: '1px solid #fff'
            }
          },
          children: (
            <React.Fragment>
              <b>{record.name}</b>
            </React.Fragment>
          )
        };
      }
    }];
    let pair = 0;
    programSessions?.forEach(ps => {
      columns.push(createColumn(ps, pair));
      if (pair === 0) {
        pair = 1;
      } else {
        pair = 0;
      }
    });
    setColumns(columns);
  };

  const createColumn = (programSession, pair) => {
    return {
      title: moment(programSession.timestamp.createdAt).format('L'),
      key: programSession._id,
      render: (record) => {
        const exerciseSession = programSession.exercises.find(es => es.exercise === record._id);
        //
        //
        if (exerciseSession) {
          return {
            props: {
              style: {
                backgroundColor: pair ? '#d5bcbc' : '#c4d4da',
                textAlign: 'center',
                fontWeight: 'bold',
                width: '150px',
                borderRight: '1px solid #fff'
              }
            },
            children: (
              <React.Fragment>
                <div style={{ fontSize: 12 }}>
                  {exerciseSession.type === 'isometric'
                    ? (
                      <>
                        {moment(exerciseSession.lastSet).format('mm:ss')}
                        <br/>
                        {exerciseSession.sets.length} {capitalize(t('sets'))}
                        <br/>
                      </>
                    )
                    : (
                      <>
                        {exerciseSession.weight} {record.weightMeasure ? record.weightMeasure : 'p.'}
                        <br/>
                        {exerciseSession.reps} {exerciseSession.incomplete > 0 && ' + ' + exerciseSession.incomplete} Reps.
                        <br/>
                      </>
                    )}

                  {moment(exerciseSession.time).format('mm:ss')}
                  <br/>
                  {exerciseSession.AP && <Tag color="default">AP</Tag>}
                  {exerciseSession.MT && <Tag color="default">MT</Tag>}
                  {exerciseSession.NF && <Tag color="default">NF</Tag>}
                  {exerciseSession.NS && <Tag color="default">NS</Tag>}
                  {exerciseSession.passTul === 2 && <Tag color="success"><ArrowUpOutlined/></Tag>}
                  {exerciseSession.passTul === 1 && <Tag color="red"><ArrowDownOutlined/></Tag>}
                </div>

              </React.Fragment>
            )
          };
        } else {
          return {
            props: {
              style: {
                backgroundColor: pair ? '#d5bcbc' : '#c4d4da',
                textAlign: 'center',
                fontWeight: 'bold',
                width: '150px',
                borderRight: '1px solid #fff'
              }
            },
            children: (
              <React.Fragment>

              </React.Fragment>
            )
          };
        }
      }
    };
  };

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading || !columns.length) {
    return <></>;
  }

  return (
    <>
      <Modal style={{ minWidth: 1200 }} title={capitalizeAll(program.name)} visible={props.progressControlModalVisible}
        onCancel={() => {
          props.handleCloseProgressControlModal();
        }}

        onPan={(p) => { }}
        centered
        footer={[
          <Button key="OK" onClick={ async () => {
            props.handleCloseProgressControlModal();
          }}>
            {t('ok').toUpperCase()}
          </Button>
        ]}
      >
        {noProgress
          ? (
            <div>
            No hay progreso de este programa.
            </div>
          )
          : (
            <div>
              <Button onClick={() => handleChangeGroup(0)} disabled={selectedGroup <= 0}><LeftOutlined /></Button>
              <Button onClick={() => handleChangeGroup(1)} disabled={selectedGroup >= programGroups.length - 1}><RightOutlined /></Button>
              {columns.length > 0 && <Table columns={columns} dataSource={program.exercises} onChange={onChange} pagination={false}
                summary={(e, i) => {
                  return (
                    <>
                      <Table.Summary key={i} fixed>
                        <Table.Summary.Row style={{ background: 'lightgrey' }}>
                          <Table.Summary.Cell index={0}>{capitalize(t('time'))}</Table.Summary.Cell>

                          {programGroups[selectedGroup].map((programSession, i) => {
                            const time = (programSession.closed ? new Date(programSession.closed) : new Date()) - new Date(programSession.timestamp.createdAt);
                            let timeEx = 0;
                            let timeSecsEx = 0;
                            let totalTimeEx = 0;
                            programSession.exercises.forEach(es => {
                              timeSecsEx += new Date(es.time).getSeconds();
                              timeEx += new Date(es.time).getMinutes();
                              totalTimeEx += new Date(es.time).getTime();
                            });
                            const timeExFormat = new Date(totalTimeEx);
                            const hours = new Date(time).getHours();
                            const totalMinutes = ((hours - 1) * 60) + new Date(time).getMinutes();

                            return (
                              <Table.Summary.Cell index={i} key={i}>
                                <Table.Summary.Row>{moment(timeExFormat).format('mm:ss')} min / {totalMinutes} min</Table.Summary.Row>
                                {/* <Table.Summary.Row>{moment(time).format('HH:mm:ss')}</Table.Summary.Row> */}
                              </Table.Summary.Cell>
                            );
                          })}
                        </Table.Summary.Row>
                      </Table.Summary>
                    </>
                  );
                }}/>}
            </div>
          )}

      </Modal>
    </>

  );
};

export default ProgressControl;
