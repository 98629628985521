import { methodGet, methodPost, methodPatch } from './api/methods';

export const getAllCenters = (token) => {
  return methodGet('center/all', '', token);
};

export const getCenterById = (id, token) => {
  return methodGet('center/' + id, '', token);
};

export const getCenterByQuery = (req, token) => {
  return methodGet('center', req, token);
};

export const patchCenter = (id, req, token) => {
  return methodPatch('center/update?id=' + id, req, token);
};

export const createCenter = (data, token) => {
  return methodPost('center/create', data, token);
};
