import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import Spinner from '../../../components/navigation/Spinner';
import { getProgramByQuery } from '../../../services/program.service';
import { getIdToken } from 'firebase/auth';
import { getProgramSessionByQuery } from '../../../services/programsession.service';
import { Row } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate, useParams } from 'react-router-dom';
moment.locale('es');

const UserPrograms = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [programs, setPrograms] = useState([]);
  const [programSessions, setProgramSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (user.account) {
      loadPrograms();
    }
  }, [user.account]);

  const loadPrograms = async () => {
    const { status, data } = await getProgramByQuery({ account: params.account ? params.account : user.account._id }, user.token);
    if (status === 200) {
      const programSessions = await loadProgramSessions();
      const accountPrograms = [];
      data.data.forEach(program => {
        programSessions.forEach(programSession => {
          if (programSession.program === program._id && accountPrograms.indexOf(program) === -1) {
            accountPrograms.push(program);
          }
        });
      });
      setPrograms(accountPrograms);
      setLoading(false);
    }
  };

  const loadProgramSessions = async () => {
    const { status, data } = await getProgramSessionByQuery({ account: params.account ? params.account : user.account._id }, user.token);
    if (status === 200) {
      setProgramSessions(data.data);
      return data.data;
    }
  };

  const renderProgram = (program) => {
    const pSessions = programSessions.filter(programSession => programSession.program === program._id);
    const lastProgramSession = pSessions[pSessions.length - 1];

    return (
      <>
        <div className='exerciseCard' style={{ cursor: 'pointer' }} onClick={() => {
          navigate(`/user/program/${program._id}`);
        }}>
          <div></div>
          <div style={{ color: 'white' }}>{program.name}</div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', width: '100%', color: 'lightgray', fontSize: '0.8em' }}>
            {moment(lastProgramSession.timestamp.createdAt).format('L')}
          </div>
        </div>
      </>
    );
  };

  if (loading) {
    return <Spinner/>;
  }

  return (
    <>
      <Row justify='center'>
        {programs.map(program => renderProgram(program))}
      </Row>
    </>
  );
};

export default UserPrograms;
