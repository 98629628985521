import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getCenterByQuery } from '../../../services/center.service';
import { getBooksessionByQuery } from '../../../services/booksession.service';
import { getAccountByQuery } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

const Booksessions = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [booksessions, setBooksessions] = useState(null);
  const [trainers, setTrainers] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadBooksessions = async (centerId) => {
    const { status, data } = await getBooksessionByQuery({ center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(booksession => {
        booksession.key = booksession._id;
      });
      setBooksessions(data.data);
      setLoading(false);
    }
  };

  const loadTrainers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId, roles: 'trainer' }, user.token);
    if (status === 200) {
      setTrainers(data.data);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      await loadTrainers(data.data[0]._id);
      await loadBooksessions(data.data[0]._id);
    }
  };

  const columns = [
    {
      title: capitalize(t('booksessions')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => {
        const trainer = trainers.find(trainer => record.trainer === trainer._id);
        return (
          <React.Fragment>
            {record.name}
            <br />
            {record.description}
            <br />
            {trainer.name + ' ' + trainer.surname}
            <br />
            {record.slots} {capitalize(t('userSlots'))}
            <br />
            {record.squeeze}
          </React.Fragment>
        );
      },
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('description')),
      dataIndex: 'description',
      key: 'description',
      sorter: {
        compare: (a, b) => a.description.localeCompare(b.description),
        multiple: 6
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('trainer')),
      dataIndex: 'trainer',
      key: 'trainer',
      render: (text, record) => {
        const trainer = trainers.find(trainer => record.trainer === trainer._id);
        return trainer.name + ' ' + trainer.surname;
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('userSlots')),
      dataIndex: 'slots',
      key: 'slots',
      sorter: {
        compare: (a, b) => a.slots - b.slots,
        multiple: 7
      },
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewBooksession'))}
        </Button>
        <Table columns={columns} dataSource={booksessions} onChange={onChange} onRow={(record, rowIndex) => {
          return {
            onClick: event => { navigate('edit/' + record._id); }, // click row
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {} // mouse leave row
          };
        }} rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  };
};

export default Booksessions;
