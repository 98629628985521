import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Alert, Switch, Space, message, Popconfirm } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { createProgram, getProgramById, patchProgram } from '../../../services/program.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { CheckOutlined, CloseOutlined, CaretUpOutlined, CaretDownOutlined, DeleteOutlined } from '@ant-design/icons';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateProgram = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [exercises, setExercises] = useState([]);
  const [program, setProgram] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const { firebaseUser } = useContext(Auth);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  useEffect(() => {
    if (program && exercises.length) {
      const editExercisesArr = [];
      program.exercises.forEach(editExercise => {
        exercises.forEach(allExercise => {
          if (editExercise === allExercise._id) editExercisesArr.push(allExercise);
        });
      });
      setSelectedExercises(editExercisesArr);
    }
  }, [program, exercises]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      if (params.id) {
        await loadProgram(params.id, user.token);
      }
      await loadExercises(data.data[0]._id, user.token);
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      if (params.id) {
        await loadProgram(params.id, user.token);
      }
      await loadExercises(data.data._id, user.token);
      setLoading(false);
    }
  };

  const loadProgram = async (id, token) => {
    const { status, data } = await getProgramById(id, token);
    if (status === 200) {
      setProgram(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const loadExercises = async (center, token) => {
    const { status, data } = await getExerciseByQuery({ center: center, isActive: true }, token);
    if (status === 200) {
      setExercises(data.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  const fillForm = (program) => {
    form.setFieldsValue({
      name: program.name,
      isActive: program.isActive
    });
  };

  const deactivateProgram = async () => {
    if (program) {
      let account;
      if (params.id) account = program.account;
      else account = params.account;
      const { status } = await patchProgram(program._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/users/programs/' + account);
        message.success(capitalize(t('programDeleted')));
      }
    }
  };

  const createNewProgram = async (data, token) => {
    let account;
    if (params.id) account = program.account;
    else account = params.account;
    let exercisesSelected;
    if (exercises.length) {
      exercisesSelected = selectedExercises.map(exercise => exercise._id);
    }
    const formData = {
      name: data.name,
      account: account,
      exercises: exercisesSelected
    };
    if (params.id) await patchProgram(params.id, formData, token);
    else await createProgram(formData, token);
    navigate('/dashboard/users/programs/' + account);
  };

  const correctClave = async e => {
    await createNewProgram(e, user.token);
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {program ? <h1>{capitalizeAll(t('editProgram'))}</h1> : <h1>{capitalizeAll(t('createProgram'))}</h1>}
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={program ? program.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'left' }}>
            {capitalize(t('availableExercices'))}:
          </div>
          <div style={{ border: '1px solid', borderRadius: '3px', borderColor: '#a6a6a6', maxHeight: 385, overflow: 'scroll' }}>
            {exercises.length
              ? (
                <>
                  { selectedExercises.length < 10
                    ? (
                      exercises.map(exercise => {
                        const isInSelected = selectedExercises.find(selectedExercise => selectedExercise._id === exercise._id);
                        return (
                          <Button key={exercise._id} type={isInSelected ? 'ghost' : 'primary'} block onClick={() => {
                            const validate = selectedExercises.filter(exerciseSel => exerciseSel._id === exercise._id);
                            if (!validate.length) {
                              const exercisesArr = [...selectedExercises];
                              exercisesArr.push(exercise);
                              setSelectedExercises(exercisesArr);
                            }
                          }} style={{ margin: 2, width: '99%' }}>
                            {exercise.name}
                          </Button>
                        );
                      })
                    )
                    : (
                      <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
                        {capitalize(t('maxExercises10'))}
                      </div>
                    )}
                </>
              )
              : (<></>)}
          </div>

        </Form.Item>
        <Form.Item>
          {selectedExercises.length
            ? (
              <>
                <div style={{ textAlign: 'left' }}>
                  {capitalize(t('selectedExercises'))}:
                </div>
                <div style={{ border: '1px solid', borderRadius: '3px', borderColor: '#a6a6a6' }}>
                  {selectedExercises.length
                    ? (
                      selectedExercises.map(exercise => {
                        return (
                          <Alert key={exercise._id} message={exercise.name} type="success" closable onClose={() => {
                            const validate = selectedExercises.filter(exerciseSel => exerciseSel._id === exercise._id);
                            if (validate.length) {
                              const exercisesArr = selectedExercises.filter(ex => ex._id !== exercise._id);
                              setSelectedExercises(exercisesArr);
                            }
                          }}
                          action={
                            <Space direction="horizontal">
                              <Button size="small" type="ghost" onClick={() => {
                                // move index up
                                const exercisesArr = [...selectedExercises];
                                const index = exercisesArr.indexOf(exercise);
                                if (index > 0) {
                                  const temp = exercisesArr[index - 1];
                                  exercisesArr[index - 1] = exercise;
                                  exercisesArr[index] = temp;
                                  setSelectedExercises(exercisesArr);
                                }
                              }}>
                                <CaretUpOutlined />
                              </Button>
                              <Button size="small" type="ghost" onClick={() => {
                                // move index down
                                const exercisesArr = [...selectedExercises];
                                const index = exercisesArr.indexOf(exercise);
                                if (index < exercisesArr.length - 1) {
                                  const temp = exercisesArr[index + 1];
                                  exercisesArr[index + 1] = exercise;
                                  exercisesArr[index] = temp;
                                  setSelectedExercises(exercisesArr);
                                }
                              }}>
                                <CaretDownOutlined />
                              </Button>
                            </Space>
                          } style={{ margin: 2, width: '99%' }}/>
                        );
                      })
                    )
                    : (<></>)}
                </div>
              </>
            )
            : (<></>)}

        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {program && (
            <Popconfirm
              title={capitalize(t('confirmDeleteProgram'))}
              onConfirm={deactivateProgram}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {program ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createProgram'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateProgram;
