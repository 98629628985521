import React from 'react';
import { Button } from 'antd';
import { format } from 'date-fns';
import Modal from 'antd/es/modal/Modal';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';

const WeightControl = (props) => {
  const { t } = useTranslation();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    zoomPlugin,
    ChartDataLabels
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        title: {
          display: true,
          text: 'Kg.'
        },
        ticks: {
          stepSize: 10
        }
        // suggestedMin: 20,
        // suggestedMax: 120
      }
    },
    plugins: {
      datalabels: {
        font: {
          size: '18px'
        }
      }
    }
  };

  const labels = props.weightHistory.map(weight => format(new Date(weight.created), 'dd-MM-yyyy'));

  const data = {
    labels,
    datasets: [
      {
        id: 1,
        label: capitalize(t('weight')),
        borderColor: 'rgba(193, 221, 230,1)',
        backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: true,
        data: props.weightHistory.map(weight => weight.weight)
      }
    ]
  };

  return (
    <>
      <Modal title={capitalizeAll(t('weightControl'))} visible={props.weightModalVisible} onOk={() => {
        props.setWeightModalVisible(false);
      }}
      onCancel={() => {
        props.setWeightModalVisible(false);
      }}

      onPan={(p) => { }}
      centered
      width={'100%'}
      footer={[
        <Button key="OK" onClick={() => {
          props.setWeightModalVisible(false);
        }}>
                 OK
        </Button>
      ]}
      >

        <div style={{ height: 300 }}>
          <Line
            datasetIdKey='id'
            data={data}
            options={options}
          />

        </div>
      </Modal>
    </>

  );
};

export default WeightControl;
