/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import Spinner from '../../../components/navigation/Spinner';
import { getCenterByQuery, patchCenter } from '../../../services/center.service';
import 'react-calendar/dist/Calendar.css';
import { Button, Col, Input, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const BookingSettings = (props) => {
  const { user } = useContext(Auth);
  const [center, setCenter] = useState(null);
  const [maxDays, setMaxDays] = useState(null);
  const [visible, setVisible] = useState(true);
  const [penaltyHours, setPenaltyHours] = useState(null);
  const [minHours, setMinHours] = useState(null);
  const [sendCreditAlertEmail, setSendCreditAlertEmail] = useState(false);
  const [creditAlert, setCreditAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      setMaxDays(data.data[0].bookingSettings?.maxDaysCalendar);
      setVisible(data.data[0].bookingSettings?.calendarVisibility);
      setPenaltyHours(data.data[0].bookingSettings?.penaltyHoursCancel);
      setMinHours(data.data[0].bookingSettings?.minHoursBooking);
      setCreditAlert(data.data[0].bookingSettings?.creditAlert);
      setSendCreditAlertEmail(data.data[0].bookingSettings?.sendCreditAlertEmail);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    await patchCenter(center._id, {
      'bookingSettings.maxDaysCalendar': maxDays,
      'bookingSettings.calendarVisibility': visible,
      'bookingSettings.penaltyHoursCancel': penaltyHours,
      'bookingSettings.minHoursBooking': minHours,
      'bookingSettings.creditAlert': creditAlert,
      'bookingSettings.sendCreditAlertEmail': sendCreditAlertEmail
    }, user.token);
    window.location.reload();
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Row style={{ margin: 10, border: '1px solid rgba(0, 0, 0, 0.2)', padding: 10, borderRadius: 10 }}>
        <Col>
          <b>{capitalize(t('clientVisibility'))}:</b>
          <p>
            <Switch defaultChecked={visible} checked={visible} onChange={(e) => setVisible(e)} />
            <span style={{ marginLeft: 12 }}>{visible ? capitalizeAll(t('visible')) : capitalizeAll(t('notVisible'))}</span>
          </p>
          <b>{capitalize(t('maxDaysCalendar'))}:</b>
          <p>
            <Input defaultValue={maxDays} type={'number'}
              onWheel={(e) => e.target.blur()} onChange={(e) => setMaxDays(e.target.value)} />
          </p>
          <p>
            <b>{capitalize(t('penaltyHoursCancel'))}:</b>
            <Input defaultValue={penaltyHours} type={'number'}
              onWheel={(e) => e.target.blur()} onChange={(e) => setPenaltyHours(e.target.value)} />
          </p>
          <p>
            <b>{capitalize(t('minHoursBooking'))}:</b>
            <Input defaultValue={minHours} type={'number'}
              onWheel={(e) => e.target.blur()} onChange={(e) => setMinHours(e.target.value)} />
          </p>
          <b>{capitalize(t('sendCreditAlertEmail'))}:</b>
          <p>
            <Switch defaultChecked={sendCreditAlertEmail} checked={sendCreditAlertEmail} onChange={(e) => setSendCreditAlertEmail(e)} />
            <span style={{ marginLeft: 12 }}>{sendCreditAlertEmail ? capitalizeAll(t('send')) : capitalizeAll(t('notSend'))}</span>
          </p>
          <p>
            <b>{capitalize(t('creditAlert'))}:</b>
            <Input defaultValue={creditAlert} type={'number'}
              onWheel={(e) => e.target.blur()} onChange={(e) => setCreditAlert(e.target.value)} />
          </p>
          <p>
            <Button type={'primary'} onClick={handleSave}>{capitalize(t('save'))}</Button>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default BookingSettings;
