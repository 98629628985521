import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllPrograms = (token) => {
  return methodGet('program/all', '', token);
};

export const getProgramById = (id, token) => {
  return methodGet('program/' + id, '', token);
};

export const getProgramByQuery = (req, token) => {
  return methodGet('program', req, token);
};

export const getProgramByQueryWithExercises = (req, token) => {
  return methodGet('program/exercises', req, token);
};

export const createProgram = (data, token) => {
  return methodPost('program/create', data, token);
};

export const patchProgram = (id, data, token) => {
  return methodPatch('program/update?id=' + id, data, token);
};
