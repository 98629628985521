import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getAllDevices = (token) => {
  return methodGet('device/all', '', token);
};

export const getDeviceById = (id, token) => {
  return methodGet('device/' + id, '', token);
};

export const getDeviceByQuery = (req, token) => {
  return methodGet('device', req, token);
};

export const getDeviceByQueryWithExercises = (req, token) => {
  return methodGet('device/exercises', req, token);
};

export const createDevice = (data, token) => {
  return methodPost('device/create', data, token);
};

export const patchDevice = (id, data, token) => {
  return methodPatch('device/update?id=' + id, data, token);
};

export const deleteDevice = (id, token) => {
  return methodDelete('device/delete?id=' + id, token);
};
