import React, { useEffect } from 'react';
import Spinner from '../../components/navigation/Spinner';
import { getAuth, signOut } from 'firebase/auth';
import { app } from '../../services/firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';

const Logout = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    accountSignOut();
  }, []);
  const accountSignOut = async () => {
    const auth = getAuth(app);
    await signOut(auth).then((response) => {
      navigate('/dashboard');
    });
  };
  return (<Spinner/>);
};

export default Logout;
