import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllSettings = (token) => {
  return methodGet('setting/all', '', token);
};

export const getSettingById = (id, token) => {
  return methodGet('setting/' + id, '', token);
};

export const getSettingByQuery = (req, token) => {
  return methodGet('setting', req, token);
};

export const createSetting = (data, token) => {
  return methodPost('setting/create', data, token);
};

export const patchSetting = (id, data, token) => {
  return methodPatch('setting/update?id=' + id, data, token);
};
