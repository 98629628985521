import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Row, Col, Input } from 'antd';
import BuyCredits from '../../../components/modals/BuyCredits';
import Spinner from '../../../components/navigation/Spinner';
import { getAccountById, patchAccountPassword } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { useSearchParams } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { methodPost } from '../../../services/api/methods';

const UserProfile = () => {
  const { user } = useContext(Auth);
  // const stripe = useStripe();
  const [buyCreditsModalVisible, setBuyCreditsModalVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }
  //
  //   const paymentIntent = searchParams.get('payment_intent');
  //   const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
  //
  //   if (!paymentIntent || !paymentIntentClientSecret) {
  //     return;
  //   }
  //
  //   console.log('paymentIntent', paymentIntent);
  //   console.log('paymentIntentClientSecret', paymentIntentClientSecret);
  //
  //   stripe.retrievePaymentIntent(paymentIntentClientSecret).then(({ paymentIntent }) => {
  //     console.log('paymentIntent', paymentIntent);
  //     switch (paymentIntent.status) {
  //     case 'succeeded':
  //       setMessage('Payment succeeded!');
  //       console.log('Payment succeeded!');
  //       console.log('Intent', paymentIntent);
  //       setSearchParams({ payment_intent: null, payment_intent_client_secret: null });
  //       break;
  //     case 'processing':
  //       setMessage('Your payment is processing.');
  //       console.log('Your payment is processing.');
  //       console.log('Intent', paymentIntent);
  //       setSearchParams({ payment_intent: null, payment_intent_client_secret: null });
  //       break;
  //     case 'requires_payment_method':
  //       setMessage('Your payment was not successful, please try again.');
  //       console.log('Your payment was not successful, please try again.');
  //       console.log('Intent', paymentIntent);
  //       setSearchParams({ payment_intent: null, payment_intent_client_secret: null });
  //       break;
  //     default:
  //       setMessage('Something went wrong.');
  //       console.log('Something went wrong.');
  //       console.log('Intent', paymentIntent);
  //       setSearchParams({ payment_intent: null, payment_intent_client_secret: null });
  //       break;
  //     }
  //   });
  // }, [stripe]);

  useEffect(() => {
    if (user.account) {
      loadAccount();
    }
  }, [user.account]);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage(t('passwordsDoNotMatch'));
      return;
    }
    if (newPassword.length < 6) {
      setMessage(t('passwordTooShort'));
      return;
    }
    const loginPayload = {
      username: user.account.email,
      password: oldPassword
    };
    const loginData = await methodPost('auth/login', loginPayload);
    if (loginData.data.statusCode === 401) {
      setMessage(capitalize(t('incorrectPassword')));
      return;
    }
    const token = loginData.data.token;
    const payload = {
      password: newPassword
    };
    const data = await patchAccountPassword(user.account._id, payload, token);
    if (data.status === 200) {
      setMessage(capitalize(t('passwordChanged')));
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
  };

  const loadAccount = async () => {
    const { data, status } = await getAccountById(user.account._id, user.token);
    if (status === 200) {
      setAccount(data.data);
      setLoading(false);
    }
  };

  const handleCloseBuyCreditsModal = async () => {
    await loadAccount();
    setBuyCreditsModalVisible(false);
  };

  if (loading) {
    return <Spinner/>;
  }

  return (
    <>
      <Row style={{ margin: 10, border: '1px solid rgba(0, 0, 0, 0.2)', padding: 10, borderRadius: 10 }}>
        <Col>
          {capitalize(t('name'))}: {account.name + ' ' + account.surname}<br/>
          {capitalize(t('email'))}: {account.email}<br/>
          {capitalize(t('phone'))}: {account.phone}<br/>
          {t('nif').toUpperCase()}: {account.nif}<br/>
          {capitalize(t('credits'))}: {account.credits ? account.credits : 0}<br/>
          <Button disabled type="primary"
            onClick={() => setBuyCreditsModalVisible(true)}>{capitalizeAll(t('buyCredits'))}</Button>
        </Col>
      </Row>
      <b>{capitalize(t('changePassword'))}:</b>
      <Row style={{ margin: 10, border: '1px solid rgba(0, 0, 0, 0.2)', padding: 10, borderRadius: 10 }}>
        <Col>
          <p>
            {capitalize(t('oldPassword'))}:
            <Input.Password value={oldPassword} placeholder={t('oldPassword')} onChange={(e) => setOldPassword(e.target.value)}/>
          </p>
          <p>
            {capitalize(t('newPassword'))}:
            <Input.Password value={newPassword} placeholder={t('newPassword')} onChange={(e) => setNewPassword(e.target.value)}/>
          </p>
          <p>
            {capitalize(t('confirmPassword'))}:
            <Input.Password value={confirmPassword} placeholder={t('confirmPassword')} onChange={(e) => setConfirmPassword(e.target.value)}/>
          </p>
          <Button type="primary"
            onClick={() => handleChangePassword()}>{capitalizeAll(t('changePassword'))}</Button><br />
          <span >{message}</span>
        </Col>
      </Row>
      <BuyCredits buyCreditsModalVisible={buyCreditsModalVisible} handleCloseBuyCreditsModal={handleCloseBuyCreditsModal} user={user} account={account}/>
    </>

  );
};

export default UserProfile;
