import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllSubscriptions = (token) => {
  return methodGet('subscription/all', '', token);
};

export const getSubscriptionById = (id, token) => {
  return methodGet('subscription/' + id, '', token);
};

export const getSubscriptionByQuery = (req, token) => {
  return methodGet('subscription', req, token);
};

export const createSubscription = (data, token) => {
  return methodPost('subscription/create', data, token);
};

export const patchSubscription = (id, data, token) => {
  return methodPatch('subscription/update?id=' + id, data, token);
};
