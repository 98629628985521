import React from 'react';

const NotFound = (props, { children }) => {
  return (
    <>
      <p>404 - Página no encontrada</p>
    </>

  );
};

export default NotFound;
