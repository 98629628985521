/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'antd';
import { io } from 'socket.io-client';
import axios from 'axios';
import 'react-circular-progressbar/dist/styles.css';
// eslint-disable-next-line no-unused-vars
import { Line, Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
} from 'chart.js';
// import { format } from 'date-fns';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

const ENDPOINT = 'http://192.168.1.89';

const Isometrics = (props) => {
  const [pressLeft, setPressLeft] = useState(0);
  const [pressRight, setPressRight] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useState(0);
  const [countArr, setCountArr] = useState([]);
  const [pressArrLeft, setPressArrLeft] = useState([]);
  const [pressArrRight, setPressArrRight] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const chartRef = useRef(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    zoomPlugin
    // ChartDataLabels
  );

  // eslint-disable-next-line no-unused-vars
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,
    plugins: {
      tooltip: {
        enabled: false // <-- this option disables tooltips
      }
    },
    animation: false,
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0 // default to disabled in all datasets
      }
    },
    minRotation: 0,
    maxRotation: 0,
    // transitions: {
    //   show: {
    //     animations: {
    //       x: {
    //         from: 0
    //       },
    //       y: {
    //         from: 0
    //       }
    //     }
    //   },
    //   hide: {
    //     animations: {
    //       x: {
    //         to: 0
    //       },
    //       y: {
    //         to: 0
    //       }
    //     }
    //   }
    // },
    scales: {
      y: {
        min: 0,
        suggestedMax: 30
      },
      x: {
        min: countArr.length - 25,
        max: countArr.length
      }
    }
  };

  const optionsBar = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 10
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: false,
        text: 'X'
      }
    }
  };

  //

  const labels = [...countArr];

  // eslint-disable-next-line no-unused-vars
  const data = {
    labels: labels,
    datasets: [
      {
        id: 1,
        label: 'LEFT',
        borderColor: 'rgba(60, 0, 0,0.8)',
        data: [...pressArrLeft]
      },
      {
        id: 2,
        label: 'RIGHT',
        borderColor: 'rgba(2, 60, 2,0.8)',
        data: [...pressArrRight]
      }
    ]
  };

  const dataBar = {
    labels: ['LEFT', 'RIGHT'],
    datasets: [
      {
        id: 'a',
        label: 'LEFT',
        borderColor: 'rgba(0 , 0, 0,1)',
        backgroundColor: ['rgba(60, 0, 0,0.8)', 'rgba(2, 60, 2,0.8)'],
        // fill: true,
        data: [pressLeft, pressRight]
      }
    ]
  };

  useEffect(() => {
    const socket = io(ENDPOINT, { transports: ['websocket'] });

    let pressLeftMoment;
    let pressRightMoment;
    let socketCount = 0;
    let intervalCount = 0;
    socket.on('arduino:data', data => {
      // setPressLeft(data.left);
      pressLeftMoment = data.left;
      // setPressRight(data.right);
      pressRightMoment = data.right;
      socketCount = data.count;
    });
    let x = -0.0;
    const timeArray = [];
    const leftValue = [];
    const rightValue = [];
    const interval = setInterval(() => {
      // getServerStatus();
      if (intervalCount !== socketCount) {
        x += 0.1;
        timeArray.push(x.toFixed(1));
        leftValue.push(pressLeftMoment);
        rightValue.push(pressRightMoment);
        setCount(x);
        setPressArrLeft(leftValue);
        setPressArrRight(rightValue);
        setCountArr(timeArray);
        intervalCount = socketCount;
      }
    }, 100);
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   let x = 0;
  //   const tempArray = [];
  //   const interval = setInterval(() => {
  //     x++;
  //     tempArray.push(x);
  //
  //     setCount(x);
  //     setCountArr(tempArray);
  //   }, 500);
  //   return () => clearInterval(interval);
  // }, []);

  // const getServerStatus = async () => {
  //   await axios.get(`${ENDPOINT}/status`).then(res => {
  //
  //   });
  // };

  const handleStartServer = async () => {
    await axios.post(ENDPOINT + '/on');
  };

  const handeStopServer = async () => {
    await axios.post(ENDPOINT + '/off');
  };

  return (
    <>
      <div style={{ background: 'linear-gradient(145deg, rgba(255,255,255,0.19371498599439774) 21%, rgba(122,122,122,0.2945553221288515) 50%, rgba(64,64,64,0.50) 50%)' }}>
        <div>
          <Button onClick={() => { handleStartServer(); }}>Start</Button>
          <Button onClick={() => { handeStopServer(); }}>Stop</Button>
        </div>
        <div style={{ height: 300 }}>
          <Line
            redraw={false}
            datasetIdKey='id'
            data={data}
            options={options}
            ref={chartRef}
          />

        </div>
        {/* <div style={{ height: 300, width: 300 }}> */}
        {/*  <Bar options={optionsBar} data={dataBar} /> */}
        {/* </div> */}
        <div>
          {count.toFixed(2)}
        </div>
      </div>
    </>
  );
};
export default Isometrics;
