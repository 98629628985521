import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Popconfirm, message } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getAuth, createUserWithEmailAndPassword, signOut, getIdToken } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { sendEmail, getAccountById } from '../../../services/user.service';
import { getCenterByQuery, getCenterById } from '../../../services/center.service';
import { getComunicationtemplateByQuery } from '../../../services/comunicationtemplate.service';
import Spinner from '../../../components/navigation/Spinner';
import { signUpApp } from '../../../services/firebase/firebaseConfig';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const UserComunications = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [comunicationtemplates, setComunicationtemplates] = useState([]);
  const [error, seterror] = useState('');
  const [loading, setLoading] = useState(true);
  const [presset, setPresset] = useState({
    subject: '',
    message: ''
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { TextArea } = Input;

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  useEffect(() => {
    if (presset.message !== '' && presset.subject !== '') {
      fillWithPresset(presset.subject, presset.message);
    }
  }, [presset]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      await loadAccount(params.id);
      await loadComunicationtemplates(data.data._id);
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(user.account.center, user.token);
    if (status === 200) {
      setCenter(data.data);
      await loadAccount(params.id);
      await loadComunicationtemplates(data.data._id);
      setLoading(false);
    }
  };

  const loadAccount = async (id) => {
    const { status, data } = await getAccountById(id, user.token);
    if (status === 200) {
      setEditUser(data.data);
    }
  };

  const loadComunicationtemplates = async (centerId) => {
    const { status, data } = await getComunicationtemplateByQuery({ center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(comunicationtemplate => {
        comunicationtemplate.key = comunicationtemplate._id;
      });
      setComunicationtemplates(data.data);
    }
  };

  const sendUserEmail = async (data) => {
    const formData = {
      message: data.message,
      subject: data.subject
    };
    await sendEmail(params.id, formData, user.token);
    navigate('/dashboard/users');
  };

  const correctClave = async e => {
    await sendUserEmail(e);
  };

  const fillWithPresset = (subject, message) => {
    form.setFieldsValue({
      subject: subject,
      message: message
    });
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          <h1>{capitalizeAll(t('sendEmail'))}</h1>
        </Form.Item>
        <div style={{ textAlign: 'left', fontSize: 12, marginBottom: 8 }}>
          {capitalize(t('to'))}: {editUser.name} {editUser.surname} ({editUser.email})
        </div>
        {error ? <Form.Item>{error}</Form.Item> : null}
        <Form.Item
          name="subject"
          rules={[{
            required: true,
            message: capitalize(t('subject')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('subject'))}
            name="subject"
            placeholder={capitalize(t('subject'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>
          {capitalize(t('message'))}:
        </div>
        <Form.Item
          name="message"
          rules={[{
            required: true,
            message: capitalize(t('message')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            addonBefore={capitalize(t('message'))}
            rows={4}
            name="message"
            placeholder={capitalize(t('message'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {capitalizeAll(t('sendEmail'))}
            </Button>
          </Form.Item>
        </div>

      </Form>
      <div style={{ display: 'flex', flexDirection: 'column', width: '400px' }}>
        {capitalize(t('pressetsEmails'))}:
        <button onClick={() => setPresset({
          subject: 'Bono Terminado',
          message: 'Queremos informarte, que tu "Bono de Entrenamiento" se ha quedado sin sesiones.\n\nMuchas gracias.'
        })} style={{ width: '100%', marginBottom: 4 }}>
          {t('Predefinido: Bono agotado')}
        </button>
        {comunicationtemplates.map((comunicationtemplate, index) => {
          return (
            <button key={index} onClick={() => setPresset({
              subject: comunicationtemplate.name,
              message: comunicationtemplate.text
            })} style={{ width: '100%', marginBottom: 4 }}>
              {comunicationtemplate.name}
            </button>
          );
        }
        )}
      </div>
    </div>
  );
};
export default UserComunications;
