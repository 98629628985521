import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import es from './es.js';
import en from './en.js';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      es: {
        translation: es
      },
      en: {
        translation: en
      }
    },
    lng: localStorage.getItem('language') ? localStorage.getItem('language') : 'es',
    fallbackLng: 'es',
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
