import axios from 'axios';

const headers = {
  'Content-Type': 'application/json'
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: headers,
  crossdomain: true
});

export default axiosInstance;
