import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Form, Image, Table } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AudioOutlined, EditOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  PlusOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getAccountById, getAccountByQuery } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { getHistoryByQuery } from '../../../services/history.service';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { getCenterById } from '../../../services/center.service';
import * as Cookie from 'js-cookie';
moment.locale('es');

const ExternalHistories = (props) => {
  // const { user } = useContext(Auth);
  const params = useParams();
  const navigate = useNavigate();
  const [histories, setHistories] = useState(null);
  const [account, setAccount] = useState(null);
  const [center, setCenter] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState('');
  const [codeMessage, setCodeMessage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (Cookie.get('historyLogged') === 'true') {
      setIsLogged(true);
    }
    loadAccount(params.nif);
  }, []);

  const getUrlExtension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  };

  const getFileTypeByExtension = (url) => {
    switch (getUrlExtension(url)) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return {
        type: 'image',
        icon: <FileImageOutlined />
      };
    case 'mp4':
    case 'webm':
    case 'ogg':
      return {
        type: 'video',
        icon: <VideoCameraOutlined />
      };
    case 'pdf':
      return {
        type: 'pdf',
        icon: <FilePdfOutlined />
      };
    case 'mp3':
      return {
        type: 'audio',
        icon: <AudioOutlined />
      };
    default:
      return {
        type: 'file',
        icon: <FileZipOutlined />
      };
    }
  };

  const loadHistories = async (userId) => {
    const { status, data } = await getHistoryByQuery({ account: userId, isActive: true });
    if (status === 200) {
      data.data.forEach(history => {
        history.key = history._id;
      });
      setHistories(data.data.reverse());
      setLoading(false);
    }
  };

  const loadAccount = async (nif) => {
    const { status, data } = await getAccountByQuery({ nif: nif, roles: 'user' });
    if (status === 200) {
      setAccount(data.data[0]);
      await loadCenter(data.data[0].center);
      await loadHistories(data.data[0]._id);
    }
  };

  const loadCenter = async (centerId) => {
    const { status, data } = await getCenterById(centerId);
    if (status === 200) {
      setCenter(data.data);
    }
  };

  const columns = [
    {
      title: capitalizeAll(t('history')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {moment(record.timestamp.createdAt).format('LLL')}
          <br />
          {record.name}
          <p style={{ whiteSpace: 'pre' }}>
            {record.description}
          </p>
          {record.attachments.length > 0
            ? record.attachments.map((attachment, index) => {
              if (getFileTypeByExtension(attachment).type === 'image') {
                return (
                  <span key={index} style={{ margin: 3, marginRight: 6, padding: 0 }}>
                    <Button type="link" style={{ fontSize: 22, padding: 0 }} onClick={() => {
                      setPreviewImage(attachment);
                      setVisible(true);
                    }}>
                      {getFileTypeByExtension(attachment).icon}
                    </Button>
                    <Image
                      width={0}
                      style={{ display: 'none' }}
                      preview={{
                        visible,
                        src: previewImage,
                        mask: true,
                        onVisibleChange: value => {
                          setVisible(value);
                        }
                      }}
                    />
                  </span>
                );
              } else {
                return (
                  <span key={index} style={{ margin: 3, marginRight: 6, fontSize: 22, padding: 0 }}>
                    <a href={attachment} target="_blank" rel="noreferrer">{getFileTypeByExtension(attachment).icon}</a>
                  </span>
                );
              }
            })
            : capitalize(t('noAttachments'))}
          <br />
          <Button icon={<EditOutlined />} onClick={(e) => {
            navigate('/dashboard/users/histories/edit/' + record._id);
          }}>{capitalize(t('edit'))}</Button>
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      dataIndex: ['timestamp.createdAt'],
      key: 'timestamp.createdAt',
      render: (text, record) => moment(record.timestamp.createdAt).format('LLL'),
      sorter: {
        compare: (a, b) => a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('name')),
      key: 'name',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('description')),
      key: 'description',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          <p style={{ whiteSpace: 'pre' }}>
            {record.description}
          </p>
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.description.localeCompare(b.description),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('attachments')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.attachments.length > 0
            ? record.attachments.map((attachment, index) => {
              if (getFileTypeByExtension(attachment).type === 'image') {
                return (
                  <span key={index} style={{ margin: 3, marginRight: 6, padding: 0 }}>
                    <Button type="link" style={{ fontSize: 22, padding: 0 }} onClick={() => {
                      setPreviewImage(attachment);
                      setVisible(true);
                    }}>
                      {getFileTypeByExtension(attachment).icon}
                    </Button>
                    <Image
                      width={0}
                      style={{ display: 'none' }}
                      preview={{
                        visible,
                        src: previewImage,
                        mask: true,
                        onVisibleChange: value => {
                          setVisible(value);
                        }
                      }}
                    />
                  </span>
                );
              } else {
                return (
                  <span key={index} style={{ margin: 3, marginRight: 6, fontSize: 22, padding: 0 }}>
                    <a href={attachment} target="_blank" rel="noreferrer">{getFileTypeByExtension(attachment).icon}</a>
                  </span>
                );
              }
            })
            : capitalize(t('noAttachments'))}
        </React.Fragment>
      ),
      responsive: ['sm']
    },
    {
      title: capitalize(t('actions')),
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button icon={<EditOutlined />} onClick={(e) => {
              navigate('/ext/histories/' + params.nif + '/edit/' + record._id);
            }}>{capitalize(t('edit'))}</Button>
          </div>
        </React.Fragment>
      ),
      responsive: ['sm']
    }
  ];

  function onChange (pagination, filters, sorter, extra) {

  }

  if (!isLogged) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 10 }}>
        <div>
          {capitalize(t('accessCode'))}
        </div>
        <Form>
          <input type='text' value={code} onChange={(e) => {
            setCode(e.target.value);
          }} style={{ border: '1px solid', borderRadius: 5, padding: 3, textAlign: 'center', marginRight: 15 }}/>
          <Button type="primary" htmlType="submit" onClick={() => {
            if (code === center.settingsCode) {
              Cookie.set('historyLogged', 'true', { expires: 1 });
              setCodeMessage('');
              setCode('');
              setIsLogged(true);
            } else {
              setCode('');
              setCodeMessage(capitalize(t('wrongAccessCode')));
            }
          }}>{capitalize(t('login'))}</Button>
        </Form>
        <div style={{ color: 'red' }}>
          {codeMessage}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <Spinner/>
    );
  } else {
    return (
      <>
        <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={() => navigate('/ext/histories/' + params.nif + '/' + account._id + '/add')} style={{ marginBottom: 18 }}>
          {capitalize(t('createNewHistory'))}
        </Button>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b>{account ? account.name : ''}</b>
        </div>
        <Table columns={columns} dataSource={histories} onChange={onChange}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: event => { navigate('/dashboard/users/histories/edit/' + record._id); }, // click row
          //     onDoubleClick: event => {}, // double click row
          //     onContextMenu: event => {}, // right button click row
          //     onMouseEnter: event => {}, // mouse enter row
          //     onMouseLeave: event => {} // mouse leave row
          //   };
          // }}
          rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  };
};

export default ExternalHistories;
