import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getAllHistories = (token) => {
  return methodGet('history/all', '', token);
};

export const getHistoryById = (id, token) => {
  return methodGet('history/' + id, '', token);
};

export const getHistoryByQuery = (req, token) => {
  return methodGet('history', req, token);
};

export const createHistory = (data, token) => {
  return methodPost('history/create', data, token);
};

export const patchHistory = (id, data, token) => {
  return methodPatch('history/update?id=' + id, data, token);
};

export const deleteHistory = (id, token) => {
  return methodDelete('history/delete?id=' + id, token);
};
