import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getCenterByQuery, patchCenter } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { patchAccountPassword } from '../../../services/user.service';
import { methodPost } from '../../../services/api/methods';
import { getIdToken } from 'firebase/auth';
import { Col, Input, Radio, Row, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const MyCenter = (props) => {
  const { user } = useContext(Auth);
  const [centers, setCenters] = useState(null);
  const [organizer, setOrganizer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [birthdayEmail, setBirthdayEmail] = useState(false);
  const [birthdayEmailSubject, setBirthdayEmailSubject] = useState('');
  const [birthdayEmailBody, setBirthdayEmailBody] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenters(data.data);
      setBirthdayEmail(data.data[0].birthdaySettings?.sendEmail || false);
      setBirthdayEmailSubject(data.data[0].birthdaySettings?.emailSubject || '¡Felicidades!');
      setBirthdayEmailBody(data.data[0].birthdaySettings?.emailText || 'De parte del centro, te deseamos un día genial. Felicidades');
      setOrganizer(user.account);
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage(t('passwordsDoNotMatch'));
      return;
    }
    if (newPassword.length < 6) {
      setMessage(t('passwordTooShort'));
      return;
    }
    const loginPayload = {
      username: user.account.email,
      password: oldPassword
    };
    const loginData = await methodPost('auth/login', loginPayload);
    if (loginData.data.statusCode === 401) {
      setMessage(capitalize(t('incorrectPassword')));
      return;
    }
    const token = loginData.data.token;
    const payload = {
      password: newPassword
    };
    const data = await patchAccountPassword(user.account._id, payload, token);
    if (data.status === 200) {
      setMessage(capitalize(t('passwordChanged')));
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
  };

  const updateWeightMeasure = async (centerId, weight) => {
    await patchCenter(centerId, { weightMeasure: weight }, user.token);
  };

  const updateLanguaje = async (centerId, language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    await patchCenter(centerId, { language: language }, user.token);
  };

  // const updateHighMeasure = async (centerId, high) => {
  //   await patchCenter(centerId, { highMeasure: high }, user.token);
  // };

  const updateSettingsCode = async (centerId, code) => {
    await patchCenter(centerId, { settingsCode: code }, user.token);
  };

  const updateBirthdayEmail = async (centerId) => {
    const payload = {
      birthdaySettings: {
        sendEmail: birthdayEmail,
        emailSubject: birthdayEmailSubject,
        emailText: birthdayEmailBody
      }
    };
    await patchCenter(centerId, payload, user.token);
  };

  const renderCenter = (center) => {
    return (
      <Row>
        <Row style={{ margin: 10, border: '1px solid rgba(0, 0, 0, 0.2)', padding: 10, borderRadius: 10 }}>
          <Col>
            <p>
              {capitalize(t('name'))}: {center.name}<br/>
              {capitalize(t('email'))}: {center.email}<br/>
              {capitalize(t('phone'))}: {center.phone}<br/>
            </p>
            <b>{capitalizeAll(t('settingsCode'))}:</b>
            <p>
              <Input defaultValue={center.settingsCode} type={'number'}
                onWheel={(e) => e.target.blur()} maxLength={4} onChange={(e) => updateSettingsCode(center._id, e.target.value)} />
            </p>
            <b>{capitalize(t('weightMeasure'))}:</b>
            <p>
              <Radio.Group disabled defaultValue={center.weightMeasure} buttonStyle="solid" onChange={(e) => {
                updateWeightMeasure(center._id, e.target.value);
              }}>
                <Radio.Button value="kg">Kg</Radio.Button>
                <Radio.Button value="lbs">Lbs</Radio.Button>
              </Radio.Group>
            </p>
            <b>{capitalize(t('languaje'))}:</b>
            <p>
              <Radio.Group defaultValue={center.language} buttonStyle="solid" onChange={(e) => {
                updateLanguaje(center._id, e.target.value);
              }}>
                <Radio.Button value="es">{capitalize(t('castilian'))}</Radio.Button>
                <Radio.Button value="en">{capitalize(t('english'))}</Radio.Button>
              </Radio.Group>
            </p>
          </Col>
        </Row>
        <Row style={{ margin: 10, border: '1px solid rgba(0, 0, 0, 0.2)', background: 'lightgray', padding: 10, borderRadius: 10 }}>
          <Col>
            <p>
              <b>{capitalize(t('logo'))}:</b>
            </p>
            <img src={center.logo} alt={center.name} style={{ width: 200 }} />
          </Col>
        </Row>
      </Row>

    );
  };

  return (
    <>
      {loading
        ? (
          <Spinner/>
        )
        : (
          <>
            <b>{capitalize(t('organizer'))}:</b>
            <p>
              {capitalize(t('name'))}: {organizer.name}<br/>
              {capitalize(t('surname'))}: {organizer.surname}<br/>
              {capitalize(t('email'))}: {organizer.email}<br/>
              {capitalize(t('phone'))}: {organizer.phone}<br/>
            </p>
            <b>{capitalize(t('centers'))}:</b>
            <Row>
              {centers && centers.map(center => renderCenter(center))}
            </Row>

            <b>{capitalize(t('changePassword'))}:</b>
            <Row style={{ margin: 10, border: '1px solid rgba(0, 0, 0, 0.2)', padding: 10, borderRadius: 10 }}>
              <Col>
                <p>
                  {capitalize(t('oldPassword'))}:
                  <Input.Password value={oldPassword} placeholder={t('oldPassword')} onChange={(e) => setOldPassword(e.target.value)}/>
                </p>
                <p>
                  {capitalize(t('newPassword'))}:
                  <Input.Password value={newPassword} placeholder={t('newPassword')} onChange={(e) => setNewPassword(e.target.value)}/>
                </p>
                <p>
                  {capitalize(t('confirmPassword'))}:
                  <Input.Password value={confirmPassword} placeholder={t('confirmPassword')} onChange={(e) => setConfirmPassword(e.target.value)}/>
                </p>
                <Button type="primary"
                  onClick={() => handleChangePassword()}>{capitalizeAll(t('changePassword'))}</Button><br />
                <span >{message}</span>
              </Col>
            </Row>
            <b>{capitalize(t('birthdayEmail'))}:</b>
            <Row style={{ margin: 10, border: '1px solid rgba(0, 0, 0, 0.2)', padding: 10, borderRadius: 10 }}>
              <Col>
                <p>
                  <b>{capitalize(t('sendEmail'))}:</b><br />
                  <Radio.Group value={birthdayEmail} buttonStyle="solid" onChange={(e) => setBirthdayEmail(e.target.value)}>
                    <Radio.Button value={true}>{capitalize(t('yes'))}</Radio.Button>
                    <Radio.Button value={false}>{capitalize(t('no'))}</Radio.Button>
                  </Radio.Group>
                </p>
                <p>
                  <b>{capitalize(t('subject'))}:</b><br />
                  <Input style={{ width: 400 }} value={birthdayEmailSubject} onChange={(e) => setBirthdayEmailSubject(e.target.value)} />
                </p>
                <p>
                  <b>{capitalize(t('text'))}:</b>
                  <Input.TextArea value={birthdayEmailBody} onChange={(e) => setBirthdayEmailBody(e.target.value)} />
                </p>
                <Button type="primary"
                  onClick={() => updateBirthdayEmail(centers[0]._id)}>{capitalizeAll(t('save'))}</Button><br />
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

export default MyCenter;
