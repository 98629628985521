import React, { useContext } from 'react';
import { Button, Menu } from 'antd';
import {
  HomeOutlined, LogoutOutlined, MenuOutlined,
  ProfileOutlined, SolutionOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import '../layout/layout.css';
import { Link, useLocation } from 'react-router-dom';
import { MenuContext } from '@elekajota/react-flexible-sliding-menu-scroll';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../utils/utils';

const AdminMenu = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { closeMenu } = useContext(MenuContext);
  return (
    <>
      <Menu theme="dark" mode={props.mode || 'inline'} defaultSelectedKeys={['/dashboard']} selectedKeys={[location.pathname]} onClick={closeMenu}>
        {props.mode !== 'horizontal'
          ? (
            <Button onClick={closeMenu} style={{ height: '100%', width: '100%', border: 0, display: 'flex', justifyContent: 'flex-end' }} ghost ><MenuOutlined style={{ fontSize: 32, margin: '8px' }} /></Button>
          )
          : (<></>)}
        <Menu.Item key="/dashboard" icon={<ProfileOutlined style={{ fontSize: 24 }}/>} style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/dashboard">{capitalize(t('home'))}</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/centers" icon={<HomeOutlined style={{ fontSize: 24 }}/>} style={{ display: 'flex', alignItems: 'center' }}>
          <Link to='/dashboard/centers'>{capitalize(t('centers'))}</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/organizers" icon={<UserAddOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
          <Link to='/dashboard/organizers'>{capitalize(t('organizers'))}</Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/centers/sessions" icon={<SolutionOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
          <Link to='/dashboard/centers/sessions'>{capitalize(t('sessions'))}</Link>
        </Menu.Item>
        {props.mode !== 'horizontal'
          ? (
            <Menu.Item key="logout" icon={<LogoutOutlined style={{ fontSize: 24 }} />} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '100%' }} onClick={props.signOut}> {capitalize(t('logout'))} </div>
            </Menu.Item>
          )
          : (<></>)}
      </Menu>
    </>
  );
};

export default AdminMenu;
