import React, { useEffect, useState } from 'react';
// import { Context as Auth } from '../../../services/auth/AuthContext';
import { useParams } from 'react-router-dom';
// import { getAccountByQuery } from '../../../services/user.service';
import { Input } from 'antd';
import Spinner from '../../../components/navigation/Spinner';
import Form from 'antd/es/form/Form';
import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
// import { getIdToken } from 'firebase/auth';
import EditExerciseSettings from './EditExerciseSettings';
import { getCenterById } from '../../../services/center.service';
import { capitalize } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

const ConfigExercise = (props) => {
  // const { user } = useContext(Auth);
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState(null);
  // const [accounts, setAccount] = useState([]);
  const [settingsForm, setSettingsForm] = useState(false);
  const [nifForm] = Form.useForm(); const [value, setValue] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClick = (e) => {
    switch (e.detail) {
    case 1:
      break;
    case 2:
      if (document.fullscreenEnabled) {
        document.documentElement.requestFullscreen();
      }
      break;
    case 3:
      break;
    default:
    }
  };

  useEffect(() => {
    // if (user.account && user.firebaseUser) {
    // loadAccounts(params.center);
    loadCenter();
    // }
  }, []);

  const loadCenter = async () => {
    const { status, data } = await getCenterById(params.center);
    if (status === 200) {
      setCenter(data.data);
      setLoading(false);
    }
  };

  if (loading) { return <Spinner dark/>; }

  // const loadAccounts = async (centerId) => {
  //   const { status, data } = await getAccountByQuery({ center: centerId, roles: 'trainer' });
  //   if (status === 200) {
  //     data.data.forEach(account => {
  //       account.key = account._id;
  //     });
  //     setAccount(data.data);
  //     setLoading(false);
  //   }
  // };

  const searchAccount = (data) => {
    // const accountFound = accounts.filter(account => {
    //   if (account.nif.includes(data)) return account;
    //   return null;
    // });
    // if (data.length <= 3) {
    //   loadAccounts(params.center);
    // }
    // console.table(accountFound);
    if (data.length > 3 && data === center.settingsCode) {
      setSettingsForm(true);
    }
  };

  const addNumber = (data) => {
    let inputNif = '';
    if (nifForm.getFieldValue('nif')) inputNif = nifForm.getFieldValue('nif');
    nifForm.setFieldsValue({
      nif: inputNif + data.target.innerText
    });
    searchAccount(nifForm.getFieldValue('nif'));
  };

  const deleteNumber = () => {
    const inputNif = nifForm.getFieldValue('nif');
    if (inputNif) {
      nifForm.setFieldsValue({
        nif: inputNif.substring(0, inputNif.length - 1)
      });
    }
  };

  const resetNumber = () => {
    nifForm.setFieldsValue({
      nif: ''
    });
  };

  if (settingsForm) {
    return (
      <EditExerciseSettings setShowSettingsConfig={props.setShowSettingsConfig} setNoSettings={props.setNoSettings} setSettingsForm={setSettingsForm} account={props.account} exercise={props.exercise} editSettings={props.editSettings} />
    );
  }

  return (
    <>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div className={`background-sub${center.theme}`} style={{ height: '100vh', justifyContent: 'space-betweem', alignItems: 'center', display: 'flex', flexDirection: 'row', wrap: 'nowrap' }}>
          <div className='buttonsPage' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', flexDirection: 'row', fontSize: 24, width: '100%' }}>
            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', color: 'white' }}> */}
            {/*  <div></div> */}
            {/*  <div style={{ width: '100%' }}> */}
            {/*    <img height={'280'} src={bstLogo} alt={'logo'}/> */}
            {/*  </div> */}
            {/*  <div> */}
            {/*    <div>{moment(value).format('dddd DD')} de {moment(value).format('MMMM')}</div> */}
            {/*    <div style={{ fontSize: 32 }}>{moment(value).format('HH:mm:ss')}</div> */}
            {/*  </div> */}

            {/* </div> */}
            <Form
              form={nifForm}
              layout="vertical"
              autoComplete="off"
              className={`loginForm${center.theme}`}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}
            >
              <div/>
              <div>
                <Form.Item
                  name="nif"
                  value={''}
                  // className='nifForm'
                  style={{ }}
                >
                  {/* {nifForm.getFieldValue('nif')} */}
                  <Input className={`nifForm${center.theme}`} onChange={searchAccount} style={{ textAlign: 'center' }}/>
                </Form.Item>
                <div className='divButtons'>
                  <div className='rowButtons'>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>1</button>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>2</button>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>3</button>
                  </div>
                  <div className='rowButtons'>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>4</button>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>5</button>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>6</button>
                  </div>
                  <div className='rowButtons'>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>7</button>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>8</button>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>9</button>
                  </div>
                  <div className='rowButtons'>
                    <button className={`numberButton${center.theme}`} onClick={deleteNumber} style={{ }}><LeftOutlined style={{ fontSize: 24 }} /></button>
                    <button className={`numberButton${center.theme}`} onClick={addNumber} style={{ }}>0</button>
                    <button className={`numberButton${center.theme}`} onClick={resetNumber} style={{ }}><DeleteOutlined style={{ fontSize: 24 }} /></button>
                  </div>
                </div>
              </div>

              <div className={`text-color${center.theme}`} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div onClick={() => { props.setShowSettingsConfig(false); }}>
                  {capitalize(t('back'))}
                </div>
                <div>
                  {capitalize(t('config'))}
                </div>
              </div>
            </Form>

          </div>
        </div>
      </div>
    </>
  );
};
export default ConfigExercise;
