import { React, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';

export const NumberPicker = ({
  theme,
  numbers,
  decimals = [],
  selectedNumber,
  setSelectedNumber,
  selectedDecimal = 0,
  setSelectedDecimal = () => { },
  percent = false,
  percentResult = false,
  wheelClass = ''
}) => {
  const addStyle = url => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;

    document.head.appendChild(style);
  };

  addStyle(process.env.PUBLIC_URL + `/assets/css/wheel-style${theme}.css`);

  return (
    <>
      <div className={wheelClass} style={{ display: 'block', width: '100%' }}>
        <Swiper
          slidesPerView={'auto'}
          initialSlide={selectedNumber}
          slideToClickedSlide={true}
          centeredSlides={true}
          spaceBetween={30}
          onSlideChange={(e) => {
            setSelectedNumber(e.activeIndex);
          }}
        >
          {numbers.map((number, index) => {
            return <SwiperSlide key={index}>
              <div style={{ userSelect: 'none' }} unselectable={'on'} onMouseDown={() => {
                return false;
              }}>{number}</div>
            </SwiperSlide>;
          }
          )}
        </Swiper>
        {percent
          ? <>
            <div className={`text-color${theme}`} style={{ fontSize: 38, display: 'flex', justifyContent: 'center', marginTop: 25 }}>
              {selectedNumber} %.
            </div>
          </>
          : <>
            <div style={{ height: 10 }}></div>
            <Swiper
              slidesPerView={'auto'}
              initialSlide={selectedDecimal}
              slideToClickedSlide={true}
              centeredSlides={true}
              spaceBetween={30}
              onSlideChange={(e) => {
                setSelectedDecimal(e.activeIndex);
              }}
            >
              {decimals.map((number, index) => {
                return <SwiperSlide key={index}>
                  <div style={{ userSelect: 'none' }} unselectable={'on'} onMouseDown={() => {
                    return false;
                  }}>.{number}</div>
                </SwiperSlide>;
              }
              )}
            </Swiper>
            <div className={`text-color${theme}`} style={{ fontSize: 38, display: 'flex', justifyContent: 'center', marginTop: 25 }}>
              <>
                {selectedNumber}.{selectedDecimal}0 {percentResult ? '%' : 'Kg.'}
              </>
            </div>
          </>}

      </div>
    </>
  );
};
