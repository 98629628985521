import React from 'react';
import { Button } from 'antd';
import { format } from 'date-fns';
import Modal from 'antd/es/modal/Modal';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler, Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';

const CorporalCompositionControl = (props) => {
  const { t } = useTranslation();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Title,
    Tooltip,
    Filler,
    zoomPlugin,
    ChartDataLabels
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      y: {
        position: 'left',
        title: {
          display: true,
          text: 'Kg.'
        },
        ticks: {
          stepSize: 10
        }
        // suggestedMin: 20,
        // suggestedMax: 120
      }
    },
    y2: {
      position: 'right',
      title: {
        display: true,
        text: capitalize(t('percent'))
      },
      ticks: {
        stepSize: 10
      },
      suggestedMin: 0,
      suggestedMax: 100
    },
    plugins: {
      legend: {
        position: 'top'
      },
      datalabels: {
        font: {
          size: '14px',
          weight: 'bold'
        },
        align: 'top',
        anchor: 'top',
        formatter: function (value, context) {
          if (context.dataset.label === capitalize(t('weight')) || context.dataset.label === capitalize(t('muscle'))) {
            return value + ' Kg.';
          } else {
            return value + ' %';
          }
        },
        color: function (context) {
          if (context.dataset.label === capitalize(t('weight'))) {
            return 'rgb(0, 144, 189)';
          } else if (context.dataset.label === capitalize(t('muscle'))) {
            return 'rgb(35, 125, 0)';
          } else {
            return 'rgb(120, 104, 0)';
          }
        }
      }
    }
  };

  const labels = props.compositionHistory.map(composition => format(new Date(composition.created), 'dd-MM-yyyy'));

  const data = {
    labels,
    datasets: [
      {
        id: 1,
        label: capitalize(t('weight')),
        borderColor: 'rgb(79,215,255)',
        // backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: false,
        yAxisID: 'y',
        pointRadius: 4,
        data: props.compositionHistory.map(composition => composition.weight)
      },
      {
        id: 1,
        label: capitalize(t('muscle')),
        borderColor: 'rgb(82,185,41)',
        // backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: false,
        yAxisID: 'y',
        pointRadius: 4,
        data: props.compositionHistory.map(composition => composition.muscle)
      },
      {
        id: 1,
        label: capitalize(t('fat')),
        borderColor: 'rgb(185,166,41)',
        // backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: false,
        yAxisID: 'y2',
        pointRadius: 4,
        data: props.compositionHistory.map(composition => composition.fat)
      }
    ]
  };

  return (
    <>
      <Modal title={capitalizeAll(t('corporalComposition'))} visible={props.compositionModalVisible} onOk={() => {
        props.setCompositionModalVisible(false);
      }}
      onCancel={() => {
        props.setCompositionModalVisible(false);
      }}

      onPan={(p) => { }}
      centered
      width={'100%'}
      footer={[
        <Button key="OK" onClick={() => {
          props.setCompositionModalVisible(false);
        }}>
                 OK
        </Button>
      ]}
      >

        <div style={{ height: 300 }}>
          <Line
            datasetIdKey='id'
            data={data}
            options={options}
          />

        </div>
      </Modal>
    </>

  );
};

export default CorporalCompositionControl;
