import React, { useEffect, useReducer, useState } from 'react';
import Cookies from 'js-cookie';

export default (reducer, action, defaultValue) => {
  const Context = React.createContext();

  const Provider = ({ children }) => {
    const [user, setUser] = useState({});
    const [state, dispatch] = useReducer(reducer, defaultValue);

    useEffect(() => {
      const checkToken = Cookies.get('access');
      if (checkToken && checkToken !== 'null' && checkToken !== 'undefined') {
        setUser({
          token: checkToken,
          account: JSON.parse(Cookies.get('account'))
        });
      } else {
        setUser(state);
      }
    }, [state]);

    const boundActions = {};

    for (const key in action) {
      boundActions[key] = action[key](dispatch);
    }

    return (
      <Context.Provider value={{ user, ...boundActions }}>
        { children }
      </Context.Provider>
    );
  };

  return { Context: Context, Provider: Provider };
};
