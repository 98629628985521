import React from 'react';
import './App.css';
import RoutesManagement from './components/navigation/Routes';
import ServiceWorkerWrapper from './worker';

function App () {
  return (
    <div >
      <ServiceWorkerWrapper />
      <RoutesManagement />
    </div>
  );
}

export default App;
