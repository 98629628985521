import { methodDelete, methodGet, methodPatch, methodPost } from './api/methods';

export const getBookingById = (id, token) => {
  return methodGet('booking/' + id, '', token);
};

export const getBookingByQuery = (req, token) => {
  return methodGet('booking', req, token);
};

export const getBookingPopulated = (req, token) => {
  return methodGet('booking/populated', req, token);
};

export const getBookingPopulatedReq = (req, token) => {
  return methodGet('booking/populatedReq', req, token);
};

export const createBooking = (data, token) => {
  return methodPost('booking/create', data, token);
};

export const sendEmail = (data, token) => {
  return methodPost('booking/sendEmail', data, token);
};

export const patchBooking = (id, data, token) => {
  return methodPatch('booking/update?id=' + id, data, token);
};

export const deleteBooking = (id, token) => {
  return methodDelete('booking/delete?id=' + id, token);
};
