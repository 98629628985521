import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useSound from 'use-sound';
import beep1 from '../../assets/sounds/beep1.mp3';
import beep2 from '../../assets/sounds/beep2.mp3';
import beep3 from '../../assets/sounds/beep3.mp3';
import moment from 'moment';
import 'moment/locale/es';

const RepChrono = (props) => {
  const [diff, setDiff] = useState(null);
  const [initial, setInitial] = useState(null);
  // const [concentric, setConcentric] = useState(true);
  // const [eccentric, setEccentric] = useState(false);
  // const [squeeze, setSqueeze] = useState(false);
  const [startChrono, setStartChrono] = useState(false);

  useEffect(() => {
    start();
  }, []);

  const [playBeep1] = useSound(beep1);
  const [playBeep2] = useSound(beep2);
  const [playBeep3] = useSound(beep3);

  const tick = () => {
    const update = new Date(+new Date() - initial);
    const total = parseInt(props.set.time);
    props.handleProgress((moment(update).format('s') + moment(update).format('SSS')), total * 1000);
    if (update.getSeconds() === parseInt(props.set.time)) {
      setInitial(+new Date());
      if (props.lastSet) {
        playBeep1();
      } else {
        playBeep3();
      }
      // props.beep1();
      // loadBeep1();
      props.handleChangeSet();
    }
    setDiff(update);
  };
  const start = () => {
    if (!startChrono) {
      setInitial(+new Date());
      setStartChrono(true);
    }
    if (startChrono) setStartChrono(false);
  };

  useEffect(() => {
    //
    if (initial && startChrono) {
      tick();
    }
  }, [initial]);

  useEffect(() => {
    if (diff && startChrono) {
      tick();
    }
  }, [diff]);

  const timeFormat = (date) => {
    if (!date) return '00:00';

    // let ss = date.getSeconds();
    // let cm = Math.round(date.getMilliseconds() / 100);
    //
    // ss = ss < 10 ? '0' + ss : ss;
    // cm = cm === 10 ? '00' : cm + '0';

    // return `${ss}:${cm}`;
    return moment(date).format('ss:S') + '0';
  };

  return (
    <div className="App">
      {timeFormat(diff)}
    </div>
  );
};

export default RepChrono;
