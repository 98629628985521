/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import BookingDay from './BookingDay';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getIdToken } from 'firebase/auth';
import { getAccountByQuery } from '../../../services/user.service';
import Spinner from '../../../components/navigation/Spinner';
import { getCenterByQuery } from '../../../services/center.service';
import { createBooking, getBookingPopulated, deleteBooking } from '../../../services/booking.service';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button, Col, Form, Popconfirm, Row, Select } from 'antd';
import { getCalendartemplateByQuery } from '../../../services/calendartemplate.service';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';
import { DeleteOutlined } from '@ant-design/icons';

const BookingCalendar = (props) => {
  const { user } = useContext(Auth);
  const [center, setCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [trainers, setTrainers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState({});
  const [calendarView, setCalendarView] = useState('week');
  const { Option } = Select;
  const [form] = Form.useForm();
  const [templates, setTemplates] = useState([]);
  const [hasEvents, setHasEvents] = useState(false);
  const [popVisible, setPopVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  // useEffect(() => {
  //   if (hasEvents) {
  //     setPopVisible(true);
  //   } else {
  //     setPopVisible(false);
  //   }
  // }, [hasEvents]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      await loadTrainers(data.data[0]._id);
      await loadTemplates(data.data[0]._id);
      // await loadBooks(data.data[0]._id);
    }
  };

  const loadTemplates = async (centerId) => {
    const { status, data } = await getCalendartemplateByQuery({ center: centerId, isActive: true }, user.token);
    if (status === 200) {
      data.data.forEach(template => {
        template.key = template._id;
      });
      setTemplates(data.data);
    }
  };

  const loadBooks = async (centerId) => {
    setLoading(true);
    // const { status, data } = await getBookingPopulated({ center: centerId }, user.token);
    const { status, data } = await getBookingPopulated({ center: centerId, start: selectedWeek.start, end: selectedWeek.end }, user.token);
    if (status === 200) {
      setBookings(data.data);
    }
    setLoading(false);
  };

  const loadTrainers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ roles: 'trainer', center: centerId, isActive: true }, user.token);
    if (status === 200) {
      setTrainers(data.data);
    }
  };

  const checkEventsDay = (day) => {
    const dayString = moment(day).format('YYYY-MM-DD');
    const events = bookings.filter(booking => {
      return moment(booking.start).format('YYYY-MM-DD') === dayString;
    });
    if (events.length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (selectedDay) {
      setHasEvents(checkEventsDay(selectedDay));
      const start = moment(selectedDay).startOf('week');
      const end = moment(selectedDay).endOf('week');
      const week = {
        start: start.toDate(),
        end: end.toDate()
      };
      setSelectedWeek(week);
    }
  }, [selectedDay]);

  useEffect(() => {
    if (selectedWeek && center) {
      loadBooks(center._id);
    }
  }, [selectedWeek, center]);

  const setTemplate = async () => {
    const template = templates.find(t => t._id === form.getFieldValue('template'));
    if (template) {
      for await (const booking of template.bookings) {
        const startHour = moment(booking.start);
        const endHour = moment(booking.end);
        let startDate;
        let endDate;
        if (template.type === 'week') {
          let dayOfWeek = new Date(moment(booking.start)).getDay();
          if (dayOfWeek === 0) {
            dayOfWeek = 7;
          }
          const selectedDayOfWeek = selectedDay.getDay();
          const diff = dayOfWeek - selectedDayOfWeek;
          startDate = moment(selectedDay).add(diff, 'days').set({ hour: startHour.hour(), minute: startHour.minute() });
          endDate = moment(selectedDay).add(diff, 'days').set({ hour: endHour.hour(), minute: endHour.minute() });
        } else {
          startDate = moment(selectedDay).set({ hour: startHour.hour(), minute: startHour.minute() });
          endDate = moment(selectedDay).set({ hour: endHour.hour(), minute: endHour.minute() });
        }
        booking.start = startDate.toDate();
        booking.end = endDate.toDate();
        booking.isGroup = booking.slots > 1;
        const { status, data } = await createBooking(booking, user.token);
        if (status === 200) {

        }
      };
      form.setFieldsValue({
        template: 'null'
      });
      await loadBooks(center._id);
    }
  };

  const deleteSelectedDay = async () => {
    const selectedDayStart = moment(selectedDay).startOf('day');
    const selectedDayEnd = moment(selectedDay).endOf('day');
    let bookingsToDelete = bookings.filter(booking => {
      const bookingStart = moment(booking.start);
      const bookingEnd = moment(booking.end);
      return bookingStart >= selectedDayStart && bookingEnd <= selectedDayEnd;
    }
    );
    bookingsToDelete = bookingsToDelete.filter(booking => {
      return !booking.account && booking.group.length === 0;
    });
    for await (const booking of bookingsToDelete) {
      const { status, data } = await deleteBooking(booking._id, user.token);
      if (status === 200) {

      }
    }
    await loadBooks(center._id);
  };

  const deleteSelectedWeek = async () => {
    if (selectedWeek) {
      let bookingsToDelete = bookings.filter(booking => {
        const bookingStart = moment(booking.start);
        const bookingEnd = moment(booking.end);
        return bookingStart >= selectedWeek.start && bookingEnd <= selectedWeek.end;
      }
      );
      bookingsToDelete = bookingsToDelete.filter(booking => {
        return !booking.account && booking.group.length === 0;
      });
      for await (const booking of bookingsToDelete) {
        const { status, data } = await deleteBooking(booking._id, user.token);
        if (status === 200) {

        }
      }
      await loadBooks(center._id);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Row>
        <Col xs={24} xl={6}>
          <Calendar onChange={setSelectedDay} value={selectedDay} locale='es-ES' />
          <div style={{
            marginTop: 15,
            marginBottom: 15
          }}>
            {capitalize(t('selectTemplateForDay'))}
            <Form form={form} style={{
              width: '100%',
              maxWidth: 400,
              marginTop: 15
            }}>

              <Form.Item
                name="template" style={{ width: '80%' }} >
                <Select defaultValue='null'>
                  <Option value="null">{capitalizeAll(t('selectTemplate'))}</Option>
                  {templates.map(template => (
                    <Option key={template._id} value={template._id}>
                      {template.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* {popVisible
                ? ( */}
              <Popconfirm
                title={capitalize(t('dayHasEvents'))}
                onConfirm={setTemplate}
                okText={capitalize(t('yes'))}
                cancelText={capitalize(t('no'))}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    className="submit-form-button"
                    style={{ width: '80%' }}
                  >
                    {capitalizeAll(t('setTemplate'))}
                  </Button>
                </Form.Item>
              </Popconfirm>
              <Popconfirm
                title={capitalize(t('areYouSureDeleteBookings'))}
                onConfirm={deleteSelectedDay}
                okText={capitalize(t('yes'))}
                cancelText={capitalize(t('no'))}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    className="delete-form-button"
                    style={{ width: '80%' }}
                  >
                    {capitalizeAll(t('deleteSelectedDay'))}
                  </Button>
                </Form.Item>
              </Popconfirm>
              <Popconfirm
                title={capitalize(t('areYouSureDeleteBookings'))}
                onConfirm={deleteSelectedWeek}
                okText={capitalize(t('yes'))}
                cancelText={capitalize(t('no'))}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    className="delete-form-button"
                    style={{ width: '80%' }}
                  >
                    {capitalizeAll(t('deleteSelectedWeek'))}
                  </Button>
                </Form.Item>
              </Popconfirm>
              {/* : (
                 )
                   <Form.Item>
                     <Button
                       type="primary"
                       danger
                       className="delete-form-button"
                       style={{ width: '80%' }}
                       onClick={setTemplate}
                     >
                       {capitalizeAll(t('setTemplate'))}
                     </Button>
                   </Form.Item>
                 )} */}

              {/* <Form.Item */}
              {/*  name="template" style={{ width: '80%' }} > */}
              {/*  <Select defaultValue='null' onChange={(e) => { correctClave(e); }}> */}
              {/*    <Option value="null">{capitalizeAll(t('selectTemplate'))}</Option> */}
              {/*    {templates.map(template => ( */}
              {/*      <Option key={template._id} value={template._id}> */}
              {/*        {template.name} */}
              {/*      </Option> */}
              {/*    ))} */}
              {/*  </Select> */}
              {/* </Form.Item> */}
            </Form>
            {/* {calendarView === 'week' ? 'la semana seleccionada' : calendarView === 'month' ? 'el mes seleccionado' : 'el día seleccionado'} */}
          </div>
        </Col>
        <Col xs={24} xl={18}>
          <BookingDay trainers={trainers} center={center} user={user} bookings={bookings} loadBooks={loadBooks} selectedDay={selectedDay} setSelectedDay={setSelectedDay}
            setCalendarView={setCalendarView}/>
        </Col>
      </Row>
      {/* <BookingDay trainers={trainers} center={center} user={user} bookings={bookings} loadBooks={loadBooks}/> */}
    </>
  );
};

export default BookingCalendar;
