import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useParams } from 'react-router-dom';
import { getProgramByQueryWithExercises } from '../../../services/program.service';
import { getIdToken } from 'firebase/auth';
import Spinner from '../../../components/navigation/Spinner';
import { getProgramSessionWithExerciseSessions } from '../../../services/programsession.service';
import moment from 'moment';
import { Button, Table, Tag } from 'antd';
import 'moment/locale/es';
import { ArrowDownOutlined, ArrowUpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
moment.locale('es');

const ProgramLog = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [program, setProgram] = useState(null);
  const [programSessions, setProgramSessions] = useState([]);
  const [programGroups, setProgramGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  // const [exercises, setExercises] = useState(null);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadProgram();
    }
  }, [user.account]);

  useEffect(() => {
    if (programGroups.length > 0) {
      fillColumns(programGroups[selectedGroup]);
    }
  }, [programGroups, selectedGroup]);

  const loadProgram = async () => {
    const { status, data } = await getProgramByQueryWithExercises({ _id: params.id }, user.token);
    if (status === 200) {
      await loadProgramSessions();
      // await loadExercises();
      setProgram(data.data[0]);
      setLoading(false);
    }
  };

  const loadProgramSessions = async () => {
    const { status, data } = await getProgramSessionWithExerciseSessions({ program: params.id }, user.token);
    if (status === 200) {
      setProgramSessions(data.data);
      setProgramGroups(groupByN(3, data.data));
      setSelectedGroup(groupByN(3, data.data).length - 1);
    }
  };

  const handleChangeGroup = (group) => {
    switch (group) {
    case 0:
      if (selectedGroup > 0) {
        setSelectedGroup(selectedGroup - 1);
      }
      break;
    case 1:
      if (selectedGroup < programGroups.length - 1) {
        setSelectedGroup(selectedGroup + 1);
      }
      break;
    default:
      setSelectedGroup(selectedGroup);
      break;
    }
  };

  const groupByN = (n, data) => {
    const result = [];
    let j = 1;
    for (let i = 0; i < data.length; i += n) {
      let from = data.length - (n * j);
      const to = data.length - i;
      if (from < 0) {
        from = 0;
      }
      result.push(data.slice(from, to));
      j++;
    }
    return result.reverse();
  };

  const fillColumns = (programSessions) => {
    const columns = [{
      title: capitalize(t('exercise')),
      key: '_id',
      render: (record) => {
        return {
          props: {
            style: {
              // backgroundColor: pair ? '#d5bcbc' : '#c4d4da',
              // textAlign: 'center',
              // fontWeight: 'bold',
              width: '350px'
              // borderRight: '1px solid #fff'
            }
          },
          children: (
            <React.Fragment>
              <b>{record.name}</b>
            </React.Fragment>
          )
        };
      }
    }];
    let pair = 0;
    programSessions?.forEach(ps => {
      columns.push(createColumn(ps, pair));
      if (pair === 0) {
        pair = 1;
      } else {
        pair = 0;
      }
    });
    setColumns(columns);
  };

  const createColumn = (programSession, pair) => {
    return {
      title: moment(programSession.timestamp.createdAt).format('L'),
      key: programSession._id,
      render: (record) => {
        const exerciseSession = programSession.exercises.find(es => es.exercise === record._id);
        //
        //
        if (exerciseSession) {
          return {
            props: {
              style: {
                backgroundColor: pair ? '#d5bcbc' : '#c4d4da',
                textAlign: 'center',
                fontWeight: 'bold',
                width: '150px',
                borderRight: '1px solid #fff'
              }
            },
            children: (
              <React.Fragment>
                <div style={{ fontSize: 12 }}>
                  {exerciseSession.type === 'isometric'
                    ? (
                      <>
                        {moment(exerciseSession.lastSet).format('mm:ss')}
                        <br/>
                        {exerciseSession.sets.length} {capitalize(t('sets'))}
                        <br/>
                      </>
                    )
                    : (
                      <>
                        {exerciseSession.weight} {record.weightMeasure ? record.weightMeasure : 'p.'}
                        <br/>
                        {exerciseSession.reps} {exerciseSession.incomplete > 0 && ' + ' + exerciseSession.incomplete} Reps.
                        <br/>
                      </>
                    )}

                  {moment(exerciseSession.time).format('mm:ss')}
                  <br/>
                  {exerciseSession.AP && <Tag color="default">AP</Tag>}
                  {exerciseSession.MT && <Tag color="default">MT</Tag>}
                  {exerciseSession.NF && <Tag color="default">NF</Tag>}
                  {exerciseSession.NS && <Tag color="default">NS</Tag>}
                  {exerciseSession.passTul === 2 && <Tag color="success"><ArrowUpOutlined/></Tag>}
                  {exerciseSession.passTul === 1 && <Tag color="red"><ArrowDownOutlined/></Tag>}
                </div>

              </React.Fragment>
            )
          };
        } else {
          return {
            props: {
              style: {
                backgroundColor: pair ? '#d5bcbc' : '#c4d4da',
                textAlign: 'center',
                fontWeight: 'bold',
                width: '150px',
                borderRight: '1px solid #fff'
              }
            },
            children: (
              <React.Fragment>

              </React.Fragment>
            )
          };
        }
      }
    };
  };

  // const createColumn = (exercise) => {
  //   return {
  //     title: exercise.name,
  //     key: exercise._id,
  //     render: (record) => {
  //       const exerciseSession = record.exercises.find(es => es.exercise === exercise._id);
  //       //
  //       //
  //       if (exerciseSession) {
  //         return (
  //           <React.Fragment>
  //             <div style={{ fontSize: 12 }}>
  //               {exerciseSession.weight}Kg
  //               <br />
  //               {exerciseSession.reps} {exerciseSession.discount > 0 && ' + ' + exerciseSession.discount} Reps.
  //               <br />
  //               {moment(exerciseSession.time).format('mm:ss')}
  //               <br />
  //               { exerciseSession.AP && <Tag color="default">AP</Tag>}
  //               { exerciseSession.MT && <Tag color="default">MT</Tag>}
  //               { exerciseSession.NF && <Tag color="default">NF</Tag>}
  //               { exerciseSession.NS && <Tag color="default">NS</Tag>}
  //               { exerciseSession.passTul === 2 && <Tag color="success"><ArrowUpOutlined /></Tag>}
  //               { exerciseSession.passTul === 1 && <Tag color="red"><ArrowDownOutlined /></Tag>}
  //             </div>
  //
  //           </React.Fragment>
  //         );
  //       } else {
  //         return (
  //           <React.Fragment>
  //
  //           </React.Fragment>
  //         );
  //       }
  //     }
  //   };
  // };

  //

  function onChange (pagination, filters, sorter, extra) {

  }

  if (loading || !columns.length) {
    return <Spinner/>;
  }

  return (
    <div>
      <h1>{program.name}</h1>
      <Button onClick={() => handleChangeGroup(0)} disabled={selectedGroup <= 0}><LeftOutlined /></Button>
      <Button onClick={() => handleChangeGroup(1)} disabled={selectedGroup >= programGroups.length - 1}><RightOutlined /></Button>
      {columns.length > 0 && <Table columns={columns} dataSource={program.exercises} onChange={onChange} pagination={false}
        summary={(e, i) => {
          return (
            <>
              <Table.Summary key={i} fixed>
                <Table.Summary.Row style={{ background: 'lightgrey' }}>
                  <Table.Summary.Cell index={0}>{capitalize(t('time'))}</Table.Summary.Cell>

                  {programGroups[selectedGroup].map((programSession, i) => {
                    const time = (programSession.closed ? new Date(programSession.closed) : new Date()) - new Date(programSession.timestamp.createdAt);
                    let timeEx = 0;
                    let timeSecsEx = 0;
                    let totalTimeEx = 0;
                    programSession.exercises.forEach(es => {
                      timeSecsEx += new Date(es.time).getSeconds();
                      timeEx += new Date(es.time).getMinutes();
                      totalTimeEx += new Date(es.time).getTime();
                    });
                    const timeExFormat = new Date(totalTimeEx);
                    const hours = new Date(time).getHours();
                    const totalMinutes = ((hours - 1) * 60) + new Date(time).getMinutes();

                    return (
                      <Table.Summary.Cell index={i} key={i}>
                        <Table.Summary.Row>{moment(timeExFormat).format('mm:ss')} / {totalMinutes} min</Table.Summary.Row>
                        {/* <Table.Summary.Row>{moment(time).format('HH:mm:ss')}</Table.Summary.Row> */}
                      </Table.Summary.Cell>
                    );
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            </>
          );
        }}/>}
    </div>
  );
};

export default ProgramLog;
